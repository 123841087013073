// External librairies
import {useMemo, useState} from "react";

// Query
import { useQueryUserIssuesTable, useQueryCategories } from "../../queries";

// Hooks
import { UseTableWithColumns } from "../../hooks";

// Components
import { LinkButton } from "../../components";

// Config
import { STATUS } from "../../config/config";
import { formatDateString } from "../../config/formats";

// Misc
import { Loader, Transition } from "../../utils";
import {NoData} from "../Nodata";

const TableUserReports = ({ userId }) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [sort, setSort] = useState({
		direction: "asc",
		accessor: "id",
	});

	const { isLoading, data } = useQueryUserIssuesTable(
		userId,
		currentPage,
		sort
	);

	const { isLoading: isLoadingCategories, data: categories } =
		useQueryCategories();

	const columns = useMemo(() => [
		{
			accessor: "action",
			Cell: ({ row: { original } }) => (
				<LinkButton url={`/reports/${original.id}/brief`} />
			),
		},
		{
			Header: "N°",
			accessor: "id",
			sortDirection: sort.accessor === "id" ? sort.direction : "none",
			Cell: ({ value }) => {
				return <span className="text-textNeutral-dark">{value}</span>;
			},
		},
		{
			Header: "Sujet",
			accessor: "title",
			sortDirection: sort.accessor === "title" ? sort.direction : "none",
			Cell: ({ value }) => {
				return <span className="text-textNeutral-dark">{value}</span>;
			},
		},
		{
			Header: "Etat",
			accessor: "status",
			sortDirection: sort.accessor === "status" ? sort.direction : "none",
			Cell: ({ row: { original } }) => {
				const translatedStatus = STATUS.find(
					(status) => status.slug === original.status
				);
				return (
					<span
						className={`rounded-full border ${translatedStatus.border} flex justify-center items-center px-2 py-1 text-center text-[8px] font-semibold ${translatedStatus.bgFade} ${translatedStatus.color} uppercase overflow-hidden whitespace-nowrap`}
					>
						{translatedStatus.name}
					</span>
				);
			},
		},
		{
			Header: "Date de création",
			accessor: "rep_created",
			sortDirection:
				sort.accessor === "rep_created" ? sort.direction : "none",
			Cell: ({ value }) => {
				return formatDateString(value);
			},
		},
		{
			Header: "Modifié le",
			accessor: "rep_updated",
			sortDirection:
				sort.accessor === "rep_updated" ? sort.direction : "none",
			Cell: ({ value }) => {
				return formatDateString(value);
			},
		},
		{
			Header: "Catégorie",
			accessor: "category",
			sortDirection:
				sort.accessor === "category" ? sort.direction : "none",
			Cell: ({ row: { original } }) => {
				const icon = categories?.find(
					(cat) => cat.value === original.category
				)?.icon;
				const translate = categories?.find(
					(cat) => cat.value === original.category
				)?.label;
				return (
					<div className="flex jusitfy-center items-center gap-x-2">
						<i>{icon}</i>
						<span className="text-textNeutral-dark dark:text-textNeutral-light">
							{translate}
						</span>
					</div>
				);
			},
		},
	]);

	return (
		<>
			{isLoading || isLoadingCategories ? (
				<Loader />
			) : data ? (
				<>
					<Transition>
						<div className="h-full flex flex-col gap-y-5">
							<div className="block max-w-full h-[50vh]">
								<div className="mb-16 flex flex-col gap-y-10">
									<UseTableWithColumns
										columns={columns}
										data={data.data}
										sort={sort}
										setSort={setSort}
										currentPage={currentPage}
										setCurrentPage={setCurrentPage}
										count={data.count}
									/>
								</div>
							</div>
						</div>
					</Transition>
				</>
			) : (
				<NoData caption="L'utilisateur n'a encore fait aucun signalement" />
			)}
		</>
	);
};

export default TableUserReports;
