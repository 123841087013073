// External librairies
import React from "react";

// Config
import { formatDateString } from "../../config/formats";

// Icons
import { LibraryBooks, LibraryAddCheck } from "@mui/icons-material";
import {IMAGES_URL} from "../../config/config";
import {Tag} from "../Tag";

const CardNews = ({ image, dateCreated, dateUpdated, title, content, section }) => {
	return (
		<div className="rounded-lg w-full h-full flex flex-col gap-y-4 px-8 xl:px-6 py-5 xl:py-5 bg-bgNeutral-light xl:-translate-y-0 shadow-custom transition-all xl:duration-300 xl:ease-in-out xl:hover:shadow-2xl xl:hover:-translate-y-1">
			<figure className="block w-full h-[12em]">
				<img
					className="rounded-lg w-full h-full object-cover object-center"
					src={
						image === undefined
							? ("https://images.unsplash.com/photo-1618005198919-d3d4b5a92ead?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80")
							: (IMAGES_URL + image)
					}
					alt=""
				/>
			</figure>
			<div className="flex flex-col gap-y-[0.4em] py-1">
				<div className="flex flex-col gap-y-2 lg:flex-row lg:items-center gap-x-8 lg:gap-x-5 lg:gap-y-0 xl:flex-col xl:gap-y-2 xl:items-start">
					<div className="flex items-center gap-x-2 text-xs text-textNeutral-dark/[.38]">
						<p className="flex items-center gap-x-1 text-xs text-textNeutral-dark/[.38]">
							<i className="opacity-50">
								<LibraryBooks sx={{ fontSize: 16 }} />
							</i>
							Parution:
						</p>
						<span className="flex items-center text-xs text-textNeutral-dark/[.38]">
							{formatDateString(dateCreated)}
						</span>
					</div>
					<div className="flex items-center gap-x-2 text-xs text-textNeutral-dark/[.38]">
						<p className="flex items-center gap-x-1 text-xs text-textNeutral-dark/[.38]">
							<i className="opacity-50">
								<LibraryAddCheck sx={{ fontSize: 16 }} />
							</i>
							Mise à jour:
						</p>
						<span className="flex items-center text-xs text-textNeutral-dark/[.38]">
							{formatDateString(dateUpdated)}
						</span>
					</div>
				</div>
				<div className="mt-3 flex flex-col gap-y-[0.4em]">
					<Tag caption={section} />
					<h2 className="text-[16px] text-textNeutral-dark">
						{title}
					</h2>
					<div
						className="quill-editor-theme quill-editor-theme---preview  text-textNeutral-dark/[.72] text-[13px] line-clamp-3"
						dangerouslySetInnerHTML={{ __html: content }}
					></div>
				</div>
			</div>
		</div>
	);
};

export default CardNews;
