// External librairies
import {useQuery} from '@tanstack/react-query';

// Config
import instance from '../../config/axiosconfig';
import {refreshToken} from '../../utils';

export const useQueryForum = (id) => {
	return useQuery({
		queryKey: ['getForum'],
		queryFn: async () => {
			try {
				return await instance.get(`/forums/${id}`);
			} catch (error) {
				if (error.response && error.response.status === 401) {
					await refreshToken();
					return instance.get(`/forums/${id}`);
				}
				throw error;
			}
		},
		select: (data) => {
			return data.data.data;
		}
	});
};

export const useQueryForums = (id) => {
	return useQuery({
		queryKey: ['getForums'],
		queryFn: async () => {
			try {
				return await instance.get(`/forums?user_id=${id}`);
			} catch (error) {
				if (error.response && error.response.status === 401) {
					await refreshToken();
					return instance.get(`/forums?user_id=${id}`);
				}
				throw error;
			}
		},
		select: (data) => {
			return data.data.data;
		}
	});
};

export const useQueryForumMessages = (id) => {
	return useQuery({
		queryKey: ['getForumMessages'],
		queryFn: async () => {
			try {
				return await instance.get(`/forums/${id}/messages`);
			} catch (error) {
				if (error.response && error.response.status === 401) {
					await refreshToken();
					return instance.get(`/forums/${id}/messages`);
				}
				throw error;
			}
		},
		select: (data) => {
			return data.data.data;
		}
	});
};

export const useQuerySubscribers = (id) => {
	return useQuery({
		queryKey: [`getSubscribers-${id}`],
		queryFn: () => instance.get(`/forums/${id}/subscribers`),
		select: (data) => {
			return data.data.data;
		},
	});
};