// Icons
import { KeyboardArrowDown } from "@mui/icons-material";

const SurveyMoveDownInputButton = ({ onClick }) => {
	return (
		<div>
			<button
				type="button"
				className="group rounded-md border-[1px] border-slate-600 w-9 h-9 flex justify-center items-center cursor-pointer transition-all duration-300 hover:border-slate-500"
				onClick={onClick}
			>
				<i className="flex justify-center items-center text-slate-600 transition-all duration-300 group-hover:text-slate-500">
					<KeyboardArrowDown sx={{ fontSize: 20 }} />
				</i>
			</button>
		</div>
	);
};

export default SurveyMoveDownInputButton;