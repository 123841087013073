import { NavLink } from "react-router-dom";
import city_silhouette from "../../assets/img/png/city_silhouette.png";

const NotFound = () => {
	return (
		<div className="relativew-full h-[100vh] flex flex-col items-center gap-y-5 bg-gradient-to-b from-[#091d40] to-[#385490] overflow-hidden">
			<figure className="z-20 absolute bottom-0 left-0">
				<img className="shadow-custom" src={city_silhouette} alt="" />
			</figure>
			<div className="lg:-mt-[16em] w-full h-[100vh] flex flex-col items-center justify-center gap-y-3">
				<h1 className="text-[#f4f5fd] text-[10em]">404</h1>
				<div className="-mt-10 text-[#f4f5fd] flex flex-col items-center">
					<p className="text-inherit font-thin text-center">
						Il semblerait que la page souhaitée n'existe pas
					</p>
					<NavLink
						className="text-inherit font-thin text-center underline text-center mt-2"
						to="/"
					>
						Retourner à l'accueil
					</NavLink>
				</div>
			</div>
		</div>
	);
};

export default NotFound;
