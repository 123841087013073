// External librairies
import React from "react";
import { useParams, Outlet } from "react-router-dom";

// Query
import {useQueryJobSelect} from "../../queries";

// Components
import { NavigationHeader } from "../../components";

// Misc
import { Loader } from "../../utils";

const JobPageSelect = () => {
	const { id } = useParams();
	const { isLoading, data } = useQueryJobSelect(id);

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<>
					<div className="mt-3 block max-w-full">
						<Outlet context={data} />
					</div>
				</>
			)}
		</>
	);
};

export default JobPageSelect;
