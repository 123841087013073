// External Librairies
import React from "react";

// Components
import {Tag} from "../../components";

// Misc
import {AccessTime, Work, PinDrop, Event} from "@mui/icons-material";
import {formatDateString} from "../../config/formats";

const JobList = ({jobs, currentJob, setCurrentJob}) => {
    return (
        <div className="flex flex-col gap-y-5">
            <div className="rounded-lg h-[60vh] bg-bgNeutral-light overflow-auto shadow-custom">
                <ul className="h-full overflow-auto">
                    {jobs.map((j) => (
                        <li
                            key={j.id}
                            className="border-b border-bgNeutral-headerBorder flex flex-col gap-y-5 relative p-6 cursor-pointer transition-all hover:bg-primary/[.1]"
                            onClick={() => {
                                setCurrentJob(j.id);
                            }}
                        >
                            {currentJob === j.id && (
                                <span className="absolute top-0 left-0 w-2 h-full bg-primary"></span>
                            )}
                            <div>
                                <div className="flex items-center justify-between">
                                    <h2 className="text-primary">{j.entrepriseName}</h2>
                                    <Tag caption={formatDateString(j.job_updated)}/>
                                </div>
                                <h3 className="opacity-[.85]">{j.title}</h3>
                                    <ul className="grid grid-cols-2">
                                        <li className="flex items-center gap-x-1">
                                            <i>
                                                <Event sx={{fontSize: 14}}/>
                                            </i>
                                            <span className="text-textNeutral-dark text-xs">
											{formatDateString(j.startTime)}
										</span>
                                        </li>
                                        <li className="flex items-center gap-x-1">
                                            <i>
                                                <PinDrop sx={{fontSize: 14}}/>
                                            </i>
                                            <span className="text-textNeutral-dark text-xs">
											{j.address}
										</span>
                                        </li>
                                        <li className="flex items-center gap-x-1">
                                            <i>
                                                <Work sx={{fontSize: 14}}/>
                                            </i>
                                            <span className="text-textNeutral-dark text-xs">
											{j.contractType}
										</span>
                                        </li>
                                        <li className="flex items-center gap-x-1">
                                            <i>
                                                <AccessTime sx={{fontSize: 14}}/>
                                            </i>
                                            <span className="text-textNeutral-dark text-xs">
											{j.workTime}
										</span>
                                        </li>
                                    </ul>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default JobList;
