// External librairies
import classNames from "classnames";

const SimpleCheckboxInput = ({ name, label, register, ...rest }) => {
	return (
		<div className="flex">
			<label className="flex flex-row-reverse items-center gap-x-2 cursor-pointer">
				<span className="text-[11px] md:text-xs text-textNeutral-dark/[.75] font-semibold uppercase tracking-tight">
					{label}
				</span>
				<input
					type="checkbox"
					name={name}
					placeholder={label}
					className="h-[1.125rem] w-[1.125rem] bg-bgNeutral-light appearance-none rounded-[0.25rem] border-[0.125rem] border-solid border-bgNeutral-light outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-bgNeutral-light before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:ml-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-px checked:focus:after:ml-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent shadow-custom border border-primary checked:border-primary checked:bg-primary"
					{...register}
					{...rest}
				/>
			</label>
		</div>
	);
};

export default SimpleCheckboxInput;
