import React from "react";
import Select from "react-select";
import { ErrorMessage } from "../Errors";

const SelectMultiInput = ({ label, placeholder, options, field, error }) => {
	const style = {
		control: (base) => ({
			...base,
			border: 0,
			boxShadow: "none",
			cursor: "pointer",
			display: "flex",
			flexWrap: "wrap",
		}),
		option: (base) => ({
			...base,
			cursor: "pointer",
		}),
		multiValue: (base) => ({
			...base,
			cursor: "pointer",
			color: "#ededed",
		}),
		multiValueRemove: (base) => ({
			...base,
			cursor: "pointer",
			color: "#333",
		}),
	};
	return (
		<div className="w-full md:w-[18rem] xl:w-[20.5rem] flex flex-col text-[13px] ">
			<div className="mb-2">
				<label className="mb-2 text-textNeutral-dark text-xs font-semibold uppercase tracking-tight opacity-75">
					{label}
				</label>
			</div>
			<div className="shadow-custom rounded-md">
				<Select
					{...field}
					className="react-select-container"
					classNamePrefix="react-select"
					placeholder={placeholder}
					getOptionLabel={(option) => option.name || option.label || option.username}
					getOptionValue={(option) => option.slug || option.value || option.id}
					options={options}
					isMulti
					theme={(theme) => ({
						...theme,
						borderRadius: 6,
						colors: {
							...theme.colors,
							primary25: "#ededed",
							primary: "gray",
						},
					})}
					styles={style}
				/>
			</div>
			{error && (
				<ErrorMessage
					type={error.type === "required" ? "error" : "pattern"}
					message={error.message}
				/>
			)}
		</div>
	);
};

export default SelectMultiInput;
