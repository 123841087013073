import {useState} from "react";
import {TableMoreOptionButton} from "../Button";
import {ROLES} from "../../config/config";
import {formatDateString} from "../../config/formats";
import {UseTableWithColumns} from "../../hooks";
import {Loader, Transition} from "../../utils";
import {useMutationSubscriberDelete, useMutationSubscriptionUpdate, useQuerySubscribers} from "../../queries";
import {toast} from "react-toastify";

const TableForumSubscribers = ({forumId}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [sort, setSort] = useState({
        direction: "asc",
        accessor: "id",
    });

    const {isLoading, data, refetch: refetchUser} = useQuerySubscribers(forumId)
    const {mutate: updateSubscription} = useMutationSubscriptionUpdate(forumId)
    const {mutate: deleteSubscriber} = useMutationSubscriberDelete(forumId)

    const handleBlockSubscriber = (id) => {
        updateSubscription({id, isBlocked: true}, {
            onSuccess: () => {
                toast.success("L'utilisateur a été bloqué")
                refetchUser()
            },
            onError: () => toast.error("Une erreur est survenue, impossible de bloquer l'utilisateur")
        })
    }

    const handleAllowSubscriber = (id) => {
        updateSubscription({id, isBlocked: false}, {
            onSuccess: () => {
                toast.success("L'utilisateur a été débloqué")
                refetchUser()
            },
            onError: () => toast.error("Une erreur est survenue, impossible de débloquer l'utilisateur")
        })
    }

    const handleDeleteSubscriber = (id) => {
        deleteSubscriber(id, {
            onSuccess: () => {
                toast.success("L'utilisateur a été supprimé")
                refetchUser()
            },
            onError: () => toast.error("Une erreur est survenue, impossible de supprimer l'utilisateur")
        })
    }

    const moreActionOptions = [
        {label: "Débloquer", action: handleAllowSubscriber},
        {label: 'Bloquer', action: handleBlockSubscriber},
        {label: 'Supprimer', action: handleDeleteSubscriber},
    ]

    //TODO Ajouter la possibilité d'ajouter un subscriber

    const columns = [
        // {
        //     accessor: "action",
        //     Cell: ({row: {original}}) => (
        //         <EditTableButton id={original.id} navigable={false} />
        //     ),
        // },
        {
            Header: "N°",
            accessor: "id",
            Cell: ({value}) => {
                return <span className="text-textNeutral-dark">{value}</span>;
            },
        },
        {
            Header: "Nom d'utilisateur",
            accessor: "username",
            Cell: ({row: {original}}) => {
                return (
                    <span className="text-textNeutral-dark dark:text-textNeutral-light">
						{original.username}
					</span>
                );
            },
        },
        {
            Header: "Prénom",
            accessor: "first_name",
            Cell: ({row: {original}}) => {
                return (
                    <span className="text-textNeutral-dark dark:text-textNeutral-light">
						{original.first_name}
					</span>
                );
            },
        },
        {
            Header: "Nom de famille",
            accessor: "last_name",
            Cell: ({row: {original}}) => {
                return (
                    <span className="text-textNeutral-dark dark:text-textNeutral-light">
						{original.last_name}
					</span>
                );
            },
        },
        {
            Header: "E-mail",
            accessor: "email",
            Cell: ({row: {original}}) => {
                return (
                    <span className="text-textNeutral-dark dark:text-textNeutral-light">
						{original.email}
					</span>
                );
            },
        },
        {
            Header: "Role",
            accessor: "role",
            Cell: ({row: {original}}) => {
                const translatedRole = ROLES.find(
                    (role) => role.slug === original.role
                );
                return (
                    <div className="block flex">
                        <p
                            className={`rounded-full border ${translatedRole.border} block px-3 px-5 py-1 text-center text-[10px] font-semibold ${translatedRole.bgFade} ${translatedRole.color} dark:text-textNeutral-light uppercase overflow-hidden whitespace-nowrap tracking-wide`}
                        >
                            {translatedRole.name}
                        </p>
                    </div>
                );
            },
        },
        {
            Header: "Membre depuis le",
            accessor: "subscriptionDate",
            Cell: ({value}) => {
                return formatDateString(value);
            },
        },
        {
            Header: "Etat",
            accessor: "isBlocked",
            Cell: ({row: {original}}) => {
                return (
                    <span className="text-textNeutral-dark dark:text-textNeutral-light">
						{original.isBlocked ? "Bloqué" : "Actif"}
					</span>
                );
            },
        },
        {
            Header: "Actions",
            accessor: "more",
            Cell: ({row: {original}}) => (
                <TableMoreOptionButton options={moreActionOptions} rowId={original.id}/>
            ),

        }
    ]

    return (
        <>
            {isLoading ? (
                <Loader/>
            ) : (
                <Transition>
                    <div className="block max-w-full h-[50vh]">
                        <div className="mb-16 flex flex-col gap-y-10">
                            <UseTableWithColumns
                                columns={columns}
                                data={data}
                                sort={sort}
                                setSort={setSort}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                count={data.length}
                            />
                        </div>
                    </div>
                </Transition>
            )}
        </>
    )

}

export default TableForumSubscribers;