import { Info } from "@mui/icons-material";
import classNames from "classnames";

const ErrorMessage = ({ message = "Ce champ est requis", type = "error" }) => {
	return (
		<div className="mt-1 flex lg:items-center">
			<i
				className={classNames(
					type === "error"
						? "text-rose-400"
						: type === "pattern"
						? "text-orange-400"
						: ""
				)}
			>
				<Info sx={{ fontSize: 16 }} />
			</i>
			<p
				className={classNames(
					"pl-1 mt-1 text-xs lg:whitespace-nowrap",
					type === "error"
						? "text-rose-400"
						: type === "pattern"
						? "text-orange-400"
						: ""
				)}
			>
				{message}
			</p>
		</div>
	);
};

export default ErrorMessage;
