export const appRoutes = {
	LOGIN: "/login",
	FIRST_LOGIN: "/first-login",
	FORGOT_PASSWORD: "/forgot-password",
	RECOVERY_PASSWORD: "/password-recovery",
	MAIN: "/",
	REPORTS: "reports",
	REPORTS_ID: "reports/:id",
	REPORTS_CREATE: "reports/create",
	NEWS: "news",
	NEWS_ID: "news/:id",
	NEWS_CREATE: "news/create",
	AGENDA: "agenda",
	AGENDA_ID: "agenda/:id",
	AGENDA_CREATE: "agenda/create",
	DIRECTORY: "directory",
	DIRECTORY_ID: "directory/:id",
	DIRECTORY_CREATE_USER: "directory/create/user",
	DIRECTORY_CREATE_GROUP: "directory/create/group",
	JOB: "job",
	JOB_ID: "job/:id",
	JOB_CREATE: "job/create",
	SURVEY: "survey",
	SURVEY_ID: "survey/:id",
	SURVEY_CREATE: "survey/create",
	FORUM: "forum",
	FORUM_ID: "forum/:id",
	FORUM_CREATE: "forum/create",
	USERS: "users",
	USERS_ID: "users/:id",
	USERS_CREATE: "users/create",
	PROFILE: "profile",
	NOTIFICATIONS: "notifications",
};

export const appRoutesSub = {
	BRIEF: "brief",
	EDIT: "edit",
	COMMENTS: "comments",
	SETTINGS: 'settings',
	EXPORT: 'export',
	UNKNOWN: "*",
};
