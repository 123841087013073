// External librairies
import React, {useEffect, useState} from "react";

// Query
import {useQueryJobs} from "../../queries";

// Components
import {ActionHeader, JobList, JobDetails} from "../../components";

// Misc
import {Loader} from "../../utils";
import {Transition} from "../../utils/";

const JobPage = () => {
    const [contractType, setContractType] = useState("");
	const [searchText, setSearchText] = useState("");
    const [startDate, setStartDate] = useState("all");
    const {isLoading: isLoadingDataJobs, data: jobs, refetch} = useQueryJobs(
        contractType,
        startDate,
        searchText
    );
    const [currentJob, setCurrentJob] = useState(null);

    const actionData = [
        {
            id: 0,
            route: "create",
            label: "Ajouter une offre",
        },
    ];

    useEffect(() => {
        if (jobs && jobs.length > 0) {
            setCurrentJob(jobs[0].id)
        }
    }, [jobs, setCurrentJob])

    return (
        <>
            <div>
                <ActionHeader icon="job" title="Emplois" hasReturn={false} actionData={actionData}/>
            </div>
            {isLoadingDataJobs ? (
                <Loader/>
            ) : (
                <>
                    <Transition>
                        {
                            jobs ? (
                                <div className="flex gap-x-8">
                                    <div className="w-[25em] h-full hidden lg:block">
                                        <JobList
                                            jobs={jobs}
                                            currentJob={currentJob}
                                            setCurrentJob={setCurrentJob}
                                        />
                                    </div>
                                    <div className="hidden lg:block w-[calc(100%-25em)] h-full">
                                        <Transition>
                                            <JobDetails
                                                id={currentJob}
                                                refetch={refetch}
                                            />
                                        </Transition>
                                    </div>
                                </div>
                            ) : (
                                <div className="w-full h-full flex justify-center">
                                    <h3 className="mt-[20vh] text-xl lg:text-[2.6rem] opacity-[.18] text-center leading-[1.2em]">
                                        Il n'y actuellement aucun job
                                    </h3>
                                </div>
                            )
                        }
                    </Transition>
                </>
            )}
        </>
    );
};

export default JobPage;
