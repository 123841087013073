import React from "react";
import Select from "react-select";
import FormLabel from "../Form/FormLabel";

const SelectListFilter = ({
	isMulti,
	options,
	select,
	setSelect,
	label,
	placeholder,
	registeredOnChange,
	callBackAction,
}) => {
	const style = {
		control: (base) => ({
			...base,
			border: 0,
			boxShadow: "none",
			cursor: "pointer",
		}),
		option: (base) => ({
			...base,
			cursor: "pointer",
		}),
		multiValue: (base) => ({
			...base,
			cursor: "pointer",
			color: "#ededed",
		}),
		multiValueRemove: (base) => ({
			...base,
			cursor: "pointer",
			color: "#333",
		}),
	};
	const getValue = () => {
		return select
			? options?.filter((s) => select.indexOf(s.slug || s.value) >= 0)
			: [];
	};


	const onChange = (newValue) => {
		if (registeredOnChange) {
			registeredOnChange(newValue);
		} else {
			setSelect(isMulti ? newValue.map((s) => s.slug || s.value) : newValue.slug || newValue.value);
		}
		if (callBackAction) {
			callBackAction()
		}
	};

	return (
		<div className="min-w-[16em] text-[13px]">
			<div className="mb-2">
				<FormLabel htmlFor={`${label}-select`}>{label}</FormLabel>
			</div>
			<div className="shadow-custom rounded-md">
				<Select
					id={`${label}-select`}
					className="react-select-container"
					classNamePrefix="react-select"
					getOptionLabel={(option) => option.name || option.label}
					getOptionValue={(option) => option.slug || option.value}
					options={options}
					value={getValue()}
					onChange={onChange}
					placeholder={placeholder}
					isMulti={isMulti}
					theme={(theme) => ({
						...theme,
						borderRadius: 6,
					})}
					styles={style}
				/>
			</div>
		</div>
	);
};

export default SelectListFilter;
