// External librairies
import React, {useEffect} from "react";
import {useOutletContext, useNavigate} from "react-router-dom";
import {useForm, Controller} from "react-hook-form";
import {toast} from "react-toastify";

// Queries
import {useMutationDeleteImage, useMutationNews, useMutationUploadImages} from "../../queries";

// Components
import {
    TextInput,
    ImageInput,
    EditorQuill,
    FormButton,
    CancelButton, SearchBoxInput, TextAreaInput,
} from "../../components";

// Misc
import {Transition} from "../../utils/";
import {useQueryNewsSections} from "../../queries/query/queryNews";
import {Loader} from "../../utils";

const NewsPageEdit = () => {
    const newsData = useOutletContext();
    const {mutate: updateNews} = useMutationNews(newsData.id);
    const {mutate: deleteImages} = useMutationDeleteImage();
    const {mutate: uploadImages} = useMutationUploadImages();

    const {isLoading, data: sections} = useQueryNewsSections()

    const navigate = useNavigate();

    const {
        control,
        register,
        formState: {errors, isValid},
        handleSubmit,
        reset,
        setValue
    } = useForm();

    // Set form default values
    useEffect(() => {
        let defaultValues = {};
        defaultValues.title = newsData?.title;
        defaultValues.short_title = newsData?.short_title;
        defaultValues.content = newsData?.content;
        if (sections && sections.length > 0) {
            defaultValues.section = sections.find(section => section.value === newsData?.section);
        }
        defaultValues.images = newsData?.NewsImages.map((image) => image.filename);
        reset({...defaultValues});
    }, [newsData, reset, sections]);

    const onSubmit = async (data, e) => {
        e.preventDefault();
        const imagesToDelete = newsData?.NewsImages.map((img) => img.filename).filter(img => !data.images.includes(img));
        if (imagesToDelete.length > 0) {
            deleteImages(imagesToDelete, {
                onError: () => toast.error("Les images n'ont pas pu être supprimées")
            })
        }

        const news = {
            ...data,
            section: typeof data.section === "object" ? data.section.value : data.section,
        }

        if (data.images.length === 0) {
            updateNews(news, {
                onSuccess: () => {
                    navigate("/news");
                    toast.success("La nouvelle a été mise à jour !")
                },
                onError: () => toast.error("Une erreur est survenue"),
            })
        } else {
            await uploadImages(data.images, {
                onSuccess: (res) => {
                    news.images = res.data.data;
                    updateNews(news, {
                        onSuccess: () => {
                            navigate("/news");
                            toast.success("La nouvelle a été mise à jour")
                        },
                        onError: () => toast.error("Une erreur est survenue lors de la publication de la nouvelle")
                    });
                },
                onError: () => toast.error("Les images n'ont pas été téléchargées")
            })
        }
    };

    if (isLoading) {
        return <Loader/>
    }

    return (
        <Transition>
            <div className="mt-10 mb-12 flex flex-col-reverse gap-y-32 xl:flex-row xl:gap-x-20 xl:gap-y-0">
                <div className="w-full xl:w-3/5">
                    <form
                        className="flex flex-col gap-y-5"
                        onSubmit={(e) => {
                            handleSubmit(onSubmit)(e);
                        }}
                    >

                        <div className="mt-5 flex flex-col gap-y-10">
                            <TextInput
                                id="title"
                                label="Titre"
                                register={{
                                    ...register("title", {
                                        required: true,
                                    }),
                                }}
                                error={errors?.title}
                            />
                            <SearchBoxInput
                                id="news_sections"
                                label="Rubrique"
                                fetchResults={(search) => {
                                    return sections.filter(section => section.value.toLowerCase().includes(search.toLowerCase()))
                                }}
                                text={newsData?.section}
                                onChange={section => setValue("section", section)}
                            />
                            <TextAreaInput
                                id="short_title"
                                label="Description courte"
                                register={{
                                    ...register("short_title", {
                                        required: false
                                    })
                                }}
                                error={errors?.short_title}
                            />
                            <Controller
                                control={control}
                                name="content"
                                render={({field: {onChange, value}}) => (
                                    <EditorQuill
                                        label="Description de la nouvelle"
                                        value={value}
                                        onChange={onChange}
                                    />
                                )}
                            />
                        </div>
                        <div>
                            <Controller
                                control={control}
                                name="images"
                                render={({field: {onChange, value}}) => (
                                    <ImageInput
                                        images={value}
                                        setImages={(images) => {
                                            onChange(images);
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div
                            className="mt-5 w-full flex flex-col gap-y-3 md:flex-row md:justify-end md:items-center md:gap-x-4 md:gap-y-0">
                            <CancelButton caption="Annuler" backTo="news"/>
                            <FormButton
                                caption="Enregistrer"
                                isValid={isValid}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </Transition>
    );
};

export default NewsPageEdit;
