import {NavLink} from "react-router-dom";

const EditTableButton = ({id, navigable = true, navigation, onClick}) => {
    return (
        <div className="absolute w-full h-full top-0 left-0 hover:border-2 hover:bg-primary/[.1]">
            {
                navigable && (
                    <NavLink
                        onClick={onClick}
                        to={navigation || `${id}/brief`}
                        className="w-full h-full flex items-center border-primary transition-all"
                    ></NavLink>
                )
            }
        </div>
    );
};

export default EditTableButton;
