import MoreVertIcon from '@mui/icons-material/MoreVert';
import {useEffect, useRef, useState} from "react";

/*
* Example options :
* [
    { label: 'Option 1', action: 'action1' },
    { label: 'Option 2', action: 'action2' },
    { label: 'Option 3', action: 'action3' },
  ]
* */
const TableMoreOptionButton = ({ options, rowId }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);

    const handleMenuOpen = () => {
        setIsMenuOpen(true);
    };

    const handleMenuClose = () => {
        setIsMenuOpen(false);
    };

    const handleOptionClick = (action) => {
        action(rowId)
        handleMenuClose();
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                handleMenuClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={"flex flex-row justify-end pr-3 transition-all duration-300"} ref={menuRef}>
            <button
                type="button"
                aria-label="more"
                aria-controls="menu"
                aria-haspopup="true"
                onClick={handleMenuOpen}
                className="transition-all duration-300 hover:text-primary"
            >
                <MoreVertIcon />
            </button>
            {isMenuOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-10">
                    {options.map((option, index) => (
                        <div
                            key={index}
                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => handleOptionClick(option.action)}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default TableMoreOptionButton;