// External Librairies
import React from "react";

const Tag = ({ caption }) => {
	return (
		<span className="rounded-full px-5 py-2 w-fit text-[12px] text-primary/[.95] uppercase font-semibold bg-bgNeutral-dark/[.05]">
			{caption}
		</span>
	);
};

export default Tag;
