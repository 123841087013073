// External librairies
import { ArrowDropDown } from "@mui/icons-material";

// Components
import { ErrorMessage } from "../Errors";

const SelectInput = ({ label, id, items, noValue, error, register }) => {
	const reducedSelect = items?.filter(
		(i) => i.slug !== "all" || i.name !== "Tous"
	);
	return (
		<div className="w-full md:w-[18rem] xl:w-[20.5rem] flex flex-col justify-start">
			<label
				htmlFor={id}
				className="mb-2 text-textNeutral-dark text-xs font-semibold uppercase tracking-tight opacity-75 dark:text-textNeutral-light"
			>
				{label}
			</label>
			<div className="relative">
				<span className="z-10 absolute top-[35%] right-6 -translate-y-1/2 w-4 h-4">
					<ArrowDropDown className="text-textNeutral-dark/[.7] dark:text-textNeutral-light/[.7]" />
				</span>
				<select
					id={id}
					className={`relative border border-bgNeutral-elementborderlight dark:border-bgNeutral-dark shadow-custom bg-bgNeutral-elementlight dark:bg-bgNeutral-elementdark rounded-lg w-full px-4 py-3 text-xs text-textNeutral-dark dark:text-textNeutral-light transition-all outline-none focus:border-sky-500 focus:ring-2 focus:ring-primary cursor-pointer appearance-none`}
					placeholder="Catégorie"
					defaultValue={noValue}
					{...register}
				>
					
					{reducedSelect?.map((item, index) => {
						return (
							<option
								key={index}
								value={item.slug || item.value}
								className="text-textNeutral-dark"
							>
								{item.name || item.label}
							</option>
						);
					})}
				</select>
			</div>
			{error && (
				<ErrorMessage
					type={error.type === "required" ? "error" : "pattern"}
					message={error.message}
				/>
			)}
		</div>
	);
};

export default SelectInput;
