// External Librairies
import React from "react";
import {useOutletContext} from "react-router-dom";

// Components
import {Tag} from "../../components";

// Misc
import {Phone, Mail, PinDrop} from "@mui/icons-material";
import {Transition} from "../../utils/";
import {getDays} from "./directoryHelpers";

const DirectoryPageBrief = () => {
    const contactData = useOutletContext();
    return (
        <Transition>
            <div className="w-full xl:w-[48em] rounded-lg px-6 py-8 bg-bgNeutral-light xl:-translate-y-0 shadow-custom">
                <div className="flex flex-col gap-y-8">
                    <div className="border-b border-beNeutral-borderHeader flex flex-col gap-y-5 pb-4">
                        <div className="flex justify-between">
                            <div className="flex flex-col gap-y-0">
                                <h3>
                                    {contactData.first_name} {contactData.last_name}
                                </h3>
                                <h4 className="opacity-75 font-light">
                                    {contactData.role}
                                </h4>
                            </div>
                            <div>
                                <Tag caption={contactData.group}/>
                            </div>
                        </div>
                        <div className="flex flex-col gap-y-0">
                            <div className="flex items-center gap-x-2">
                                <i className="text-[14px] text-primary">
                                    <Phone sx={{fontSize: 12}}/>
                                </i>
                                <span className="text-[14px] text-textNeutral-dark font-light">
									{contactData.phone}
								</span>
                            </div>
                            <div className="flex items-center gap-x-2">
                                <i className="text-[14px] text-primary">
                                    <Mail sx={{fontSize: 12}}/>
                                </i>
                                <span className="text-[14px] text-textNeutral-dark font-light">
									{contactData.mail}
								</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-y-3">
                        {contactData.ContactSchedules.length > 0 && (
                            <ul>
                                {contactData.ContactSchedules.map((c) => (
                                    <li key={c.id}>
                                        <h4 className="text-[13px] text-primary/[.75]">
                                            {getDays(c.days)}
                                        </h4>
                                        <div className="flex items-center gap-x-8">
                                            {c.morning_start !== "00:00:00" && c.morning_end !== "00:00:00" && (
                                                <span className="text-textNeutral-dark/[.72] text-[13px] font-normal">
										{c.morning_start.substring(0, 5)} - {c.morning_end.substring(0, 5)}
									</span>
                                            )
                                            }
                                            {c.afternoon_start !== "00:00:00" && c.afternoon_end !== "00:00:00" && (
                                                <span className="text-textNeutral-dark/[.72] text-[13px] font-normal">
										{c.afternoon_start.substring(0, 5)} - {c.afternoon_end.substring(0, 5)}
									</span>
                                            )
                                            }
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    {(contactData.street || contactData.zip || contactData.city) && (
                        <div className="flex items-center gap-x-2">
                            <i className="text-[14px] text-primary">
                                <PinDrop sx={{fontSize: 15}}/>
                            </i>
                            <div className="flex items-center gap-x-3 text-[14px] text-textNeutral-dark font-light">
                                {contactData.street && (
                                    <span className="text-inherit opacity-75">
								{contactData.street}
							</span>
                                )}
                                <span className="text-inherit opacity-75">-</span>
                                {(contactData.zip || contactData.city) && (
                                    <div className="flex items-center gap-x-1">
                                        {contactData.zip && (
                                            <span className="text-inherit font-medium">
									{contactData.zip}
								</span>
                                        )}
                                        {contactData.city && (
                                            <span className="text-inherit font-medium">
									{contactData.city}
								</span>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Transition>
    );
};

export default DirectoryPageBrief;
