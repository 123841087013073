import { AnimatePresence, motion } from 'framer-motion';

const AnimationDown = ({ children }) => {
	return (
		<AnimatePresence className="w-full flex items-center justify-end">
			<motion.div
				initial={{ opacity: 0, y: 32 }}
				animate={{ opacity: 1, y: 0 }}
				exit={{ opacity: 0, y: 32 }}
			>
				{children}
			</motion.div>
		</AnimatePresence>
	);
};

export default AnimationDown;
