export const getDays = (days) => {
    let cpt = days;
    const daysList = []
    while (cpt > 0) {
        if (cpt >= 32) {
            daysList.unshift("VE")
            cpt -= 32
        } else if (cpt >= 16) {
            daysList.unshift("JE")
            cpt -= 16
        } else if (cpt >= 8) {
            daysList.unshift("ME")
            cpt -= 8
        } else if (cpt >= 4) {
            daysList.unshift("MA")
            cpt -= 4
        } else if (cpt >= 2) {
            daysList.unshift("LU")
            cpt -= 2
        }
    }
    return daysList.join(' - ')
}

const getDaysValues = (days) => {
    let cpt = days;
    const daysList = []
    while (cpt > 0) {
        if (cpt >= 32) {
            daysList.unshift("32")
            cpt -= 32
        } else if (cpt >= 16) {
            daysList.unshift("16")
            cpt -= 16
        } else if (cpt >= 8) {
            daysList.unshift("8")
            cpt -= 8
        } else if (cpt >= 4) {
            daysList.unshift("4")
            cpt -= 4
        } else if (cpt >= 2) {
            daysList.unshift("2")
            cpt -= 2
        }
    }
    return daysList
}

export const getScheduleWithDaysValues = schedule => {
    return schedule.map(s => {
        const daysValues = getDaysValues(s.days)
        return {...s, days: daysValues}
    })
}

export const getScheduleWithSumOfDays = (schedule) => {
    return schedule.map(s => {
        const sum = s.days.reduce((acc, current) => {
            const number = parseInt(current);
            return acc + number
        }, 0);
        return {...s, days: sum}
    })
}