// Icons
import { MoreTime, PlusOneOutlined, TextFormat, TextFields, FilterList, CheckCircleOutline } from "@mui/icons-material";

const AddButton = ({ caption, icon, onClick }) => {

	const getIcon = (icon) => {
		switch (icon) {
			case "text":
				return <TextFormat sx={{ fontSize: 54 }} />;
			case "textarea":
				return <TextFields sx={{ fontSize: 54 }} />;
			case "select":
				return <FilterList sx={{ fontSize: 54 }} />;
			case "checkbox":
				return <CheckCircleOutline sx={{ fontSize: 54 }} />;
			default:
				return null
		}
	};
	return (
		<div className="group rounded-[12px] border-2 border-bgNeutral-light flex justify-center bg-primary transition-all duration-300 hover:bg-bgNeutral-light hover:border-primary">
			<button
				type="button"
				onClick={onClick}
				className="w-full h-full flex flex-col items-center gap-y-3 py-8 px-2 text-[12px] text-bgNeutral-light font-semibold uppercase whitespace-nowrap transition-all duration-300 group-hover:text-primary"
			>
				<i className="text-bgNeutral-light font-semibold opacity-[.65] transition-all duration-300 group-hover:text-primary">
					{getIcon(icon)}
				</i>
				{caption}
			</button>
		</div>
	);
};

export default AddButton;
