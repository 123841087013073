const NoData = ({ caption }) => {
	return (
		<div className="w-full h-full flex justify-center">
			<h3 className="mt-[20vh] text-xl lg:text-[2.6rem] opacity-[.18] text-center leading-[1.2em]">
				{caption}
			</h3>
		</div>
	);
};

export default NoData;
