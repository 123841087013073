// External librairies
import React, { useContext, useEffect, useState } from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

// Query
import { request } from '../../utils/axios';

// Context
import { AuthContext } from '../../contexts/AuthProvider';

// Components
import { NoData } from '../../components';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

export const options = {
	scales: {
		x: {
			grid: {
				display: false
			}
		},
		y: {
			grid: {
				display: false
			}
		}
	},
	plugins: {
		title: {}
	},
	responsive: true
};

const BarChart = ({ chartData, options }) => {
	return (
		<div>
			<Bar data={chartData} height={325} width={720} options={options} />
		</div>
	);
};

const Graphic = () => {
	const { user } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(true);
	const [issuesData, setIssuesData] = useState({
		labels: [],
		datasets: [
			{
				label: 'Nouveaux signalements',
				data: [],
				backgroundColor: 'rgb(255, 231, 144)'
			}
		]
	});

	useEffect(() => {
		request(
			'/reports?group=date&aggregate=count&date=last_30_days',
			'GET',
			null,
			user.accessToken
		).then((res) => {
			if (res.data !== undefined) {
				let chartData = res.data.map((data, index) => {
					return {
						id: index,
						day: new Date(data.date).toLocaleDateString('fr'),
						issuesAdded: data.count
					};
				});
				setIssuesData({
					labels: chartData.map((data) => data.day),
					datasets: [
						{
							label: 'Nouveaux signalements',
							data: chartData.map((data) => data.issuesAdded),
							backgroundColor: 'rgb(255, 231, 144)'
						}
					]
				});
			}
			setIsLoading(false);
		});
	}, [setIssuesData]);
	return (
		<div>
			{!issuesData ? (
				<NoData caption="Aucun canal disponible" />
			) : (
				<div className="w-full h-auto flex flex-col">
					<div className="flex justify-between">
						<div>
							<h2 className="dark:text-textNeutral-light">
								Graphique des signalements
							</h2>
						</div>
					</div>
					<div className="mt-5 w-full h-full">
						<BarChart chartData={issuesData} options={options} />
					</div>
				</div>
			)}
		</div>
	);
};

export default Graphic;
