// Config
import { IMAGES_URL } from "../../config/config";

const ImagePreview = ({ image }) => {
	return (
		<div className="rounded-xl w-full flex justify-center items-center overflow-hidden">
			{image ? (
				<figure className="block w-full h-full">
					<img
						className="rounded-lg w-full h-full object-cover object-center"
						src={IMAGES_URL + image}
						alt=""
					/>
				</figure>
			) : (
				<div>
					<p className="text-textNeutral-dark/[.15] font-semibold text-lg">
						Aucune image
					</p>
				</div>
			)}
		</div>
	);
};

export default ImagePreview;
