// External Librairies
import React from "react";
import {useOutletContext} from "react-router-dom";
import {format} from "date-fns";

// Components
import {Avatar} from "../../components";
import { Icon } from '@mui/material';
// Config
import {ROLES} from "../../config/config";
import {formatDateString, slugToIcon} from "../../config/formats";

// Misc
import {Transition} from "../../utils/react-router";
import TableUserReports from "../../components/Table/TableUserReports";

const UsersPageBrief = () => {
    const userData = useOutletContext();
    const translatedRole = ROLES.find((r) => r.slug === userData?.role);

    return (
        <>
            <Transition>
                <div className="flex flex-col gap-y-5 lg:flex-row lg:gap-y-0 lg:gap-x-3">
                    <div
                        className="rounded-xl w-full lg:max-w-[22em] flex flex-col items-center gap-y-4 p-8 shadow-custom bg-bgNeutral-light">
                        <div className="flex flex-col items-center gap-y-3">
                            <figure className="w-56 h-56 bg-slate-300 rounded-lg overflow-hidden">
                                <Avatar
                                    className="w-full h-auto object-cover"
                                    userAvatar={userData.avatar}
                                />
                            </figure>
                            <span
                                className={`border ${translatedRole?.bgFade} ${translatedRole?.border} ${translatedRole?.color} font-semibold py-1 px-4 rounded-full text-xs dark:text-textNeutral-light`}
                            >
								{translatedRole?.name}
							</span>
                        </div>
                        <div className="my-5 w-full flex justify-between items-center">
                            <div>
                                <p className="font-semibold">Informations</p>
                            </div>
                            <div className="flex">
                                <p className="text-textNeutral-dark text-[11px] opacity-50">
									<span className="text-inherit">
										Créé le:{" "}
									</span>
                                    <span className="text-inherit">
										{format(
                                            new Date(userData?.use_created),
                                            "dd-MM-yyyy"
                                        )}
									</span>
                                </p>
                            </div>
                        </div>
                        <div className="mb-2 border-b border-bgNeutral-dark/[.1] w-full flex justify-between pb-3">
                            <div className="flex items-center gap-x-1">
                                <div>
                                    <p className="text-xs text-textNeutral-dark">
                                        {userData?.first_name}
                                    </p>
                                </div>
                                <div>
                                    <p className="text-textNeutral-dark text-xs font-semibold">
                                        {userData?.last_name}
                                    </p>
                                </div>
                            </div>
                            <div>
                                <p className="text-xs text-textNeutral-dark/[.6]">
                                    @{userData?.username}
                                </p>
                            </div>
                        </div>
                        <div className="mb-2 border-b border-bgNeutral-dark/[.1] w-full flex justify-between pb-3">
                            <div className="w-full flex justify-between items-center gap-x-1.5">
								<span className="text-textNeutral-dark text-xs">
									{userData?.email}
								</span>
                            </div>
                        </div>
                        <div className="mb-2 border-b border-bgNeutral-dark/[.1] w-full flex justify-between pb-3">
                            <div className="flex items-center gap-x-1">
                                <div>
                                    <p className="flex items-center gap-x-1.5 text-xs text-textNeutral-dark">
                                        {formatDateString(userData?.birthday)}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {
                            ["associate", "manager"].includes(userData.role) && (
                                <div className="my-5 w-full flex flex-col justify-between items-start">
                                    <div>
                                        <p className="font-semibold">Modules</p>
                                    </div>
                                    <ul>
                                    {
                                        userData.Plugins.map(plugin => (
                                             <li key={plugin.slug} className="w-full flex items-center gap-x-3 p-1.5 px-[0.44em]">
                                                 <Icon sx={{fontSize: 18}}>{slugToIcon[plugin.slug]}</Icon>
                                                 <span className="text-xs text-textNeutral-dark">{plugin.fr}</span>
                                             </li>
                                        ))
                                    }
                                    </ul>
                                </div>
                            )
                        }
                    </div>
                    <div className="w-full flex flex-col gap-y-3">
                        <h4>Incidents créés par {userData?.username}</h4>
                        <TableUserReports userId={userData?.id}/>
                    </div>
                </div>
            </Transition>
        </>
    );
};

export default UsersPageBrief;
