// External librairies
import React, { useContext } from "react";
import { Outlet } from "react-router-dom";

// Query
import { useQueryUser } from "../../queries";

// Context
import { AuthContext } from "../../contexts/AuthProvider";

// Components
import { NavigationHeader } from "../../components/Header";
import { Loader } from "../../utils/react-router";

const ProfilePageSelect = () => {
	const { user } = useContext(AuthContext);
	const { isLoading, data } = useQueryUser(user.id);

	const navData = [
		{
			id: 0,
			route: "brief",
			label: "Aperçu",
			isExtra: false
		},
		{
			id: 1,
			route: "edit",
			label: "Editer",
			isExtra: false
		},
	];

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<>
					<div>
						<NavigationHeader
							title="Mon profile"
							subTitle={data?.last_name + " " + data?.first_name}
							navData={navData}
							hasReturn={false}
						/>
					</div>
					<div className="mt-3 block max-w-full">
						<Outlet context={data} />
					</div>
				</>
			)}
		</>
	);
};

export default ProfilePageSelect;
