import React from "react";
import {Image, DeleteForever} from "@mui/icons-material";
import { IMAGES_URL } from "../../config/config";
import {ImagePreview} from "../Image";

const ImageInput = ({images = [], setImages, error, errorText}) => {
    const handleChange = (e) => {
        e.preventDefault();
        const newImages = Array.from(e.target.files);
        setImages([...images, ...newImages]);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        let imageTypes = ["image/png", "image/jpeg"];
        const newImages = Array.from(e.dataTransfer.files).filter(
            (file) => imageTypes.includes(file.type)
        );
        setImages([...images, ...newImages]);
    };

    const handleDelete = (index) => {
        const updatedImages = [...images];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
    };

    return (
        <div className="h-full w-full">
            <div
                className={`${images.length === 0 ? "h-0" : "w-full h-2/3 flex flex-row items-start gap-5"}`}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => handleDrop(e)}
            >
                {/* Affichage des images sélectionnées */}
                {images.map((image, index) => (
                    <div key={index} className="relative w-1/6">
                        {typeof image === "string" ? (
                            <ImagePreview image={image} />
                        ) : (
                            <figure className="block w-full h-full">
                                <img
                                    src={URL.createObjectURL(image)}
                                    alt={`${index + 1}`}
                                    className="rounded-xl w-full h-auto object-fill object-center"
                                />
                            </figure>
                        )}
                        <button
                            type="button"
                            className="absolute top-2 right-2 bg-white bg-opacity-50 rounded-full p-1"
                            onClick={() => handleDelete(index)}
                        >
                            <DeleteForever/>
                        </button>
                    </div>
                ))}
            </div>
            {/* Zone de dépôt d'image */}
            {/* Vérifie s'il y a déjà des images */}
            {images.length < 5 ? (
                <div className={`${images.length === 0 ? "h-full":"h-1/3"}`}>
                    <label
                        htmlFor="dropzone-file"
                        className="rounded-2xl w-full h-full border-2 border-dashed flex justify-center items-center cursor-pointer overflow-hidden transition-all hover:border-primary"
                    >
                        <div className="flex glex-row justify-center items-center pt-5 pb-6">
                            <i className="opacity-[.16]">
                                <Image sx={{fontSize: 90}}/>
                            </i>
                            <div className="flex flex-col items-center">
                                <p className="mb-2 flex flex-col items-center text-sm text-textNeutral-dark text-center opacity-[.75]">
                                <span className="font-semibold">
                                    Cliquez pour télécharger une image &nbsp;
                                </span>
                                </p>
                                <p className="text-xs text-textNeutral-dark">
                                    PNG ou JPG
                                </p>
                            </div>
                        </div>

                        {/* Input pour l'ajout d'image */}
                        <input
                            id="dropzone-file"
                            type="file"
                            className="hidden"
                            accept="image/png, image/jpeg"
                            multiple
                            onChange={(e) => handleChange(e)}
                        />
                    </label>
                </div>
            ) : null}
            {error && (
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                    {errorText}
                </p>
            )}
        </div>
    );
};

export default ImageInput;
