// External librairies
import React from "react";
import { useParams, Outlet } from "react-router-dom";

// Query
import { useQueryDirectorySelect } from "../../queries";

// Components
import { NavigationHeader } from "../../components";

// Misc
import { Loader } from "../../utils";

const DirectoryPageSelect = () => {
	const { id } = useParams();
	const { isLoading, data } = useQueryDirectorySelect(id);

	const navData = [
		{
			id: 0,
			route: "brief",
			label: "Aperçu",
			isExtra: false
		},
		{
			id: 1,
			route: "edit",
			label: "Editer",
			isExtra: false
		},
	];

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<>
					<div>
						<NavigationHeader
							title="Annuaire"
							subTitle={data?.first_name + " " + data?.last_name}
							returnUrl="/directory"
							navData={navData}
						/>
					</div>
					<div className="mt-3 block max-w-full">
						<Outlet context={data} />
					</div>
				</>
			)}
		</>
	);
};

export default DirectoryPageSelect;
