// External librairies
import {useQuery} from "@tanstack/react-query";

// Config
import instance from "../../config/axiosconfig";
import {refreshToken} from "../../utils";

export const useQueryNotifications = (currentPage, sort) => {
	return useQuery({
		queryKey: ["getNotifications", currentPage, sort],
		queryFn: async () => {
			try{
				return await instance.get(`/notifications?page=${currentPage}&sort=${sort.accessor}`)
			} catch (error) {
				if (error.response && error.response.status === 401) {
					await refreshToken();
					return await instance.get(`/notifications?page=${currentPage}&sort=${sort.accessor}`)
				}
				throw error
			}
		},
		select: (data) => {
			return data.data;
		},
	});
};

export const useQueryCountUnreadNotifications = () => {
	return useQuery({
		queryKey: ["countUnread"],
		queryFn: async () => {
			try{
				return await instance.get(`/notifications?read=not_read`)
			} catch (error) {
				if (error.response && error.response.status === 401) {
					await refreshToken();
					return await instance.get(`/notifications?read=not_read`)
				}
				throw error
			}
		},
		select: (data) => {
			return data.data.count;
		},
	});
}

