import Turnstone from "turnstone";

/***
 * Expected result format from the query :
 * [
 *  {
 *      id: xxx,
 *      value: xxx,
 *      image: xxx (optional)
 *  }
 * ]
 */
const SearchBoxInput = ({ id, label, text, fetchResults, onChange }) => {
	const listbox = {
		displayField: "results",
		data: async (query) => {
			return await fetchResults(query);
		},
		searchType: "contains",
	};

	const styles = {
		input: `relative border border-bgNeutral-elementborderlight dark:border-bgNeutral-dark shadow-custom bg-bgNeutral-elementlight dark:bg-bgNeutral-elementdark rounded-lg w-full px-4 py-3 text-xs text-textNeutral-dark dark:text-textNeutral-light transition-all outline-none focus:border-sky-500 focus:ring-2 focus:ring-primary cursor-text appearance-none`,
		listbox: "rounded-md border border-primary mt-2 w-full px-2 py-1 text-textNeutral-dark text-xs bg-bgNeutral-light shadow-custom",
		clearButton:
			"absolute inset-y-0 text-lg right-0 w-10 inline-flex items-center justify-center",
		item: "block rounded-md cursor-pointer py-2 px-4",
		highlightedItem:
			"block rounded-md cursor-pointer py-2 px-4 bg-primary text-white",
	};

	const Item = ({ item }) => {
		return <div>{item.value}</div>;
	};

	return (
		<div className="w-full md:w-[18rem] xl:w-[20.5rem] flex flex-col justify-start">
			{label && (
				<label
					htmlFor={id}
					className="mb-2 text-textNeutral-dark text-xs font-semibold uppercase tracking-tight opacity-75 dark:text-textNeutral-light"
				>
					{label}
				</label>
			)}

			<Turnstone
				id={id}
				name={label}
				autoFocus={false}
				typeahead={true}
				clearButton={true}
				debounceWait={250}
				defaultListboxIsImmutable={false}
				listboxIsImmutable={false}
				maxItems={6}
				noItemsMessage="Aucun résultat"
				placeholder={label}
				listbox={listbox}
				styles={styles}
				Item={Item}
				matchText={false}
				onEnter={onChange}
				onChange={onChange}
				onSelect={onChange}
				text={text}
			/>
		</div>
	);
};

export default SearchBoxInput;
