import {useState, useEffect, useContext} from "react";
import {AuthContext} from "../../contexts";
import axios from "axios";

import {useNavigate} from "react-router-dom";
import {BASE_URL} from "../../config/config";

export function useAxios(target, method, payload) {
    const [count, setCount] = useState(0);
    const [data, setData] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const {user} = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!target || !user) return;
        setLoading(true);
        request(target, method, payload, user.accessToken).then((res) => {
            setData(res.data);
            if (res.code !== 200) {
                setError(true);
            } else {
                setError(false);
            }
            if (res.count) {
                setCount(res.count);
            }
            setLoading(false);
        });
    }, [target, method, payload, navigate, user]);
    return {isLoading, data, error, count};
}

export function update(target, payload, token) {
    const config = {
        headers: {
            authorization: token,
        },
    };
    return axios.put(BASE_URL + target, {data: payload}, config);
}

export async function auth(payload, callback) {
    axios
        .post(BASE_URL + "/auth/login", payload)
        .then((res) => callback("ok", res.data.data))
        .catch((err) => callback("error", err.response.data.message));
}

export async function sendPasswordReset(payload, callback) {
    axios
        .post(BASE_URL + "/auth/forgot-password", payload)
        .then((r) => callback(r.status, "Un email vous a été envoyé"))
        .catch((err) => callback(err.status, err.response.data.message));
}

export async function resetPassword(payload, token, callback) {
    const headers = {authorization: token};
    axios
        .post(BASE_URL + "/auth/reset-password", payload, {headers})
        .then((res) => callback(res.status, "Mot de passe modifié avec succès"))
        .catch((err) => callback(err.status, err.response.data.message));
}

export async function uploadImage(payload) {
    const config = {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    };
    let formdata = new FormData();
    formdata.append("img", payload);
    return axios.post(BASE_URL + "/upload", formdata, config);
}

export async function request(target, method, payload, token) {
    const res = {code: null, data: null, message: null}
    const headers = {"authorization": token}
    let data = {...payload}
    if (payload && payload.image && payload.image !== "no-image.jpeg") {
        await uploadImage(payload.image)
            .then(response => {
                data = {...data, image: response.data.data}
            })
    }
    await axios.request({
        data,
        method,
        url: BASE_URL + target,
        headers

    })
        .then((response) => {
            res.code = response.status;
            res.data = response.data.data;
            res.message = response.data.message;
            if (response.data.count) {
                res.count = response.data.count;
            }
        })
        .catch((err) => {
            res.code = err.response.status;
            res.data = err.response.data.data;
            res.message = err.response.data.message;
        });
    return res;
}

export async function refreshToken() {
    let user = JSON.parse(localStorage.getItem('user'));

    try {
        // Appel à l'API pour renouveler le token
        const response = await axios.post(BASE_URL + '/auth/refreshToken', {
            refreshToken: user.refreshToken,
        });

        // Mettre à jour le nouveau token dans le local storage
        user = {...user, accessToken: response.data.accessToken, refreshToken: response.data.refreshToken}
        localStorage.setItem('user', JSON.stringify(user));

        // Retourner le nouveau token
        window.location.reload();
        return response.data.refreshToken;
    } catch (error) {
        // Gérer les erreurs de renouvellement du token
        console.error('Erreur lors du renouvellement du token :', error);
        localStorage.removeItem("user")
        window.location.href = "/login";
    }
}

export async function validateToken(token, refreshToken) {

    try {
        // Appel à l'API pour renouveler le token
        const response = await axios.post(BASE_URL + '/auth/valideToken', {
            "token": token,
            "refreshToken": refreshToken
        });

        return response.data
    } catch (error) {

        console.error('Erreur lors du check du token :', error);
    }
}
