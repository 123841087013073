import {Graphic, Stats, Summary, NextEvent} from "../../components/Dashboard";
import {ActionHeader} from "../../components/Header";
import {Transition} from "../../utils/react-router";
import {AuthContext} from "../../contexts";
import {useContext} from "react";

const HomePage = () => {
    const {user} = useContext(AuthContext)
    const checkRights = (neededPlugin) => {
        return (user && user.activePlugins?.includes(neededPlugin)) || (user && user.role === "ROLE_ADMIN")
    }
    return (
        <div className="flex flex-col gap-y-8">
            <ActionHeader icon="dashboard" title="Tableau de bord" hasReturn={false}/>
            <Transition>
                <div className="flex flex-col justify-between gap-y-10">
                    {
                        checkRights("reports") && (
                            <div className="w-full flex flex-col xl:flex-row gap-y-10 xl:gap-y-0">
                                <div className="w-full xl:w-2/3 flex items-end">
                                    <Graphic/>
                                </div>
                                <div className="w-full xl:w-1/3 flex items-end">
                                    <Summary/>
                                </div>
                            </div>
                        )
                    }
                    <div
                        className="pb-20xl xl:pb-0 w-full flex flex-col xl:flex-row lg-items-between gap-y-10 xl:gap-x-10 xl:gap-y-0">
                        {
                            checkRights("reports") && (
                                <div className="w-full xl:w-2/3 flex items-end">
                                    <Stats/>
                                </div>
                            )
                        }
                        {
                            checkRights("agenda") && (
                                <div className="w-full xl:w-1/3 flex items-end">
                                    <NextEvent/>
                                </div>
                            )
                        }
                    </div>
                </div>
            </Transition>
        </div>
    );
};

export default HomePage;
