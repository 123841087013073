// External librairies
import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {toast} from 'react-toastify';

// Queries
import {useMutationForumAdd, useQueryUsers} from '../../queries';

// Hooks
import {useLocalStorage} from '../../hooks';

// Components
import {
    SelectInput,
    NavigationHeader,
    TextInput,
    FormButton,
    ToggleInput, SelectMultiInput
} from '../../components';

// Config
import {formSchemaForumAdd} from '../../config/yupconfig';
import {ROLES} from '../../config/config';

// Misc
import {Transition} from '../../utils/';

const ForumPageCreate = () => {
    const [user] = useLocalStorage('user', null);
    const {mutate} = useMutationForumAdd();
    const {data: users} = useQueryUsers("role=admin,associate,manager");
    const navigate = useNavigate();

    const {
        register,
        formState: {errors, isValid},
        handleSubmit,
        control,
        reset
    } = useForm({
        resolver: yupResolver(formSchemaForumAdd)
    });

    useEffect(_ => {
        let defaultValues = {};
        defaultValues.moderators = [{id: user.id, username: user.username}];
        reset({...defaultValues})
    }, [])

    const onSubmit = async (data, e) => {
        e.preventDefault();

        let newForumAddData = {
            user: data.forumModerator || user.id,
            title: data.title,
            level: data.forumLevel,
            isPublic: data.forumOpen,
            moderators: data.moderators.map((moderator) => moderator.id)
        };

        mutate(newForumAddData, {
            onSuccess: () => {
                toast.success('Le canal a été créé !');
                navigate('/forum');
            },
            onError: () => toast.error('Une erreur est survenue')
        });
    };

    return (
        <Transition>
            <NavigationHeader
                title="Forum"
                subTitle="Ajouter un nouveau forum"
                returnUrl="/forum"
            />
            <div className="w-full lg:w-[46rem] flex flex-col gap-y-5">
                <form
                    className="flex flex-col gap-y-5"
                    onSubmit={(e) => {
                        handleSubmit(onSubmit)(e);
                    }}
                >
                    <div className="flex flex-col gap-y-8">
                        <div className="flex flex-col md:flex-row md:justify-between gap-y-8 md:gap-y-0 gap-x-5">
                            <div className="flex flex-col">
                                <ToggleInput
                                    id="forum-open"
                                    label="Ouvert au public"
                                    register={{
                                        ...register('forumOpen')
                                    }}
                                    error={errors?.forumOpen}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row md:justify-between gap-y-8 md:gap-y-0 gap-x-5">
                            <div className="flex flex-col">
                                <TextInput
                                    id="title"
                                    label="Nom du canal"
                                    register={{
                                        ...register('title')
                                    }}
                                    error={errors?.title}
                                />
                            </div>
                            <div className="flex flex-col">
                                <Controller
                                    name="moderators"
                                    control={control}
                                    render={({field}) => (
                                        <SelectMultiInput
                                            placeholder="Ajoutez des modérateurs"
                                            label="Modérateurs"
                                            options={users}
                                            field={field}
                                            error={
                                                errors?.moderators
                                            }
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row md:justify-between gap-y-8 md:gap-y-0 gap-x-5">
                            <div className="flex flex-col">
                                <SelectInput
                                    id="title"
                                    label="Rôle minimum pour accéder"
                                    items={ROLES}
                                    register={{
                                        ...register('forumLevel')
                                    }}
                                    error={errors?.forumLevel}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className="mt-5 w-full flex flex-col gap-y-3 md:flex-row md:justify-end md:items-center md:gap-x-4 md:gap-y-0">
                        <FormButton
                            caption="Ajouter un canal"
                            isValid={isValid}
                        />
                    </div>
                </form>
            </div>
        </Transition>
    );
};

export default ForumPageCreate;
