// External librairies
import { useMemo, useState } from "react";

// Query
import { useQueryUsersTable } from "../../queries";

// Hooks
import { UseTableWithColumns } from "../../hooks";

// Components
import { SelectListFilter, EditTableButton, UserAdd } from "../../components";

// Config
import { ROLES } from "../../config/config";

// Misc
import { Loader, Transition } from "../../utils";

const TableUsers = () => {
	const [roleFilter, setRoleFilter] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [sort, setSort] = useState({
		direction: "asc",
		accessor: "id",
	});

	const { isLoading, data } = useQueryUsersTable(
		currentPage,
		roleFilter,
		sort
	);

	const columns = useMemo(() => [
		{
			accessor: "action",
			Cell: ({ row: { original } }) => (
				<EditTableButton id={original.id} />
			),
		},
		{
			Header: "N°",
			accessor: "id",
			sortDirection: sort.accessor === "id" ? sort.direction : "none",
			Cell: ({ value }) => {
				return <span className="text-textNeutral-dark">{value}</span>;
			},
		},
		{
			Header: "Nom d'utilisateur",
			accessor: "username",
			sortDirection:
				sort.accessor === "username" ? sort.direction : "none",
			Cell: ({ value }) => {
				return <span className="text-textNeutral-dark">{value}</span>;
			},
		},
		{
			Header: "Email",
			accessor: "email",
			sortDirection: sort.accessor === "email" ? sort.direction : "none",
			Cell: ({ value }) => {
				return <span className="text-textNeutral-dark">{value}</span>;
			},
		},
		{
			Header: "Rôle",
			accessor: "role",
			sortDirection: sort.accessor === "role" ? sort.direction : "none",
			Cell: ({ row: { original } }) => {
				const translatedRole = ROLES.find(
					(role) => role.slug === original.role
				);
				return (
					<div className="block flex">
						<p
							className={`rounded-full border ${translatedRole.border} block px-3 px-5 py-1 text-center text-[10px] font-semibold ${translatedRole.bgFade} ${translatedRole.color} dark:text-textNeutral-light uppercase overflow-hidden whitespace-nowrap tracking-wide`}
						>
							{translatedRole.name}
						</p>
					</div>
				);
			},
		},
	]);

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<>
					<div className="h-full flex flex-col gap-y-5">
						<div className="flex gap-y-4 flex-col md:flex-row gap-x-4 md:gap-y-0">
							<SelectListFilter
								options={ROLES}
								select={roleFilter}
								setSelect={setRoleFilter}
								label="Rôle"
								placeholder="Rôle"
								isMulti={false}
								callBackAction={setCurrentPage}
							/>
						</div>
						<Transition>
							<div className="block max-w-full h-[50vh]">
								<div className="mb-16 flex flex-col gap-y-10">
									<UseTableWithColumns
										columns={columns}
										data={data?.data}
										sort={sort}
										setSort={setSort}
										currentPage={currentPage}
										setCurrentPage={setCurrentPage}
										count={data?.count}
									/>
								</div>
							</div>
						</Transition>
					</div>
				</>
			)}
		</>
	);
};

export default TableUsers;
