// External librairies
import {useQuery} from "@tanstack/react-query";

// Config
import instance from "../../config/axiosconfig";
import {refreshToken} from "../../utils";

export const useQueryNews = () => {
	return useQuery({
		queryKey: ["getNews"],
		queryFn: async () => {
			try{
				return await instance.get(`/news?sort=new_created&order=desc`)
			} catch (error) {
				if (error.response && error.response.status === 401) {
					await refreshToken();
					return instance.get(`/news?sort=new_created&order=desc`)
				}
				throw error
			}
		},
		select: (data) => {
			return data.data.data.map((e) => ({
				id: e.id,
				title: e.title,
				shortTitle: e.short_title,
				content: e.content,
				section: e.section,
				dateCreated: e.new_created,
				dateUpdated: e.new_updated,
				images: e.NewsImages
			}));
		},
	});
};

export const useQueryNewsSelect = (id) => {
	return useQuery({
		queryKey: ["getNewsSelect"],
		queryFn: async () => {
			try{
				return await instance.get(`/news/${id}`)
			} catch (error) {
				if (error.response && error.response.status === 401) {
					await refreshToken();
					return instance.get(`/news/${id}`)
				}
				throw error
			}
		},
		select: (data) => {
			return data.data.data;
		},
	});
};

export const useQueryNewsSections = () => {
	return useQuery({
		queryKey: ["getNews"],
		queryFn: async () => {
			try{
				return await instance.get(`/news?group=section&aggregate=count`)
			} catch (error) {
				if (error.response && error.response.status === 401) {
					await refreshToken();
					return await instance.get(`/news?group=section&aggregate=count`)
				}
				throw error
			}
		},
		select: (data) => {
			return data.data.data.map((section, id) => ({
				id,
				value: section.new_section
			}))
		},
	});
};
