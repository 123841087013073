// External librairies
import React from "react";

// Config
import { formatDateString } from "../../config/formats";

// Icons
import { LibraryBooks, LibraryAddCheck } from "@mui/icons-material";
import {IMAGES_URL} from "../../config/config";
import {Tag} from "../Tag";

const MainCardNews = ({ dateCreated, dateUpdated, title, shortTitle, content, image, section }) => {
	return (
		<div className="rounded-xl w-full lg:w-[920px] flex flex-col lg:flex-row lg:gap-x-10 gap-y-4 lg:gap-y-0 px-8 xl:px-7 py-5 xl:py-7 bg-bgNeutral-light shadow-custom transition-all xl:duration-300 xl:ease-in-out xl:hover:shadow-xl xl:hover:-translate-y-1">
			<div className="w-full lg:w-[420px]">
				<figure className="rounded-lg block w-full h-[20em] lg:h-full overflow-hidden">
					<img
						className="w-full h-full object-cover object-center"
						src={
							image === undefined
								? ("https://images.unsplash.com/photo-1618005198919-d3d4b5a92ead?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80")
								: (IMAGES_URL + image)
						}
						alt=""
					/>
				</figure>
			</div>
			<div className="w-full lg:w-2/5 flex flex-col gap-y-4 py-2">
				<div className="flex flex-col gap-y-2">
					<div className="flex items-center gap-x-2 text-xs text-textNeutral-dark/[.38]">
						<p className="flex items-center gap-x-1 text-xs text-textNeutral-dark/[.38]">
							<i>
								<LibraryBooks sx={{ fontSize: 16 }} />
							</i>
							Parution:
						</p>
						<span className="flex items-center text-xs text-textNeutral-dark/[.38] whitespace-nowrap">
							{formatDateString(dateCreated)}
						</span>
					</div>
					<div className="flex items-center gap-x-2 text-xs text-textNeutral-dark/[.38]">
						<p className="flex items-center gap-x-1 text-xs text-textNeutral-dark/[.38] whitespace-nowrap">
							<i>
								<LibraryAddCheck sx={{ fontSize: 16 }} />
							</i>
							Mise à jour:
						</p>
						<span className="flex items-center text-xs text-textNeutral-dark/[.38] whitespace-nowrap">
							{formatDateString(dateUpdated)}
						</span>
					</div>
				</div>
				<Tag caption={section} />
				<h2 className="text-[1.9em] lg:text-[2.5em] text-textNeutral-dark font-semibold leading-[1.1em]">
					{title}
				</h2>
				<h3>
					{shortTitle}
				</h3>
				<div
					className="quill-editor-theme pr-0 lg:pr-[2em] text-textNeutral-dark/[.72] line-clamp-3"
					dangerouslySetInnerHTML={{ __html: content }}
				></div>
			</div>
		</div>
	);
};

export default MainCardNews;
