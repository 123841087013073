import React, {useContext} from "react";
import {useForm} from "react-hook-form";
import {FormButton} from "../../components/Button";
import {toast, ToastContainer} from "react-toastify";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {DateInput, TextInput} from "../../components/Input";
import {useSearchParams} from "react-router-dom";
import {auth, resetPassword} from "../../utils";
import {AuthContext} from "../../contexts";

const NewUserLoginPage = () => {
    const { login } = useContext(AuthContext);
    const [searchParams] = useSearchParams();
    const validRoles = ["ROLE_ADMIN", "ROLE_MANAGER", "ROLE_ASSOCIATE"];

    const formSchema = Yup.object().shape({
        passwordOne: Yup.string()
            .min(8, "Le mot de passe doit contenir au moins 8 caractères")
            .max(16, "Le mot de passe ne peut excéder 16 caractères")
            .matches(/(?=.*[A-Z])/, "Doit contenir au moins une majuscule")
            .matches(/(?=.*[a-z])/, "Doit contenir au moins une miniscule")
            .matches(/(?=.*[0-9])/, "Doit contenir au moins un chiffre")
            .matches(
                /(?=.*[#?!@$%^&*-])/,
                "Doit contenir au moins un caractère spécial"
            ),
        passwordTwo: Yup.string()
            .min(8, "Le mot de passe doit contenir au moins 8 caractères")
            .max(16, "Le mot de passe ne peut excéder 16 caractères")
            .matches(/(?=.*[A-Z])/, "Doit contenir au moins une majuscule")
            .matches(/(?=.*[a-z])/, "Doit contenir au moins une miniscule")
            .matches(/(?=.*[0-9])/, "Doit contenir au moins un chiffre")
            .matches(
                /(?=.*[#?!@$%^&*-])/,
                "Doit contenir au moins un caractère spécial"
            )
            .oneOf(
                [Yup.ref("passwordOne")],
                "Les mots de passe ne correspondent pas"
            ),
    });
    const {
        register,
        formState: {errors, isValid},
        handleSubmit,
    } = useForm({resolver: yupResolver(formSchema)});

    const handleUpdateUser = (data, e) => {
        e.preventDefault();
        const payload = {
            password: data.passwordOne,
            birthday: new Date(data.birthday),
            username: data.username
        }
        const token = searchParams.get('token');
        resetPassword(payload, token, (status, message) => {
            if (status === 200) {
                toast.success(message)
                auth({username: payload.username, password: payload.password}, (status, res) => {
                    if (status === "ok") {
                        if (validRoles.includes(res.role)) {
                            login(res);
                            toast("Connexion réussie !");
                        } else {
                            toast.error("Vous n'êtes pas autorisé à vous connecter'");
                        }
                    } else {
                        toast.error("Mot de passe ou email incorrecte");
                    }
                })
            } else {
                toast.error(message)
            }
        })
    };

    return (
        <div className="bg-gradient-to-l from-primary to-secondary w-full h-[100vh] flex justify-center items-center">
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <div
                className="md:mx-0 md:shadow-xl w-full md:w-[860px] h-3/4 flex flex-col justify-end md:flex-row">
                <div
                    className="relative md:bg-gradient-to-br md:from-primary md:secondary w-full md:h-auto md:w-1/2 pb-10 md:pb-10 md:px-8 flex justify-center items-center">
					<span
                        className="absolute hidden md:block md:bottom-5 md:right-5 uppercase text-white font-bold text-xs">
						SMAPI
					</span>
                    <div className="flex justify-center items-center">
                        <figure className="w-32 md:w-56">
                            <img
                                className="w-full h-auto"
                                src={`${process.env.REACT_APP_API_URL}/uploads/logo.png`}
                                alt="city img"
                            />
                        </figure>
                    </div>
                </div>
                <div
                    className="bg-white rounded-t-[2em] md:rounded-t-none w-full md:w-1/2 h-auto flex flex-col justify-center">
                    <div className="mx-8 md:mx-12">
                        <div className="flex flex-col items-start gap-y-2 mb-10 text-textNeutral-dark">
                            <h2 className="font-semibold text-inherit">
                                Complétez vos informations
                            </h2>
                            <p className={"text-xs text-inherit"}>Complétez vos informations pour finaliser votre inscription.
                                Votre mot de passe doit contenir :</p>
                            <div className="px-4 text-xs text-inherit opacity-75">
                                <ul className="flex flex-col gap-y-1 text-xs list-disc">
                                    <li>Entre 8 et 16 caractères</li>
                                    <li>Une majuscule</li>
                                    <li>Une minuscule</li>
                                    <li>
                                        Un caractère spécial (ex. #?!@$%^&*-)
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <form
                            className="flex flex-col"
                            onSubmit={(e) => {
                                handleSubmit(handleUpdateUser)(e);
                            }}
                        >
                            <div className="mb-4 flex flex-col">
                                <TextInput
                                    id="username"
                                    label="Nom d'utilisateur"
                                    type="text"
                                    error={errors.username}
                                    register={{
                                        ...register("username", {
                                            required: true,
                                        }),
                                    }}
                                    placeholder="Nom d'utilisateur"
                                />
                            </div>

                            <div className="mb-4 flex flex-col">
                                <DateInput
                                    id="birthday"
                                    label="Date de naissance"
                                    register={{
                                        ...register("birthday", {
                                            required: true,
                                        }),
                                    }}
                                    placeholder="Nom d'utilisateur"
                                    error={errors.birthday}
                                />
                            </div>

                            <div className="mb-4 flex flex-col">
                                <TextInput
                                    id="passwordOne"
                                    label="Nouveau mot de passe"
                                    type="password"
                                    register={{
                                        ...register("passwordOne", {
                                            required: true,
                                        }),
                                    }}
                                    error={errors.passwordOne}
                                    placeholder="Nouveau mot de passe"
                                />
                            </div>
                            <div className="mb-4 flex flex-col">
                                <TextInput
                                    id="passwordTwo"
                                    label="Confirmer le nouveau mot de passe"
                                    type="password"
                                    register={{
                                        ...register("passwordTwo", {
                                            required: true,
                                        }),
                                    }}
                                    error={errors.passwordTwo}
                                    placeholder="Confirmer le nouveau mot de passe"
                                />
                            </div>
                            <div>
                                <div className="mt-12 md:mt-8 flex justify-center">
                                    <FormButton
                                        caption="Envoyer"
                                        isValid={isValid}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewUserLoginPage;
