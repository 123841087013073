import classNames from "classnames";
import {
	Warning,
	Event,
	SupervisedUserCircle,
	HomeOutlined,
	ArrowCircleUp,
} from "@mui/icons-material";

const FormButton = ({ caption, icon, isValid }) => {
	const getIcon = (value) => {
		switch (value) {
			case "issue":
				return <Warning />;
			case "event":
				return <Event />;
			case "user":
				return <SupervisedUserCircle />;
			case "home":
				return <HomeOutlined />;
			case "update":
				return <ArrowCircleUp />;
			default:
				return null;
		}
	};

	const currentIcon = getIcon(icon);

	return (
		<button
			type="submit"
			className={classNames(
				"rounded-full w-[17rem] flex justify-center items-center gap-x-3 px-8 py-3 text-textNeutral-light text-xs font-semibold uppercase tracking-wider transition-all cursor-pointer whitespace-nowrap hover:opacity-75",
				isValid
					? "bg-gradient-to-r from-secondary to-primary"
					: "bg-gray-400 opacity-75 cursor-forbidden"
			)}
		>
			{currentIcon}
			{caption}
		</button>
	);
};

export default FormButton;
