// External librairies
import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

// Queries
import { useMutationContactAdd } from "../../queries";

// Components
import {
	NavigationHeader,
	TextInput,
	DirectorySearchInput,
	TimeInput,
	AddButton,
	FormButton,
	SimpleCheckboxInput,
} from "../../components";

// Config
import { WEEKDAYS } from "../../config/config";
import { formSchemaAddContact } from "../../config/yupconfig";

// Icons
import { Cancel } from "@mui/icons-material";

// Misc
import { Transition } from "../../utils/";
import {getScheduleWithSumOfDays} from "./directoryHelpers";

const DirectoryPageCreateUser = () => {
	const { mutate: mutateContactAdd } = useMutationContactAdd();

	const navigate = useNavigate();

	const {
		register,
		formState: { errors, isValid },
		control,
		watch,
		handleSubmit,
		setValue,
	} = useForm({
		resolver: yupResolver(formSchemaAddContact),
	});

	// Declare dynamic fields commands
	const { fields, append, remove } = useFieldArray({
		control,
		name: "scheduleAdd",
	});

	// Watch search box input
	const watchSearhBox = watch("group");

	const handleOnSelectListItem = (value) => {
		setValue("group", value)
	}

	// Watch dynamic fields dor changes
	const watchFieldArray = watch("scheduleAdd");
	const controlledFields = fields.map((field, index) => {
		return {
			...field,
			...watchFieldArray[index],
		};
	});

	const onSubmit = async (data, e) => {
		e.preventDefault();

		const scheduleWithSumOfDays = getScheduleWithSumOfDays(data.scheduleAdd);

		let newContactAddData = {
			group: data.group,
			role: data.role,
			first_name: data.surname,
			last_name: data.name,
			mail: data.email,
			phone: data.phone,
			city: data.city,
			street: data.street,
			zip: data.zip,
			ContactSchedules: scheduleWithSumOfDays,
		};

		mutateContactAdd(newContactAddData, {
			onSuccess: () => {
				toast.success("Le contact a été ajouté!");
				navigate("/directory");
			},
			onError: () => toast.error("Une erreur est survenue"),
		});
	};

	return (
		<Transition>
			<NavigationHeader
				title="Annuaire"
				subTitle="Ajouter un contact"
				returnUrl="/directory"
			/>
			<div className="w-full lg:w-[46rem] flex flex-col gap-y-5">
				<form
					className="flex flex-col gap-y-5"
					onSubmit={(e) => {
						handleSubmit(onSubmit)(e);
					}}
				>
					<div className="flex flex-col gap-y-8">
						<div className="flex flex-col md:flex-row md:justify-between gap-y-8 md:gap-y-0 gap-x-5">
							<DirectorySearchInput
								id="group"
								label="Groupe"
								watchSearhBox={watchSearhBox}
								handleOnSelectListItem={handleOnSelectListItem}
								register={{
									...register("group"),
								}}
							/>
							<div className="flex flex-col">
								<TextInput
									id="role"
									label="Rôle"
									register={{
										...register("role", {
											required: true,
										}),
									}}
									error={errors?.role}
								/>
							</div>
						</div>
						<div className="flex flex-col md:flex-row md:justify-between gap-y-8 md:gap-y-0 gap-x-5">
							<div className="flex flex-col">
								<TextInput
									id="surname"
									label="Prénom"
									register={{
										...register("surname", {
											required: true,
										}),
									}}
									error={errors?.surname}
								/>
							</div>
							<div className="flex flex-col">
								<TextInput
									id="name"
									label="Nom de famille"
									register={{
										...register("name", {
											required: true,
										}),
									}}
									error={errors?.name}
								/>
							</div>
						</div>
						<div className="flex flex-col md:flex-row md:justify-between gap-y-8 md:gap-y-0 gap-x-5">
							<div className="flex flex-col">
								<TextInput
									id="email"
									label="Email"
									register={{
										...register("email", {
											required: true,
										}),
									}}
									error={errors?.email}
								/>
							</div>
							<div className="flex flex-col">
								<TextInput
									id="phone"
									label="Téléphone"
									register={{
										...register("phone"),
									}}
									error={errors?.phone}
								/>
							</div>
						</div>
						<div className="flex flex-col md:flex-row md:justify-between gap-y-8 md:gap-y-0 gap-x-5">
							<div className="flex flex-col">
								<TextInput
									id="city"
									label="Ville"
									register={{
										...register("city", {}),
									}}
									error={errors?.city}
								/>
							</div>
							<div className="flex flex-col">
								<TextInput
									id="street"
									label="Rue"
									register={{
										...register("street"),
									}}
									error={errors?.street}
								/>
							</div>
						</div>
						<div className="flex flex-col md:flex-row md:justify-between gap-y-8 md:gap-y-0 gap-x-5">
							<div className="flex flex-col">
								<TextInput
									id="zip"
									label="Code postal"
									register={{
										...register("zip", {}),
									}}
									error={errors?.zip}
								/>
							</div>
						</div>
					</div>
					<div className="mt-10 flex flex-col gap-y-6">
						<div className="border-b border-bgNeutral-headerBorder w-full flex flex-col gap-y-3 pb-5 md:flex-row md:justify-between md:items-center">
							<h3>Horaires</h3>
							<AddButton
								caption="Ajouter un horaire"
								icon="time"
								onClick={() => {
									append({
										morning_start: 0,
										morning_end: 0,
										afternoon_start: 0,
										afternoon_end: 0,
									});
								}}
							/>
						</div>
						<ul className="flex flex-col gap-y-5">
							{!controlledFields.length && (
								<Transition>
									<div className="w-full h-[10rem] flex justify-center items-center">
										<p className="text-textNeutral-dark/[.25] text-[22px] font-bold uppercase">
											Aucun horaire
										</p>
									</div>
								</Transition>
							)}
							{controlledFields.map((item, index) => {
								return (
									<li key={index}>
										<Transition>
											<li className="rounded-md flex flex-col gap-y-8 relative pt-12 pb-12 px-8 bg-[#C4C9D4]/[.18]">
												<button
													type="button"
													className="rounded-md w-10 h-10 flex justify-center items-center absolute top-3 right-3 p-3 bg-rose-400 cursor-pointer transition-all hover:opacity-75"
													onClick={() => {
														remove(index);
													}}
												>
													<i className="flex justify-center items-center text-textNeutral-light">
														<Cancel
															sx={{
																fontSize: 20,
															}}
														/>
													</i>
												</button>
												<h4>
													Horaire numéro {index + 1}
												</h4>
												<div className="grid grid-cols-2 gap-y-3 md:flex md:items-center md:gap-x-8 md:gap-y-0">
													{WEEKDAYS.map((day, i) => (
														<Controller
															key={i}
															control={control}
															name="content"
															render={({
																field: {
																	onChange,
																},
															}) => (
																<SimpleCheckboxInput
																	key={i}
																	name={
																		day.value
																	}
																	label={
																		day.label
																	}
																	value={
																		day.value
																	}
																	onChange={
																		onChange
																	}
																	register={{
																		...register(
																			`scheduleAdd.${index}.days`
																		),
																	}}
																/>
															)}
														/>
													))}
												</div>
												<div className="flex flex-col gap-y-5">
													<div className="flex flex-col gap-y-1">
														<h4 className="text-[11px] uppercase opacity-75">
															Matin
														</h4>
														<div className="flex flex-col md:flex-row md:justify-between gap-y-3 md:gap-y-0 gap-x-5">
															<TimeInput
																id="time-morning-start"
																register={{
																	...register(
																		`scheduleAdd.${index}.morning_start`
																	),
																}}
																error={
																	errors?.timeMorningStart
																}
															/>
															<TimeInput
																id="time-morning-end"
																register={{
																	...register(
																		`scheduleAdd.${index}.morning_end`
																	),
																}}
																error={
																	errors?.timeMorningEnd
																}
															/>
														</div>
													</div>
													<div className="flex flex-col gap-y-1">
														<h4 className="text-[11px] uppercase opacity-75">
															Après-midi
														</h4>
														<div className="flex flex-col md:flex-row md:justify-between gap-y-3 md:gap-y-0 gap-x-5">
															<TimeInput
																id="time-afternoon-start"
																register={{
																	...register(
																		`scheduleAdd.${index}.afternoon_start`
																	),
																}}
																error={
																	errors?.timeAfternoonStart
																}
															/>
															<TimeInput
																id="time-afternoon-end"
																register={{
																	...register(
																		`scheduleAdd.${index}.afternoon_end`
																	),
																}}
																error={
																	errors?.timeAfternoonEnd
																}
															/>
														</div>
													</div>
												</div>
											</li>
										</Transition>
									</li>
								);
							})}
						</ul>
					</div>
					<div className="mt-5 w-full flex flex-col gap-y-3 md:flex-row md:justify-end md:items-center md:gap-x-4 md:gap-y-0">
						<FormButton
							caption="Ajouter le contact"
							isValid={isValid}
						/>
					</div>
				</form>
			</div>
		</Transition>
	);
};

export default DirectoryPageCreateUser;
