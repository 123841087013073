// External librairies
import React from "react";
import { NavLink } from "react-router-dom";

// Query
import { useQueryDirectory } from "../../queries";

// Components
import { ActionHeader, DirectoryCard } from "../../components";

// Misc
import { Loader } from "../../utils";
import { Transition } from "../../utils/";

const DirectoryPage = () => {
	const { isLoading: isLoadingDataDirectory, data: dataDirectory } =
		useQueryDirectory();

	const actionData = [
		{
			id: 0,
			route: "create/user",
			label: "Ajouter un contact",
		},
	];

	return (
		<>
			<div>
				<ActionHeader
					icon="directory"
					title="Annuaire"
					actionData={actionData}
					hasReturn={false}
				/>
			</div>
			{isLoadingDataDirectory ? (
				<Loader />
			) : (
				<>
					<Transition>
						{
							dataDirectory ? (
								<div className="flex flex-col gap-y-[7em]">
									{dataDirectory.groups.map((g, i) => (
										<div key={i} className="flex flex-col">
											<h3 className="text-[2.2em] text-primary/[.55] uppercase tracking-wider font-bold">
												{g}
											</h3>
											<ul className="mt-8 w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-16">
												{dataDirectory.users.map((u) => {
													if (u.group === g) {
														return (
															<li key={u.id}>
																<NavLink to={`${u.id}/brief`}>
																	<DirectoryCard
																		user={u}
																	/>
																</NavLink>
															</li>
														);
													}
												})}
											</ul>
										</div>
									))}
								</div>
							) : (
								<div className="w-full h-full flex justify-center">
									<h3 className="mt-[20vh] text-xl lg:text-[2.6rem] opacity-[.18] text-center leading-[1.2em]">
										Il n'y actuellement aucun contact
									</h3>
								</div>
							)
						}

					</Transition>
				</>
			)}
		</>
	);
};

export default DirectoryPage;
