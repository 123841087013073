// Icons
import { AddCircle } from "@mui/icons-material";

const SurveyAddInputButton = ({ onClick, caption }) => {
	return (
		<div className="group rounded-[12px] border-2 border-bgNeutral-light flex justify-center bg-primary transition-all duration-300 hover:bg-bgNeutral-light hover:border-primary">
			<button
				type="button"
				onClick={onClick}
				className="w-full h-full flex flex-col items-center gap-y-3 p-2 text-[12px] text-bgNeutral-light font-semibold uppercase whitespace-nowrap transition-all duration-300 group-hover:text-primary"
			>
				<div className="flex items-center gap-x-3">
					<i className="text-bgNeutral-light font-semibold transition-all duration-300 group-hover:text-primary">
						<AddCircle sx={{ fontSize: 24 }} />
					</i>
					<span className="text-textNeutral-light font-semibold transition-all duration-300 group-hover:text-primary">{caption}</span>
				</div>
			</button>
		</div>
	);
};

export default SurveyAddInputButton;
