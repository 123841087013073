import { AnimatePresence, motion } from 'framer-motion';

const AnimationSide = ({ children }) => {
	return (
		<AnimatePresence>
			<motion.div
				className="w-full"
				initial={{ opacity: 0, x: 32 }}
				animate={{ opacity: 1, x: 0 }}
				exit={{ opacity: 0, x: 32 }}
			>
				{children}
			</motion.div>
		</AnimatePresence>
	);
};

export default AnimationSide;
