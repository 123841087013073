// External librairies
import { useEffect } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useForm, useFieldArray, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

// Queries
import { useMutationSurvey } from '../../queries';

// Components
import {
	NavigationHeader,
	FormButton,
	SurveyTextFieldsArray,
	SurveyTextAreaFieldsArray,
	SurveySelectFieldsArray,
	SurveyCheckboxFieldsArray,
	SurveyMenu,
	TextInput
} from '../../components';

// Config
import { formSchemaAddSurvey } from '../../config/yupconfig';

// Icons
import { ArrowForward, ArrowUpward } from '@mui/icons-material';

// Misc
import { Transition } from '../../utils/';
import { AnimationDown } from '../../animations';

const SurveyEdit = () => {
	const surveyData = useOutletContext();

	const { id } = useParams();
	const { mutate: mutateSurvey } = useMutationSurvey(id);

	const methods = useForm({ defaultValues: surveyData });

	const {
		control,
		register,
		formState: { errors, isValid },
		handleSubmit,
		watch,
		reset
	} = methods;

	// Global dynamic fields array
	const { fields, append, remove, move } = useFieldArray({
		control,
		name: 'fieldArray'
	});

	// Watch for dynamic changes
	const fieldArrayWatch = watch('fieldArray');

	const fieldArrayControlledFields = fields.map((field, index) => {
		return {
			...field,
			...fieldArrayWatch[index]
		};
	});

	// useEffect(() => {
	// 	let defaultValues = {}
	// 	defaultValues.surveyTitle = surveyData.surveyTitle,
	// 	defaultValues.fieldArray = surveyData.fieldArray
	// 	console.log("---- defaultValues: ", defaultValues);
	// 	reset({ ...defaultValues });
	// }, []);

	const onSubmit = async (data, e) => {
		e.preventDefault();

		mutateSurvey(data, {
			onSuccess: () => {
				toast.success('Le questionnaire a été mis à jour !!');
			},
			onError: () => toast.error('Une erreur est survenue')
		});
	};

	return (
		<div className="w-full 2xl:w-[55%]">
			<FormProvider {...methods}>
				<form
					className="flex flex-col gap-y-12"
					onSubmit={(e) => {
						handleSubmit(onSubmit)(e);
					}}
				>
					<div className="flex flex-col gap-y-3">
						<h2>Nom du questionnaire</h2>
						<TextInput
							id="survey-title"
							placeholder="Nom du questionnaire"
							register={{
								...register('surveyTitle', {
									required: true
								})
							}}
							error={errors?.surveyTitle}
						/>
					</div>
					<ul className="border-[1px] border-bgNeutral-dark/[.1] rounded-[12px] flex flex-col gap-y-8 p-12 bg-bgNeutral-light">
						{fieldArrayControlledFields.map((field, index) => {
							return (
								<li key={field.id} className="pt-10">
									<div className="w-full flex items-end gap-x-5">
										{field.type === 'text' && (
											<SurveyTextFieldsArray
												name={`fieldArray.${index}.textInput`}
												index={index}
												fields={fields}
												currentField={field}
												move={move}
												remove={remove}
											/>
										)}
										{field.type === 'textarea' && (
											<SurveyTextAreaFieldsArray
												name={`fieldArray.${index}.textareaInput`}
												index={index}
												fields={fields}
												currentField={field}
												move={move}
												remove={remove}
											/>
										)}
										{field.type === 'select' && (
											<SurveySelectFieldsArray
												name={`fieldArray.${index}.selectInput`}
												index={index}
												fields={fields}
												currentField={field}
												move={move}
												remove={remove}
											/>
										)}
										{field.type === 'checkbox' && (
											<SurveyCheckboxFieldsArray
												name={`fieldArray.${index}.checkboxInput`}
												index={index}
												fields={fields}
												currentField={field}
												move={move}
												remove={remove}
											/>
										)}
									</div>
								</li>
							);
						})}
					</ul>
					<div className="mt-5 w-full flex flex-col gap-y-3 md:flex-row md:justify-end md:items-center md:gap-x-4 md:gap-y-0">
						{fields.length != 0 && (
							<div className="w-full flex items-center justify-end">
								<AnimationDown>
									<FormButton
										caption="Mettre à jour le questionnaire"
										isValid={isValid}
									/>
								</AnimationDown>
							</div>
						)}
					</div>
				</form>
			</FormProvider>
		</div>
	);
};

export default SurveyEdit;
