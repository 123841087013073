// External librairies
import React, { useState } from "react";

// Queries
import { useQueryContactGroup } from "../../queries";

// Components
import { ErrorMessage, FormLabel } from "../../components";

const SearchInput = ({
	label,
	id,
	watchSearhBox,
	handleOnSelectListItem,
	register,
	error,
	isDirty,
	...rest
}) => {
	const [currentValue, setCurrentValue] = useState("");
	const { data, isFetching } = useQueryContactGroup(watchSearhBox);

	return (
		<div className="w-full md:w-[18rem] xl:w-[20.5rem] flex flex-col justify-start">
			<FormLabel htmlFor={id}>{label}</FormLabel>
			<input
				type="text"
				id={id}
				name={id}
				placeholder={label}
				className={`border ${
					error ? "border-red" : "border-bgNeutral-elementborderlight"
				} dark:border-bgNeutral-dark bg-bgNeutral-elementlight dark:bg-bgNeutral-elementdark shadow-custom rounded-lg w-full px-4 py-3 text-xs text-textNeutral-dark dark:text-textNeutral-light transition-all outline-none placeholder:text-[11px] focus:border-primary focus:ring-2 focus:ring-primary cursor-text`}
				{...register}
				{...rest}
			/>
			{!isFetching && watchSearhBox !== currentValue && isDirty && (
				<div className="border border-primary rounded-lg mt-2 w-full bg-bgNeutral-light shadow-custom">
					<ul className="flex flex-col gap-y-1.5">
						{data?.map((r, i) => (
							<li
								key={i}
								onClick={(e) => (
									handleOnSelectListItem(
										e.target.textContent
									),
									setCurrentValue(e.target.textContent)
								)}
								className="rounded-sm max-h-[5em] px-3 py-2 text-[14px] text-textNeutral-dark overflow-x-scroll cursor-pointer transition-all hover:bg-primary hover:text-textNeutral-light"
							>
								{r}
							</li>
						))}
						{watchSearhBox && !data?.length && (
							<li className="px-3 py-2 text-[12px] text-textNeutral-dark/[.75]">
								Aucun résultat
							</li>
						)}
					</ul>
				</div>
			)}
			{error && (
				<ErrorMessage
					type={error.type == "required" ? "error" : "pattern"}
					message={error.message}
				/>
			)}
		</div>
	);
};

export default SearchInput;
