import React from "react";
import classNames from "classnames";

const CommentButton = ({ caption, isValid }) => {
	return (
		<button
			type="submit"
			className={classNames(
				"rounded-lg w-full h-[calc(100%-24px)] flex justify-center items-center gap-x-3 px-8 py-4 text-textNeutral-light text-xs font-semibold uppercase tracking-wider transition-all cursor-pointer whitespace-nowrap hover:opacity-75",
				isValid
					? "bg-gradient-to-r from-secondary to-primary"
					: "bg-gray-400 opacity-75 cursor-forbidden"
			)}
		>
			{caption}
		</button>
	);
};

export default CommentButton;
