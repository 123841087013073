// External librairies
import {useQuery} from "@tanstack/react-query";

// Config
import instance from "../../config/axiosconfig";
import {refreshToken} from "../../utils";

export const useQueryReport = (id) => {
	return useQuery({
		queryKey: ["getReport"],
		queryFn: async () => {
			try{
				return await instance.get(`/reports/${id}`)
			} catch (error) {
				if (error.response && error.response.status === 401) {
					await refreshToken();
					return instance.get(`/reports/${id}`)
				}
				throw error
			}
		},
		select: (data) => {
			return data.data.data;
		},
	});
}

export const useQueryIssuesTable = (currentPage, statusFilter, categoriesFilter, sort) => {
	return useQuery({
		queryKey: ["getIssuesTable", currentPage, statusFilter, categoriesFilter, sort],
		queryFn: async () => {
			try {
				return await instance.get(
					`/reports?page=${currentPage}&status=${statusFilter}&category=${categoriesFilter}&sort=${sort.accessor}&order=${sort.direction}`
				);
			} catch (error) {
				if (error.response && error.response.status === 401) {
					await refreshToken();
					return await instance.get(
						`/reports?page=${currentPage}&status=${statusFilter}&category=${categoriesFilter}&sort=${sort.accessor}&order=${sort.direction}`
					)
				}
				throw error
			}
		},
		select: (data) => {
			return data.data;
		},
	});
};

export const useQueryComments = (id) => {
	return useQuery({
		queryKey: ["getComments", id],
		queryFn: async () => {
			try{
				return await instance.get(`/comments?report=${id}`)
			} catch (error) {
				if (error.response && error.response.status === 401) {
					await refreshToken();
					return instance.get(`/comments?report=${id}`);
				}
				throw error;
			}
		},
		select: (data) => {
			return data.data.data;
		},
	});
}

export const useQueryUserIssuesTable = (userId, currentPage, sort) => {
	return useQuery({
		queryKey: [`getUserIssuesTable-${userId}`, currentPage, sort],
		queryFn: async () => {
			try{
				return await instance.get(
					`/reports?user=${userId}&page=${currentPage}&sort=${sort.accessor}&order=${sort.direction}&status=new,ongoing,over,archived`
				)
			} catch (error) {
				if (error.response && error.response.status === 401) {
					await refreshToken();
					return instance.get(
						`/reports?user=${userId}&page=${currentPage}&sort=${sort.accessor}&order=${sort.direction}status=new,ongoing,over,archived`
					)
				}
				throw error
			}
		},
		select: (data) => {
			return data.data;
		},
	});
};

