// External librairies
import React from "react";
import { useParams, Outlet } from "react-router-dom";

// Query
import { useQueryComments, useQueryReport } from "../../queries";

// Components
import {NavigationHeader, NoData} from "../../components";

// Misc
import { Loader } from "../../utils";

const ReportsPageSelect = () => {
	const { id } = useParams();
	const { isLoading, data } = useQueryReport(id);
	const { data: dataComments } = useQueryComments(id);

	const navData = [
		{
			id: 0,
			route: "brief",
			label: "Aperçu",
			isExtra: false
		},
		{
			id: 1,
			route: "edit",
			label: "Editer",
			isExtra: false
		},
		{
			id: 2,
			route: "comments",
			label: `Commentaires (${
				dataComments?.length > 0 ? dataComments.length : 0
			})`,
			isExtra: false
		},
	];

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				!data ? (
					<NoData caption="Aucun signalement à afficher" />
				) : (
				<>
					<div>
						<NavigationHeader
							title="Incident"
							subTitle={data?.title}
							navData={navData}
							returnUrl="/reports"
						/>
					</div>
					<div className="mt-3 block max-w-full">
						<Outlet context={data} />
					</div>
				</>
			))}
		</>
	);
};

export default ReportsPageSelect;
