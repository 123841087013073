import { useMemo } from "react";
import { createAvatar } from "@dicebear/core";
import { micah } from "@dicebear/collection";
import { AccountCircle } from "@mui/icons-material";

const Avatar = ({ userAvatar, ...props }) => {
	const avatarData = userAvatar ? JSON.parse(userAvatar) : null;

	const avatar = useMemo(() => {
		if (avatarData && Object.keys(avatarData).length > 0) {
			return createAvatar(micah, {
				...avatarData,
			}).toDataUriSync();
		}
		return null;
	}, [avatarData]);

	if (!avatar) {
		return (
			<div className="w-full h-full flex justify-center items-center">
				<AccountCircle sx={{ fontSize: 32 }} {...props} />
			</div>
		);
	} else {
		return <img src={avatar} alt="Avatar" {...props} />;
	}
};

export default Avatar;
