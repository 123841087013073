// External Librairies
import React from "react";

// Query
import {useMutationJobDelete, useQueryJobSelect} from "../../queries";

// Components
import {Tag} from "../../components";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

// Misc
import {AccessTime, Work, PinDrop, Business, Event} from "@mui/icons-material";
import {Loader} from "../../utils";
import {formatDateString} from "../../config/formats";
import {NavLink} from "react-router-dom";
import {toast} from "react-toastify";

const JobDetails = ({id, refetch}) => {
    const {isLoading, data: job} = useQueryJobSelect(id);
    const {mutate: deleteJob} = useMutationJobDelete();

    if (isLoading || id === null) {
        return <Loader/>
    }

    const handleDelete = _ => {
        deleteJob(job.id, {
            onSuccess: () => {
                toast.success("L'offre d'emploi a été supprimée");
                refetch()
            }
        })
    }

    return (
        <div>
            <div className="rounded-lg flex flex-col gap-y-8 px-6 pb-6 bg-bgNeutral-light overflow-auto shadow-custom">
                <header
                    className="mt-6 mb-[3em] w-full flex flex-col lg:flex-row lg:justify-between lg:items-center lg:gap-y-0 border-b border-bgNeutral-dark/[.09] gap-y-8 pb-6">
                    <div className="flex items-center gap-x-5">
                        <div className="flex flex-col gap-y-1 md:flex-row md:items-center md:gap-x-3 md:gap-y-0">
                            <div className="flex items-center gap-x-3">
                                <i className="text-primary"><Business sx={{fontSize: 24}}/></i>
                                <h1 className="text-primary">{job.entrepriseName}</h1>
                                <Tag caption={formatDateString(job.job_updated)}/>
                            </div>
                        </div>
                    </div>
                    <ul className="flex items-center gap-x-3">
                        <li className="rounded-lg flex justify-center items-center gap-x-4 transition-all hover:opacity-[.85] border-0 bg-primary">
                            <NavLink to={`${job.id}/edit`}
                                     className="flex justify-between items-center gap-x-4 px-6 py-3">
                                <span className="text-sm font-semibold whitespace-nowrap text-textNeutral-light">
                                    Modifier
                                </span>
                            </NavLink>
                        </li>
                        <li className="rounded-lg flex justify-center items-center gap-x-4 transition-all hover:opacity-[.85] border-0 bg-rose-400">
                            <Popup
                                trigger={
                                    <button className="flex justify-between items-center gap-x-4 px-6 py-3">
                                        <span
                                            className="text-sm font-semibold whitespace-nowrap text-textNeutral-light">
                                            Supprimer
                                        </span>
                                    </button>
                                }
                                modal
                                contentStyle={{
                                    "display":"flex",
                                    "flexDirection":"column",
                                    "borderRadius":"0.5rem",
                                    "width":"30%"
                            }}
                            >
                                {close => (
                                    <div className="flex flex-col gap-y-8 p-6 bg-bgNeutral-light overflow-auto">
                                        <div className="text-lg text-center">
                                            Êtes vous sûr de vouloir supprimer cette offre d'emploi ?
                                        </div>
                                            <ul className="flex self-center items-center gap-x-3">
                                                <li className="rounded-lg flex justify-center items-center gap-x-4 transition-all hover:opacity-[.85] border-0 bg-primary">
                                                    <button onClick={close}
                                                             className="flex justify-between items-center gap-x-4 px-6 py-3">
                                                        <span className="text-sm font-semibold whitespace-nowrap text-textNeutral-light">
                                                            Annuler
                                                        </span>
                                                    </button>
                                                </li>
                                                <li className="rounded-lg flex justify-center items-center gap-x-4 transition-all hover:opacity-[.85] border-0 bg-rose-400">
                                                    <button
                                                        onClick={_ => {
                                                            handleDelete()
                                                            close()
                                                        }}
                                                        className="flex justify-between items-center gap-x-4 px-6 py-3">
                                                        <span
                                                            className="text-sm font-semibold whitespace-nowrap text-textNeutral-light">
                                                            Supprimer
                                                        </span>
                                                    </button>
                                                </li>
                                            </ul>
                                    </div>
                                )}
                            </Popup>
                        </li>
                    </ul>
                </header>
                <h1>{job.title}</h1>
                <ul className="flex flex-col gap-y-0">
                    <li className="flex items-center gap-x-2">
                        <i className="text-textNeutral-dark">
                            <Event sx={{fontSize: 18}}/>
                        </i>
                        <span className="text-textNeutral-dark text-md">
									{formatDateString(job.startTime)}
								</span>
                    </li>
                    <li className="flex items-center gap-x-2">
                        <i className="text-textNeutral-dark">
                            <PinDrop sx={{fontSize: 18}}/>
                        </i>
                        <span className="text-textNeutral-dark text-md">
									{job.address}
								</span>
                    </li>
                    <li className="flex items-center gap-x-2">
                        <i className="text-textNeutral-dark">
                            <Work sx={{fontSize: 18}}/>
                        </i>
                        <span className="text-textNeutral-dark text-md">
									{job.contractType}
								</span>
                    </li>
                    <li className="flex items-center gap-x-2">
                        <i className="text-textNeutral-dark">
                            <AccessTime sx={{fontSize: 18}}/>
                        </i>
                        <span className="text-textNeutral-dark text-md">
									{job.workTime}
								</span>
                    </li>
                </ul>
                <div
                    className="w-2/3 font-light opacity-[.85]"
                    dangerouslySetInnerHTML={{__html: job.description}}
                />
            </div>
        </div>
    );
};

export default JobDetails;
