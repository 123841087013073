import axios from "axios";
import { BASE_URL } from "./config";

const instance = axios.create({
	baseURL: BASE_URL,
	headers: {
		Authorization: localStorage.getItem("user")
			? JSON.parse(localStorage.getItem("user"))?.accessToken
			: null,
	},
});

export default instance;

export const instanceFileUpload = axios.create({
	baseURL: BASE_URL,
	headers: {
		"Content-Type": "multipart/form-data",
		Authorization: localStorage.getItem("user")
			? JSON.parse(localStorage.getItem("user"))?.accessToken
			: null,
	},
});

export const openAgenda = axios.create({
	baseURL: `https://api.openagenda.com/v2/agendas/${process.env.REACT_APP_OPENAGENDA_UID}`,
});

//https://api.openagenda.com/v2/agendas/{agendaUID}/events?key={VOTRECLE}&lang=fr

