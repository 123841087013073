// External librairies
import React from 'react';
import {Outlet, useParams} from 'react-router-dom';

// Components
import { NavigationHeader } from '../../components';
import {useQueryForum} from "../../queries";

const ForumPageSelect = () => {
	const { id } = useParams();
	const { data: forumData } = useQueryForum(id);

	const navData = [
		{
			id: 0,
			route: 'brief',
			label: 'Aperçu',
			isExtra: false
		},
		{
			id: 1,
			route: 'settings',
			label: 'Paramètres',
			isExtra: true
		}
	];

	return (
		<>
			<div>
				<NavigationHeader
					title="Forums"
					subTitle={forumData?.title}
					returnUrl="/forum"
					navData={navData}
				/>
			</div>
			<div className="mt-3 block max-w-full h-full">
				<Outlet context={forumData} />
			</div>
		</>
	);
};

export default ForumPageSelect;
