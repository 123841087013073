// External librairies
import { useFieldArray, Controller, useFormContext } from 'react-hook-form';

// Components
import {
	SelectFieldInput,
	SurveyAddInputButton,
	SurveyRemoveInputButton,
	SurveyMoveUpInputButton,
	SurveyMoveDownInputButton
} from '../../../components';

// Animations
import { AnimationDown } from '../../../animations';

const NestedArraySelectInput = ({ nestIndex }) => {
	const { control } = useFormContext();

	const { fields, remove, append, move } = useFieldArray({
		name: `fieldArray.${nestIndex}.options`
	});
	return (
		<div className="flex flex-col gap-y-8">
			<div className="w-[85%] flex flex-col gap-y-5">
				{fields.map((item, k) => {
					return (
						<div key={item.id}>
							<AnimationDown>
								<div className="mb-5 flex gap-x-8">
									<div className="w-[65%]">
										<Controller
											control={control}
											name={`fieldArray.${nestIndex}.options.${k}`}
											rules={{ required: true }}
											render={({
												field,
												fieldState: { error }
											}) => (
												<SelectFieldInput
													field={field}
													label="Intitulé"
													error={error}
												/>
											)}
										/>
									</div>
									<div className="w-[35%] flex items-end gap-x-3 pb-[2.5px]">
										{fields.length >= 2 && (
											<div className="flex items-center gap-x-3">
												{fields[0].id != item.id && (
													<SurveyMoveUpInputButton
														onClick={() => {
															move(k, k - 1);
														}}
													/>
												)}
												{fields[fields.length - 1].id !=
													item.id && (
													<SurveyMoveDownInputButton
														onClick={() => {
															move(k, k + 1);
														}}
													/>
												)}
											</div>
										)}

										<SurveyRemoveInputButton
											onClick={() => {
												remove(k);
											}}
										/>
									</div>
								</div>
							</AnimationDown>
						</div>
					);
				})}
			</div>
			<SurveyAddInputButton
				caption="Ajouter un élément de liste"
				onClick={() => {
					append(undefined);
				}}
			/>
		</div>
	);
};

export default NestedArraySelectInput;
