// Icons
import { CancelRounded } from '@mui/icons-material';

const SurveyRemoveInputButton = ({ onClick }) => {
	return (
		<button
			type="button"
			className="rounded-md border-2 border-rose-400 w-9 h-9 flex justify-center items-center cursor-pointer bg-bgNeutral-light transition-all hover:opacity-75"
			onClick={onClick}
		>
			<i className="flex justify-center items-center text-rose-400">
				<CancelRounded sx={{ fontSize: 16 }} />
			</i>
		</button>
	);
};

export default SurveyRemoveInputButton;
