// External Librairies
import React, {useContext, useEffect, useState} from "react";
import {useOutletContext} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {toast} from "react-toastify";

// Context
import {AuthContext} from "../../contexts";

// Query
import {useQueryComments, useMutationCommentAdd} from "../../queries";

// Components
import {TextAreaInput, CommentButton} from "../../components";

// Config
import {formCommentsAdd} from "../../config/yupconfig";
import {formatDateString} from "../../config/formats";

// Misc
import {Transition} from "../../utils/react-router";

const ReportsPageComments = ({socket}) => {
    const issueData = useOutletContext();
    const {data: comments} = useQueryComments(issueData?.id);
    const {mutate} = useMutationCommentAdd();
    const {user} = useContext(AuthContext);

    const [commentList, setCommentList] = useState(comments || []);

    useEffect(() => {
        if (comments) {
            setCommentList(comments);
        }
    }, [comments]);

    useEffect(() => {
        socket.on("receive_message", (data) => {
            const isDuplicate = commentList.some(comment => comment.id === data.id);
            if (!isDuplicate) {
                setCommentList((prevComments) => [...prevComments, data]);
            }
        });

        return () => {
            socket.off("receive_message");
        };

    }, [socket]);

    const {
        register,
        handleSubmit,
        reset,
        formState: {errors, isValid},
    } = useForm({resolver: yupResolver(formCommentsAdd)});

    const onSubmit = (data, e) => {
        e.preventDefault();
        const payload = {
            report: issueData.id,
            comment: data.comment,
            user: user.id,
        };
        mutate(payload, {
            onSuccess: (posted) => {
                socket.emit('send_message', posted.data.data);
                setCommentList((prevComments) => [...prevComments, posted.data.data]);
                toast.success("Commentaire publié !");
                reset();
            },
            onError: () => toast.error("Une erreur est survenue"),
        });
    };

    return (
        <Transition>
            <div className="w-full lg:w-[56em] flex flex-col gap-y-6">
                <form
                    className="mb-14 w-full flex flex-col gap-y-6 md:gap-y-0 md:flex-row gap-x-5"
                    onSubmit={(e) => {
                        handleSubmit(onSubmit)(e);
                    }}
                >
                    <div className="w-full flex items-center">
                        <TextAreaInput
                            id="comment"
                            label="Ajoutez un commentaire"
                            register={{
                                ...register("comment", {required: true}),
                            }}
                            error={errors?.comment}
                        />
                    </div>
                    <div className="flex items-end">
                        <CommentButton caption="Commenter" isValid={isValid}/>
                    </div>
                </form>
                <ul className="w-full md:w-[calc(100%-145px)] flex flex-col gap-y-2">
                    {commentList?.length > 0 &&
                        commentList.map((comment) => (
                            <li
                                key={comment.id}
                                className="mb-3 w-full flex flex-col gap-y-2"
                            >
                                <div className="w-full flex justify-end items-center px-6">
                                    <h3 className="text-textNeutral-dark text-xs font-light italic tracking-widest">
                                        {formatDateString(comment.com_created)}
                                    </h3>
                                </div>
                                <div
                                    className="rounded-[12px] flex gap-x-6 px-8 py-7 bg-bgNeutral-elementlight shadow-custom">
                                    <figure className="border-0 rounded-full block w-[3em] h-[3em] bg-gray-300">
                                        {/* <img
											className="rounded-lg w-full h-full object-cover object-center"
											src=""
											alt=""
										/> */}
                                    </figure>
                                    <div className="w-4/5 flex flex-col gap-y-1">
                                        <h4 className="text-[15px] text-primary font-semibold">
                                            {comment.User.username}
                                        </h4>
                                        <p className="text-textNeutral-dark text-[13px]">
                                            {comment.comment}
                                        </p>
                                    </div>
                                </div>
                            </li>
                        ))}
                </ul>
            </div>
        </Transition>
    );
};

export default ReportsPageComments;
