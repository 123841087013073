// External librairies
import React from "react";
import { useParams, Outlet } from "react-router-dom";

// Query
import { useQuerySurveySelect } from "../../queries";

// Components
import { NavigationHeader } from "../../components";

// Misc
import { Loader } from "../../utils";

const SurveyPageSelect = () => {
	const { id } = useParams();
	const { isLoading, data } = useQuerySurveySelect(id);

	const navData = [
		{
			id: 0,
			route: "brief",
			label: "Aperçu",
			isExtra: false
		},
		{
			id: 1,
			route: "edit",
			label: "Editer",
			isExtra: false
		},
		{
			id: 2,
			route: "export",
			label: "Exporter les réponses",
			isExtra: true
		},
	];

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<>
					<div>
						<NavigationHeader
							title="Questionnaires"
							subTitle={data?.title}
							returnUrl="/survey"
							navData={navData}
						/>
					</div>
					<div className="mt-3 block max-w-full">
						<Outlet context={data} />
					</div>
				</>
			)}
		</>
	);
};

export default SurveyPageSelect;
