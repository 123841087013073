// External librairies
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import classNames from "classnames";

// Context
import { AuthProvider } from "../contexts/AuthProvider";

// Components
import { SideBar } from "../navigation";
import { Header } from "../components/Header";

const Container = ({ children }) => {
	const [isOpen, setIsOpen] = useState(true);

	return (
		<AuthProvider>
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="colored"
			/>
			<div className="light relative w-full h-full pb-5 after:transition-all">
				<aside
					className={classNames(
						"z-[999] fixed left-5 top-3 h-full origin-left transition-all",
						isOpen ? "w-[13em]" : "w-[4.5em]"
					)}
				>
					<SideBar setIsOpen={setIsOpen} isOpen={isOpen} />
				</aside>
				<header
					className={classNames(
						"mt-12 pl-12 pr-12 transition-all",
						isOpen ? "ml-[14em]" : "ml-[5em]"
					)}
				>
					<Header />
				</header>
				<div
					className={classNames(
						"mt-5 h-full pl-12 pr-12 py-5 transition-all",
						isOpen ? "ml-[14em]" : "ml-[5em]"
					)}
				>
					{children}
				</div>
			</div>
		</AuthProvider>
	);
};

export default Container;
