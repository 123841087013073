// External librairies
import {useQuery} from "@tanstack/react-query";

// Query
import instance from "../../config/axiosconfig";

// Config
import {transformCategories} from "../../config/formats";
import {refreshToken} from "../../utils";

export const useQueryCategories = () => {
	return useQuery({
		queryKey: ["getCategory"],
		queryFn: async () => {
			try {
				return await instance.get(`/categories`)
			} catch (error) {
				if (error.response && error.response.status === 401) {
					await refreshToken();
					return instance.get("/categories")
				}
				throw error
			}
		},
		select: (data) => {
			return transformCategories(data.data.data);
		},
	});
};

