// External librairies
import React from "react";

// Components
import { ActionHeader, TableIssues } from "../../components";

// Misc
import { Transition } from "../../utils/";

const ReportsPageTable = () => {
	const actionData = [
		{
			id: 0,
			route: "create",
			label: "Ajouter un incident",
		},
	];

	return (
		<>
			<div>
				<ActionHeader icon="warning" title="Incidents" hasReturn={false} actionData={actionData} />
			</div>
			<>
				<Transition>
					<TableIssues />
				</Transition>
			</>
		</>
	);
};

export default ReportsPageTable;
