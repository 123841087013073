// External librairies
import { useContext } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

// Context
import { AuthContext } from "../contexts";

// Component
import ProfileCard from "../components/ProfileCard/ProfileCard";
import { Icon } from '@mui/material';

// Misc
import {
	Menu,
	ChevronLeft,
	Logout,
} from "@mui/icons-material";
import {slugToIcon} from "../config/formats";

const NavigationLink = ({ title, path, isAdmin = false, isOpen }) => {
	return (
		<NavLink
			className={(navData) =>
				navData.isActive
					? isAdmin
						? "w-full border flex items-center gap-x-3 p-1.5 px-[0.44em] rounded-md bg-roles-admin border-roles-admin text-textNeutral-light"
						: "w-full border flex items-center gap-x-3 p-1.5 px-[0.44em] rounded-md bg-primary border-primary text-textNeutral-light"
					: isAdmin
					? "w-full border border-transparent flex items-center gap-x-3 p-1.5 px-[0.44em] rounded-md hover:bg-roles-admin/[.12] hover:text-roles-admin hover:border-roles-admin transition-all"
					: "w-full border border-transparent flex items-center gap-x-3 p-1.5 px-[0.44em] rounded-md hover:bg-primary/[.12] hover:text-primary hover:border-primary transition-all"
			}
			to={path}
		>
			<Icon className={"text-inherit"} sx={{ fontSize: 18 }}>{slugToIcon[path]}</Icon>
			<span
				className={classNames(
					"flex text-[12px] font-medium text-inherit origin-left transition-all whitespace-nowrap transition-opacity duration-500",
					!isOpen && "opacity-0 invisible 2xl:block"
				)}
			>
				{title}
			</span>
		</NavLink>
	);
};

const SideBar = ({ setIsOpen, isOpen }) => {
	const { logout, user } = useContext(AuthContext);

	const sidebarLinks = [
		{
			title: "Tableau de bord",
			icon: "dashboard",
			path: "/",
			isAllowed: true,
		},
		{
			title: "Incidents",
			path: "reports",
			isAllowed: true,
		},
		{
			title: "Nouvelles",
			path: "news",
			isAllowed: process.env.REACT_APP_PLUGIN_NEWS === 'true',
		},
		{
			title: "Agenda",
			path: "agenda",
			isAllowed: process.env.REACT_APP_PLUGIN_AGENDA === 'true',
		},
		{
			title: "Annuaire",
			path: "directory",
			isAllowed: process.env.REACT_APP_PLUGIN_DIRECTORY === 'true',
		},
		{
			title: "Emplois",
			path: "job",
			isAllowed: process.env.REACT_APP_PLUGIN_JOB === 'true',
		},
		{
			title: "Questionnaires",
			path: "survey",
			isAllowed: process.env.REACT_APP_PLUGIN_SURVEY === 'true',
		},
		{
			title: "Forum",
			path: "forum",
			isAllowed: process.env.REACT_APP_PLUGIN_FORUM === 'true',
		},
		/*{
			title: "Commune",
			icon: <LocationCity sx={{ fontSize: 18 }} />,
			path: "CommunePage",
			isAllowed: process.env.REACT_APP_PLUGIN_REPORTS,
		},*/
		// {
		// 	title: "Paramètres",
		// 	icon: <Settings sx={{ fontSize: 18 }} />,
		// 	path: "settings/general",
		// 	isAllowed: true,
		// },
	];

	return (
		<div className="overflow-hidden relative shadow-custom bg-bgNeutral-light dark:bg-bgNeutral-elementdark rounded-lg w-full h-[95%] pt-5 px-[1.17em] flex flex-col justify-start">
			<span className="absolute top-5 left-1/2 -translate-x-1/2"></span>
			<div
				className={classNames(
					"flex",
					isOpen ? "justify-between" : "justify-center"
				)}
			>
				<h1
					className={classNames(
						"font-bold text-textNeutral-dark text-[15px] px-2 dark:text-textNeutral-light block",
						!isOpen && "hidden"
					)}
				>
					Smapi
				</h1>
				<div className="text-textNeutral-dark dark:text-textNeutral-light">
					{isOpen ? (
						<button onClick={() => setIsOpen(false)} className="rounded-md flex justify-center items-center bg-bgNeutral-dark/[.06] p-2">
							<ChevronLeft sx={{fontSize: 18}} />
						</button>
					) : (
						<button onClick={() => setIsOpen(true)}>
							<Menu />
						</button>
					)}
				</div>
			</div>
			<div className="mt-[10em]">
				<ul className="flex flex-col gap-y-1">
					{sidebarLinks
						.filter((link) => link.isAllowed === true)
						.map((link, index) => {
                            if ((user && user.activePlugins?.includes(link.path)) || (user && user.role === "ROLE_ADMIN") || link.path === "/") {
                                return (
                                    <li
                                        key={index}
                                        className="mb-2 w-full text-textNeutral-dark flex items-center"
                                    >
                                        <NavigationLink
                                            title={link.title}
                                            icon={link.icon}
                                            path={link.path}
                                            isOpen={isOpen}
                                        />
                                    </li>
                                )
                            }
                        })}
					{user && user.role === "ROLE_ADMIN" && (
						<div className="mt-2 pt-5 border-t border-bgNeutral-dark/[.22] dark:border-bgNeutral-light/[.22] flex">
							<NavigationLink
								className="text-textNeutral-light"
								title="Utilisateurs"
								icon="users"
								path="users"
								isAdmin={true}
								isOpen={isOpen}
							/>
						</div>
					)}
				</ul>
			</div>
			<div className="flex flex-col gap-y-7 mt-auto mb-5">
				<NavLink to="profile/brief">
					<ProfileCard isOpen={isOpen} user={user} />
				</NavLink>
				<div className="mb-2 w-full flex">
					<button
						className="flex items-center gap-x-2 pl-[0.62em] text-xs text-textNeutral-dark dark:text-textNeutral-light"
						onClick={() => logout()}
					>
						<Logout sx={{ fontSize: 18 }} />
						<span
							className={classNames(
								"transition-opacity duration-500",
								!isOpen && "opacity-0 invisible 2xl:block"
							)}
						>
							Déconnexion
						</span>
					</button>
				</div>
			</div>
		</div>
	);
};

export default SideBar;
