import {ChevronLeft, ChevronRight} from "@mui/icons-material";

const Toolbar = ({ label, onNavigate }) => {
    return (
        <header className="flex items-center justify-between py-4 lg:flex-none">
            <div className="flex items-center">
                <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
                    <button
                        type="button"
                        className="flex h-9 w-12 items-center justify-center rounded-l-md pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
                    >
                        <span className="sr-only">Mois d'avant</span>
                        <ChevronLeft
                            className="w-6 h-6 cursor-pointer"
                            onClick={_ => onNavigate("PREV")}
                        />
                    </button>
                    <button
                        type="button"
                        className="hidden px-3.5 text-sm font-semibold text-gray-900 md:block cursor-default"
                    >
                        {label}
                    </button>
                    <button
                        type="button"
                        className="flex h-9 w-12 items-center justify-center rounded-r-md pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
                    >
                        <span className="sr-only">Mois suivant</span>
                        <ChevronRight
                            className="w-6 h-6 cursor-pointer"
                            onClick={_ => onNavigate("NEXT")}
                        />
                    </button>
                </div>
            </div>
        </header>
    )
}

export default Toolbar