import { useMutation } from '@tanstack/react-query';
import instance from '../../config/axiosconfig';
import { refreshToken } from '../../utils';

export const useMutationForumAdd = () => {
	return useMutation({
		mutationKey: ['mutateForumAdd'],
		mutationFn: async (addForum) => {
			try {
				return await instance.post('/forums', addForum);
			} catch (error) {
				if (error.response && error.response.status === 401) {
					await refreshToken();
					return await instance.post('/forums', addForum);
				}
				throw error;
			}
		}
	});
};

export const useMutationForumMessageAdd = (id) => {
	return useMutation({
		mutationKey: ['mutateForumMessageAdd'],
		mutationFn: async (addForum) => {
			try {
				return await instance.post(`/forums/${id}/messages`, addForum);
			} catch (error) {
				if (error.response && error.response.status === 401) {
					await refreshToken();
					return await instance.post(`/forums/${id}/messages`, addForum);
				}
				throw error;
			}
		}
	});
};

export const useMutationForumSubscribe = (id) => {
	return useMutation({
		mutationKey: ['mutateForumSubscribe'],
		mutationFn: async (addForum) => {
			try {
				return await instance.post(`/forums/${id}/subscribers`, addForum);
			} catch (error) {
				if (error.response && error.response.status === 401) {
					await refreshToken();
					return await instance.post(`/forums/${id}/subscribers`, addForum);
				}
				throw error;
			}
		}
	});
};

export const useMutationForumUpdate = (id) => {
	return useMutation({
		mutationKey: ['mutateForumUpdate'],
		mutationFn: async (updatedForum) => {
			try {
				return await instance.put('/forums/' + id, updatedForum);
			} catch (error) {
				if (error.response && error.response.status === 401) {
					await refreshToken();
					return await instance.post('/forums/' + id, updatedForum);
				}
				throw error;
			}
		}
	});
};

export const useMutationSubscriberDelete = (id) => {
	return useMutation({
		mutationKey: ['deleteSubscription'],
		mutationFn: async (userId) => {
			try {
				return await instance.delete(`/forums/${id}/subscribers/${userId}`);
			} catch (error) {
				if (error.response && error.response.status === 401) {
					await refreshToken();
					return await instance.delete(`/forums/${id}/subscribers/${userId}`);
				}
				throw error;
			}
		}
	});
};

export const useMutationSubscriptionUpdate = (id) => {
	return useMutation({
		mutationKey: ['mutateSubscription'],
		mutationFn: async (updatedSubscription) => {
			try {
				return await instance.put(`/forums/${id}/subscribers/${updatedSubscription.id}`, updatedSubscription);
			} catch (error) {
				if (error.response && error.response.status === 401) {
					await refreshToken();
					return await instance.put(`/forums/${id}/subscribers/${updatedSubscription.id}`, updatedSubscription);
				}
				throw error;
			}
		}
	});
};