import { useNavigate } from "react-router-dom";

const CancelButton = ({ caption, backTo = "/", isDisabled = false }) => {
  const navigate = useNavigate();

  const cancelAction = (e) => {
    e.preventDefault();
    navigate(`/${backTo}`);
  };

  return (
    <button
      type="button"
      className="rounded-full border-2 border-error-light w-[17rem] px-6 py-3 text-error-light text-xs font-semibold uppercase tracking-wider hover:opacity-75 transition-all"
      onClick={(e) => cancelAction(e)}
      disabled={isDisabled}
    >
      {caption}
    </button>
  );
};

export default CancelButton;
