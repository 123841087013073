import {Public, PublicOff} from "@mui/icons-material";
import React from "react";
const PrivatePublicTag = ({isPublic}) => {
    return (
        <div className="flex items-center gap-x-1 right-5">
            <i className={`-mt-[.15rem] ${isPublic?"text-green-400":"text-rose-400"}`}>
                {isPublic ? (
                    <Public
                        sx={{
                            fontSize: 18
                        }}
                    />
                ) : (
                    <PublicOff
                        sx={{
                            fontSize: 18
                        }}
                    />
                )}
            </i>
            <span className={`font-light ${isPublic?"text-green-400":"text-rose-400"} text-[14px]`}>
				{isPublic ? "Public" : "Privé"}
			</span>
        </div>
    )
}

export default PrivatePublicTag;