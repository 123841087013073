// External librairies
import React from "react";
import { useOutletContext } from "react-router-dom";

// Queries
import { useQueryCategories } from "../../queries";

// Component
import { SimpleMap } from "../../components/Map";

// Config
import { STATUS, IMAGES_URL } from "../../config/config";
import { getColorByPrivacy, formatStatus } from "../../config/formats";

// Misc
import { Transition } from "../../utils/react-router";
import {ImageCarousel} from "../../components";

const ReportsPageBrief = () => {
	const reportData = useOutletContext();
	const { data: dataCategories } = useQueryCategories();

	const privacyColor = getColorByPrivacy(reportData?.isPublic);

	const status = STATUS.find((status) => status.slug === reportData?.status);

	const icon = dataCategories?.find(
		(cat) => cat.value === reportData?.category
	)?.icon;

	const cat = dataCategories?.find(
		(cat) => cat.value === reportData?.category
	).label;

	return (
		<Transition>
			<div className="flex flex-col gap-y-5">
				<div className="rounded-xl w-full flex gap-x-5 px-8 py-12 bg-bgNeutral-light shadow-custom">
					<div>
						<figure className="rounded-lg w-[16em] h-[16em] overflow-hidden">
							{
								reportData.ReportImages.length > 0 ? (
									<ImageCarousel images={reportData.ReportImages} />
								) : (
									<img
										className="rounded-lg w-full h-full object-cover object-center"
										src={IMAGES_URL + "no-image.jpeg"}
										alt={`${reportData?.title}_thumbnail`}
									/>
								)
							}

						</figure>
					</div>
					<div className="w-full flex flex-col gap-y-6 px-4">
						<div className="mt-2">
							<div className="flex justify-between items-center text-xs text-textNeutral-dark">
								<div className="flex items-center gap-x-4">
									<span
										className={`rounded-md px-3 py-1 ${privacyColor}`}
									>
										{reportData?.isPublic
											? "Publique"
											: "Privé"}
									</span>
									<span
										className={`rounded-full border ${status.border} flex justify-center items-center px-3 py-1 text-center text-[10px] font-semibold ${status.bgFade} ${status.color} uppercase overflow-hidden whitespace-nowrap`}
									>
										{formatStatus(reportData?.status)}
									</span>
								</div>
								<div className="rounded-lg flex gap-x-2 px-4 py-3 bg-bgNeutral-dark/[.04]">
									<span>{icon}</span>
									<span>{cat}</span>
								</div>
							</div>
						</div>
						<div className="flex flex-col gap-y-2">
							<h2>{reportData?.title}</h2>
							<p className="w-full lg:w-3/5 text-xs text-textNeutral-dark">
								{reportData?.description}
							</p>
						</div>
					</div>
				</div>
				<div className="rounded-lg mt-10 h-96 pb-5 shadow-custom overflow-hidden">
					<SimpleMap
						location={{
							lat: reportData?.lat,
							lng: reportData?.lon,
						}}
					/>
				</div>
			</div>
		</Transition>
	);
};

export default ReportsPageBrief;
