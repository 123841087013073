import { ErrorMessage } from "../Errors";
import FormLabel from "../Form/FormLabel";

const TextAreaInput = ({ label, id, register, error, ...rest }) => {
	return (
		<div className="w-full flex flex-col justify-start">
			<FormLabel htmlFor={id}>{label}</FormLabel>
			<textarea
				id={id}
				name={id}
				placeholder={label}
				className={`border ${
					error ? "border-red" : "border-bgNeutral-elementborderlight"
				} dark:border-bgNeutral-dark bg-bgNeutral-elementlight dark:bg-bgNeutral-elementdark shadow-custom rounded-lg w-full px-4 py-3 text-xs text-textNeutral-dark dark:text-textNeutral-light transition-all outline-none focus:border-primary focus:ring-2 focus:ring-primary cursor-text`}
				rows="6"
				cols="120"
				{...register}
				{...rest}
			/>

			{error && (
				<ErrorMessage
					type={error.type == "required" ? "error" : "pattern"}
					message={error.message}
				/>
			)}
		</div>
	);
};

export default TextAreaInput;
