// External Librairies
import React from "react";
import ReactQuill from "react-quill";

// Components
import { FormLabel } from "../Form";
import { ErrorMessage } from "../Errors";

// Static
import "react-quill/dist/quill.snow.css";

const EditorQuill = ({ label, onChange, value, error }) => {
	return (
		<div className="flex flex-col gap-y-1">
			<FormLabel>{label}</FormLabel>
			<ReactQuill
				theme="snow"
				className="bg-bgNeutral-light shadow-custom"
				value={value}
				onChange={onChange}
			/>
			{error && (
				<ErrorMessage
					type={error.type == "required" ? "error" : "pattern"}
					message={error.message}
				/>
			)}
		</div>
	);
};

export default EditorQuill;
