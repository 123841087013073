// External librairies
import {useMutation} from "@tanstack/react-query";

// Config
import instance from "../../config/axiosconfig";
import {refreshToken} from "../../utils";

export const useMutationAgenda = (id) => {
    return useMutation({
        mutationKey: ["mutateAgenda"],
        mutationFn: async (updateAgenda) => {
            try {
                return await instance.put(`/events/${id}`, updateAgenda)
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    await refreshToken();
                    return await instance.put(`/events/${id}`, updateAgenda)
                }
                throw error
            }
        },
    });
};

export const useMutationAgendaAdd = () => {
    return useMutation({
        mutationKey: ["mutateAgendaAdd"],
        mutationFn: async (addAgenda) => {
            try {
                return await instance.post('/events', addAgenda);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    await refreshToken();
                    return await instance.post('/events', addAgenda);
                }
                throw error
            }
        },
    });
};
