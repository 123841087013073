import {useOutletContext} from "react-router-dom";
import {useQuerySurveyResponsesSelect} from "../../queries";

const SurveyPageExport = () => {
    const surveyData = useOutletContext()
    const {data} = useQuerySurveyResponsesSelect(surveyData.id)

//TODO: Afficher la table avec les réponses
    return (
        <div>
            <h1>Export page</h1>
            <a href={`data:text/csv;charset=utf-8,${escape(data)}`}>Télécharger</a>
        </div>
    )
}

export default SurveyPageExport;