// Icons
import { KeyboardArrowUp } from '@mui/icons-material';

const SurveyMoveUpButton = ({ onClick }) => {
	return (
		<div>
			<button
				type="button"
				className="rounded-md w-10 h-10 flex justify-center items-center cursor-pointer bg-slate-600 transition-all hover:bg-slate-500"
				onClick={onClick}
			>
				<i className="flex justify-center items-center text-textNeutral-light">
					<KeyboardArrowUp sx={{ fontSize: 20 }} />
				</i>
			</button>
		</div>
	);
};

export default SurveyMoveUpButton;
