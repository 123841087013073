// External librairies
import React from "react";

// Components
import { ActionHeader, TableUsers } from "../../components";

// Misc
import { Transition } from "../../utils/";

const UsersPageTable = () => {
	const actionData = [
		{
			id: 0,
			route: "create",
			label: "Ajouter un utilisateur",
		},
	];

	return (
		<>
			<div>
				<ActionHeader
					icon="users"
					title="Utilisateurs"
					hasReturn={false}
					actionData={actionData}
				/>
			</div>
			<>
				<Transition>
					<TableUsers />
				</Transition>
			</>
		</>
	);
};

export default UsersPageTable;
