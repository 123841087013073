// External librairies
import {useQuery} from "@tanstack/react-query";

// Config
import instance, {openAgenda} from "../../config/axiosconfig";
import {refreshToken} from "../../utils";
import {formatDateString} from "../../config/formats";

export const useQueryAgenda = (params) => {
    return useQuery({
        queryKey: ["getAgenda"],
        queryFn: async () => {
            try {
                if (process.env.REACT_APP_AGENDA === "openagenda") {
                    return await openAgenda.get("/events?key=" + process.env.REACT_APP_OPENAGENDA_API_KEY + "&lang=fr&size=20&includeFields[]=uid&includeFields[]=firstTiming&includeFields[]=lastTiming&includeFields[]=title&includeFields[]=timings&includeFields[]=image&includeFields[]=location")
                } else {
                    return await instance.get(`/events?${params}`);
                }
            } catch (error) {
                if (process.env.REACT_APP_AGENDA === "openagenda") {

                } else {
                    if (error.response && error.response.status === 401) {
                        await refreshToken();
                        return await instance.get(`/events?${params}`);
                    }
                }
                throw error
            }
        },
        select: (data) => {
            if (process.env.REACT_APP_AGENDA === "openagenda") {
                const agenda = []
                data.data.events.forEach(event => {
                    event.timings.forEach(timing => {
                        agenda.push({
                            id: event.uid,
                            start: new Date(timing.begin),
                            end: new Date(timing.end),
                            title: event.title.fr,
                            image: event.image.base + event.image.filename,
                            color: 'bg-primary'
                        })
                    })
                })
                return agenda.sort((a, b) => a.start - b.start)
            } else {
                return data.data.data.map((e) => ({
                    id: e.id,
                    start: new Date(e.date),
                    end: new Date(e.end),
                    title: e.title,
                    color: 'bg-primary'
                })).sort((a, b) => a.start - b.start);
            }
        },
    });
};


export const useQueryAgendaSelect = (id) => {
    return useQuery({
        queryKey: ["getEventSelect"],
        queryFn: async () => {
            try {
                if (process.env.REACT_APP_AGENDA === "openagenda") {
                    return await openAgenda.get("/events?key=" + process.env.REACT_APP_OPENAGENDA_API_KEY + "&lang=fr&size=1&uid[]=" + id + "&includeFields[]=uid&includeFields[]=title&includeFields[]=firstTiming&includeFields[]=lastTiming&includeFields[]=longDescription&includeFields[]=description&includeFields[]=image&includeFields[]=location&includeFields[]=organisator_url&includeFields[]=publics-cibles&includeFields[]=dateRange")
                } else {
                    return await instance.get(`/events/${id}`)
                }
            } catch (error) {
                if (process.env.REACT_APP_AGENDA === "openagenda") {

                } else {
                    if (error.response && error.response.status === 401) {
                        await refreshToken();
                        return instance.get(`/events/${id}`);
                    }
                }
                throw error
            }
        },
        select: (data) => {
            if (process.env.REACT_APP_AGENDA === "openagenda") {
                const event = data.data.events[0];
                return {
                    title: event?.title.fr,
                    description: event?.description.fr,
                    longDescription: event?.longDescription.fr,
                    image: event?.image.base + event?.image.filename,
                    location: event?.location?.name + ", " + event?.location?.address,
                    dateRange: event?.dateRange.fr,
                    link: event?.organisator_url
                }
            } else {
                const event = data.data.data
                return {
                    title: event?.title,
                    description: event?.description,
                    image: event?.image,
                    location: event?.location,
                    dateRange: `${formatDateString(event.date)}${formatDateString(event.date) !== formatDateString(event.end) ? " au " + formatDateString(event.end) : ""}`,
                    link: event?.link
                };
            }

        },
    });
}

