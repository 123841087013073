import { NavLink } from "react-router-dom";

const LinkButton = ({ url }) => {
	return (
		<div className="absolute w-full h-full top-0 left-0">
			<NavLink
				to={`${url}`}
				className="w-full h-full flex items-center hover:border-2 hover:bg-primary/[.1] border-primary transition-all"
			></NavLink>
		</div>
	);
};

export default LinkButton;
