// External librairies
import {useQuery} from "@tanstack/react-query";

// Config
import instance from "../../config/axiosconfig";
import {refreshToken} from "../../utils";

export const useQueryJobs = (contractType, startDate, searchText) => {
	return useQuery({
		queryKey: ["getJobs", contractType, startDate, searchText],
		queryFn: async () => {
			try{
				return await instance.get(`/jobs?search=${searchText}&contractType=${contractType}&startDate=${startDate}`)
			} catch (error) {
				if (error.response && error.response.status === 401) {
					await refreshToken();
					return instance.get(`/jobs`);
				}
				throw error
			}
		},
		select: (data) => {
			return data.data.data;
		},
	});
};

export const useQueryJobSelect = (id) => {
	return useQuery({
		queryKey: ["getJobSelect", id],
		queryFn: async () => {
			try{
				return await instance.get(`/jobs/${id}`)
			} catch (error) {
				if (error.response && error.response.status === 401) {
					await refreshToken();
					return instance.get(`/jobs/${id}`);
				}
				throw error
			}
		},
		select: (data) => {
			return data.data.data;
		},
	});
};


