// External librairies
import {useMutation} from "@tanstack/react-query";

// Config
import instance from "../../config/axiosconfig";
import {refreshToken} from "../../utils";

export const useMutationNews = (id) => {
    return useMutation({
        mutationKey: ["mutateNews"],
        mutationFn: async (updateNews) => {
            try {
                return await instance.put(`/news/${id}`, updateNews)
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    await refreshToken();
                    return await instance.put(`/news/${id}`, updateNews)
                }
                throw error
            }
        },
    });
};

export const useMutationNewsAdd = () => {
    return useMutation({
        mutationKey: ["mutateNewsAdd"],
        mutationFn: async (addNews) => {
            try {
                return await instance.post("/news", addNews);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    await refreshToken();
                    return await instance.post("/news", addNews);
                }
                throw error
            }
        },
    });
};

export const useMutationNewsDelete = () => {
    return useMutation({
        mutationFn: async (newsId) => {
            try {
                return await instance.delete(`/news/${newsId}`)
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    await refreshToken();
                    return await instance.delete(`/news/${newsId}`)
                }
                throw error;
            }
        }
    })
}
