import React from 'react';

// Composants
import { FormLabel } from '../Form';

const ToggleInput = ({ label, placeholder, id, register, error, ...rest }) => {
	return (
		<div className="w-full md:w-[18rem] xl:w-[20.5rem] flex flex-col justify-start">
			<FormLabel htmlFor={id}>{label}</FormLabel>
			<label className="switch mt-2 relative inline-block w-[48px] h-[28px]">
				<input
					className="toggle opacity-0 w-0 h-0"
					type="checkbox"
					id={id}
					name={id}
					placeholder={label || placeholder}
					{...register}
					{...rest}
				/>
				<span className="slider rounded-[34px] absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-[#ccc] transition-all duration-300 before:rounded-full before:absolute before:content-[''] before:h-[20px] before:w-[20px] before:left-[4px] before:bottom-[4px] before:bg-white before:transition-all before:duration-300"></span>
			</label>
		</div>
	);
};

export default ToggleInput;
