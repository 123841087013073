// External librairies
import {useQuery} from "@tanstack/react-query";

// Config
import instance from "../../config/axiosconfig";
import {refreshToken} from "../../utils";

export const useQueryDirectory = () => {
	return useQuery({
		queryKey: ["getDirectory"],
		queryFn: async () => {
			try{
				return await instance.get(`/contacts`)
			} catch (error) {
				if (error.response && error.response.status === 401) {
					await refreshToken();
					return instance.get(`/contacts`)
				}
				throw error
			}
		},
		select: (data) => {
			const directoryEntity = data.data.data;
			const directoryFactored = [];
			const allGroupsArray = [];
			
			directoryEntity.map((c) => {
				allGroupsArray.push(c.group);
			});

			const uniqueGroups = allGroupsArray.filter((value, index) => {
				return allGroupsArray.indexOf(value) === index;
			});

			directoryFactored.groups = uniqueGroups.sort();
			directoryFactored.users = directoryEntity;

			return directoryFactored;
		},
	});
};

export const useQueryDirectorySelect = (id) => {
	return useQuery({
		queryKey: ["getDirectorySelect"],
		queryFn: async () => {
			try {
				return await instance.get(`/contacts/${id}`)
			} catch (error) {
				if (error.response && error.response.status === 401) {
					await refreshToken();
					return instance.get(`/contacts/${id}`)
				}
				throw error;
			}
		},
		select: (data) => {
			return data.data.data;
		},
	});
};

export const useQueryContactGroup = (selectGroup) => {
	return useQuery({
		queryKey: ["getContactGroup", selectGroup],
		queryFn: async () => {
			try {
				return await instance.get(
					`/contacts?group=group&search=${selectGroup}`
				)
			} catch (error) {
				if (error.response && error.response.status === 401) {
					await refreshToken();
					return instance.get(
						`/contacts?group=group&search=${selectGroup}`
					)
				}
				throw error
			}
		},
		select: (data) => {
			const contactGroupEntity = data.data.data;
			let searchContactGroup;
			searchContactGroup = contactGroupEntity.filter((group) => {
				return selectGroup && group.toLowerCase().includes(selectGroup.toLowerCase());
			})
			return searchContactGroup;
		},
	});
};