// External librairies
import React, {useEffect, useContext, useState} from "react";
import {useOutletContext, useParams, useNavigate} from "react-router-dom";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {toast} from "react-toastify";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


// Context
import {AuthContext} from "../../contexts";

// Query
import {
    useQueryCategories,
    useMutationIssue,
    useMutationDeleteImage,
    useMutationUploadImages,
    useQueryAssignees
} from "../../queries";

// Components
import {
    TextInput,
    TextAreaInput,
    SelectInput,
    DateInput,
    RadioInput,
    ImageInput,
    SimpleMap,
    FormButton,
    CancelButton,
} from "../../components";

// Config
import {STATUS, PRIVACY} from "../../config/config";
import {formSchemaIssueEdit} from "../../config/yupconfig";

// Misc
import {Transition} from "../../utils/react-router";

const ReportsPageEdit = () => {
    const reportData = useOutletContext();
    const {id} = useParams();
    const {user} = useContext(AuthContext);
    const [currentCategory, setCurrentCategory] = useState(reportData.category)
    const {data: dataCategories} = useQueryCategories();
    const {mutate: updateReport} = useMutationIssue(id);
    const {mutate: uploadImages} = useMutationUploadImages();
    const {mutate: deleteImages} = useMutationDeleteImage();
    const {isLoading, data: assignees, error} = useQueryAssignees(currentCategory)

    const navigate = useNavigate();

    const {
        control,
        register,
        formState: {errors, isValid},
        handleSubmit,
        reset,
    } = useForm({
        resolver: yupResolver(formSchemaIssueEdit),
    });

    useEffect(() => {
        let defaultValues = {};
        defaultValues.privacyChoice = reportData?.isPublic
            ? "public"
            : "private";
        defaultValues.selectStatus = reportData?.status;
        defaultValues.selectCategory = reportData?.category;
        defaultValues.dateStart = reportData?.date;
        defaultValues.dateEnd = reportData?.end;
        defaultValues.title = reportData?.title;
        defaultValues.description = reportData?.description;
        defaultValues.location = {
            lat: parseFloat(reportData?.lat),
            lng: parseFloat(reportData?.lon),
        };
        defaultValues.image = reportData?.image;
        defaultValues.images = reportData?.ReportImages.map((image) => image.filename);
        reset({...defaultValues});
    }, []);

    const onSubmit = async (data, e) => {
        e.preventDefault();
        const imagesToDelete = reportData?.ReportImages.map((img) => img.filename).filter(img => !data.images.includes(img));
        if (imagesToDelete.length > 0) {
            deleteImages(imagesToDelete, {
                onError: () => toast.error("Les images n'ont pas pu être supprimées")
            })
        }

        let newReportData = {
            date: data.dateStart,
            title: data.title,
            description: data.description,
            status: data.selectStatus,
            end: data.dateEnd,
            lat: data.location.center.lat,
            lon: data.location.center.lng,
            isPublic: data.privacyChoice === "public",
            creator: user.id,
            category: data.selectCategory,
            assignee: data.assignee,
        };

        if (data.images.length === 0) {
            newReportData.image = "no-image.jpg"
            updateReport(newReportData, {
                onSuccess: () => {
                    navigate("/reports");
                    toast.success("Le signalement a été mis à jour !")
                },
                onError: () => toast.error("Une erreur est survenue"),
            })
        } else {
            const imagesToUpload = data.images.filter((img) => typeof img !== "string");
            await uploadImages(imagesToUpload, {
                onSuccess: (res) => {
                    newReportData.images = res.data.data;
                    updateReport(newReportData, {
                        onSuccess: () => {
                            navigate("/reports");
                            toast.success("Le signalement a été mis à jour !")
                        },
                        onError: () => toast.error("Une erreur est survenue"),
                    })
                },
                onError: (err) => console.log(err)
            })
        }
    };

    return (
        <Transition>
            <div className="relative mt-16 mb-10">
                <form
                    className="flex flex-col"
                    onSubmit={(e) => {
                        handleSubmit(onSubmit)(e);
                    }}
                >
                    <div className="flex flex-col gap-y-14">
                        <div
                            className="border-b border-bgNeutral-elementborderlight flex-col-reverse justify-center items-center md:flex-row">
                            <div className="flex flex-col-reverse md:flex-row gap-y-4 gap-x-4">
                                {PRIVACY &&
                                    PRIVACY.map((privacy) => {
                                        return (
                                            <div
                                                key={privacy.id}
                                                className="w-[12rem]"
                                            >
                                                <RadioInput
                                                    id={privacy.value}
                                                    label={privacy.label}
                                                    register={{
                                                        ...register(
                                                            "privacyChoice"
                                                        ),
                                                    }}
                                                    value={privacy.value}
                                                    bg={privacy.bg}
                                                    bgFade={privacy.bgFade}
                                                    border={privacy.border}
                                                    color={privacy.color}
                                                />
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                        <div
                            className="border-b border-bgNeutral-elementborderlight grid grid-cols-1 gap-y-12 lg:flex lg:flex-row lg:justify-between lg:gap-x-4 lg:gap-y-0">
                            <div>
                                <SelectInput
                                    label="Responsable"
                                    id="assignee"
                                    noValue="Responsable"
                                    items={assignees}
                                    register={{
                                        ...register("assignee", {
                                            required: true,
                                        }),
                                    }} placeholder="Responsable"
                                    error={
                                        errors?.assignee
                                    }
                                />
                            </div>
                        </div>
                        <div
                            className="border-b border-bgNeutral-elementborderlight grid grid-cols-1 gap-y-12 lg:flex lg:flex-row lg:justify-between lg:gap-x-4 lg:gap-y-0">
                            <div className="flex flex-col 2xl:flex-row gap-y-4 gap-x-4 2xl:gap-y-0">
                                <div>
                                    <SelectInput
                                        label="Status"
                                        id="select-status"
                                        items={STATUS}
                                        register={{
                                            ...register("selectStatus", {
                                                required: true,
                                            }),
                                        }}
                                        error={errors?.selectStatus}
                                    />
                                </div>
                                <div>
                                    <SelectInput
                                        label="Catégorie"
                                        id="select-category"
                                        items={dataCategories}
                                        register={{
                                            ...register("selectCategory", {
                                                required: true,
                                                onChange: (e) => setCurrentCategory(e.target.value)
                                            }),
                                        }}
                                        error={errors?.selectCategory}
                                    />

                                    </div>
                            </div>
                            <div className="flex flex-col 2xl:flex-row gap-y-4 gap-x-4 2xl:gap-y-0">
                                <div>
                                    <DateInput
                                        id="date"
                                        label="Date du début"
                                        register={{
                                            ...register("dateStart", {
                                                required: true,
                                            }),
                                        }}
                                        error={errors?.dateStart}
                                    />
                                </div>
                                <div>
                                    <DateInput
                                        id="end"
                                        label="Date de fin"
                                        register={{
                                            ...register("dateEnd", {
                                                required: true,
                                            }),
                                        }}
                                        error={errors?.dateEnd}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="border-b border-bgNeutral-elementborderlight flex flex-col gap-y-6">
                            <div>
                                <TextInput
                                    id="title"
                                    label="Titre"
                                    register={{
                                        ...register("title", {
                                            required: true,
                                        }),
                                    }}
                                    error={errors?.title}
                                />
                            </div>

                            <div>
                                <TextAreaInput
                                    id="description"
                                    label="Description"
                                    register={{
                                        ...register("description", {
                                            required: true,
                                        }),
                                    }}
                                    error={errors?.description}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <Controller
                                control={control}
                                name="location"
                                render={({field: {onChange, value}}) =>
                                    value && (
                                        <div className="relative">
                                            <SimpleMap
                                                location={value}
                                                onChange={onChange}
                                            />
                                        </div>
                                    )
                                }
                            />
                        </div>
                        <div className="w-full h-[20em] flex flex-col gap-y-4">
                            <div className="flex flex-col">
                                <label>Images:</label>
                                <Controller
                                    control={control}
                                    name="images"
                                    render={({field: {onChange, value}}) => (
                                        <ImageInput
                                            images={value}
                                            setImages={(images) => {
                                                onChange(images)
                                            }}
                                            error={false}
                                            errorText="Erreur de chargement d'image"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col justify-end items-center gap-x-2 gap-y-4 xl:flex-row">
                            <CancelButton caption="Annuler" backTo="reports"/>
                            <FormButton
                                caption="Enregistrer"
                                isValid={isValid}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </Transition>
    );
};

export default ReportsPageEdit;
