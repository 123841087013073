// External librairies
import { useNavigate } from 'react-router-dom';
import { useForm, useFieldArray, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

// Queries
import { useMutationSurveyAdd } from '../../queries';

// Components
import {
	NavigationHeader,
	FormButton,
	SurveyTextFieldsArray,
	SurveyTextAreaFieldsArray,
	SurveySelectFieldsArray,
	SurveyCheckboxFieldsArray,
	SurveyMenu,
	TextInput, TextAreaInput
} from '../../components';

// Config
import { formSchemaAddSurvey } from '../../config/yupconfig';

// Icons
import { ArrowForward, ArrowUpward } from '@mui/icons-material';

// Misc
import { Transition } from '../../utils/';
import { AnimationDown } from '../../animations';

const SurveyPageCreate = () => {
	const { mutate: mutateSurveyAdd } = useMutationSurveyAdd();

	const navigate = useNavigate();

	// Methods extract for useFormContext provider
	const methods = useForm({
		mode: 'onChange',
		resolver: yupResolver(formSchemaAddSurvey)
	});

	const {
		control,
		register,
		formState: { errors, isValid },
		handleSubmit,
		watch
	} = methods;

	// Global dynamic fields array
	const { fields, append, remove, move } = useFieldArray({
		control,
		name: 'fieldArray'
	});

	// Watch for dynamic changes
	const fieldArrayWatch = watch('fieldArray');

	const fieldArrayControlledFields = fields.map((field, index) => {
		return {
			...field,
			...fieldArrayWatch[index]
		};
	});

	const onSubmit = async (data, e) => {
		e.preventDefault();
		mutateSurveyAdd(data, {
			onSuccess: () => {
				toast.success("Le questionnaire a été ajouté!");
				navigate("/survey")
			},
			onError: () => toast.error("Une erreur est survenue"),
		});
	};

	return (
		<Transition>
			<NavigationHeader
				title="Questionnaires"
				subTitle="Créer un questionnaire"
				returnUrl="/survey"
			/>
			<div className="flex flex-col-reverse gap-y-12 xl:gap-y-0 xl:flex-row gap-x-8">
				<div className="w-full 2xl:w-[55%] flex flex-col gap-y-5 relative">
					<FormProvider {...methods}>
						<form
							className="flex flex-col gap-y-12"
							onSubmit={(e) => {
								handleSubmit(onSubmit)(e);
							}}
						>
							<div className="flex flex-col gap-y-3">
								<TextInput
									id="title"
									label="Nom du questionnaire"
									placeholder="Nom du questionnaire"
									register={{
										...register('title', {
											required: true
										})
									}}
									error={errors?.title}
								/>
								<TextAreaInput
									id="description"
									label="Décrivez le but du questionnaire..."
									register={{
										...register("description", {
											required: true,
										}),
									}}
									error={errors?.description}
								/>
							</div>
							<div className="flex flex-col gap-y-3">
								<ul className="border-[1px] border-bgNeutral-dark/[.1] rounded-[12px] flex flex-col gap-y-8 p-12 bg-bgNeutral-light">
									{!fieldArrayControlledFields.length && (
										<div className="w-full flex justify-center items-center gap-x-3">
											<span className="text-lg text-textNeutral-dark/[.25] font-bold uppercase tracking-wide">
												Ajouter un champ pour créer
												votre questionnaire
											</span>
											<i className="hidden xl:block text-lg text-textNeutral-dark/[.25] font-bold uppercase tracking-wide">
												<ArrowForward
													sx={{ fontSize: 34 }}
												/>
											</i>
											<i className="block xl:hidden text-lg text-textNeutral-dark/[.25] font-bold uppercase tracking-wide">
												<ArrowUpward
													sx={{ fontSize: 34 }}
												/>
											</i>
										</div>
									)}
									{fieldArrayControlledFields.map(
										(field, index) => {
											return (
												<li
													key={field.id}
													className="pt-10"
												>
													<div className="w-full flex items-end gap-x-5">
														{field.type ===
															'text' && (
															<SurveyTextFieldsArray
																name={`fieldArray.${index}.question`}
																index={index}
																fields={fields}
																currentField={
																	field
																}
																move={move}
																remove={remove}
															/>
														)}
														{field.type ===
															'textarea' && (
															<SurveyTextAreaFieldsArray
																name={`fieldArray.${index}.question`}
																index={index}
																fields={fields}
																currentField={
																	field
																}
																move={move}
																remove={remove}
															/>
														)}
														{field.type ===
															'select' && (
															<SurveySelectFieldsArray
																name={`fieldArray.${index}.question`}
																index={index}
																fields={fields}
																currentField={
																	field
																}
																move={move}
																remove={remove}
															/>
														)}
														{field.type ===
															'checkbox' && (
															<SurveyCheckboxFieldsArray
																name={`fieldArray.${index}.question`}
																index={index}
																fields={fields}
																currentField={
																	field
																}
																move={move}
																remove={remove}
															/>
														)}
													</div>
												</li>
											);
										}
									)}
								</ul>
							</div>
							<div className="mt-5 w-full flex flex-col gap-y-3 md:flex-row md:justify-end md:items-center md:gap-x-4 md:gap-y-0">
								{fields.length > 0 && (
									<div className="w-full flex items-center justify-end">
										<AnimationDown>
											<FormButton
												caption="Créer le questionnaire"
												isValid={isValid}
											/>
										</AnimationDown>
									</div>
								)}
							</div>
						</form>
					</FormProvider>
				</div>
				<div className="sticky top-5">
					<SurveyMenu append={append} />
				</div>
			</div>
		</Transition>
	);
};

export default SurveyPageCreate;
