// Components
import { ErrorMessage } from '../../../components';
import FormLabel from '../../Form/FormLabel';

// Icons
import { FilterList } from '@mui/icons-material';

const SelectFieldInput = ({
	type = 'text',
	label,
	id,
	field,
	error,
	...rest
}) => {
	return (
		<div className="w-full flex items-end gap-x-2 relative">
			<div className="w-[2.15rem] h-[2.15rem] flex justify-center items-center absolute top-[2rem] left-0">
				<i className="flex justify-center items-center text-primary">
					<FilterList sx={{ fontSize: 24 }} />
				</i>
			</div>
			<div className="ml-10 w-full flex flex-col justify-center gap-y-3 relative">
				<div className="flex flex-col gap-y-1">
					<FormLabel htmlFor={id}>{label}</FormLabel>
					<input
						{...field}
						type={type}
						placeholder={label}
						className={`border ${
							error
								? 'border-red'
								: 'border-bgNeutral-elementborderlight'
						} dark:border-bgNeutral-dark bg-bgNeutral-elementlight dark:bg-bgNeutral-elementdark shadow-custom rounded-lg w-full px-4 py-3 text-xs text-textNeutral-dark dark:text-textNeutral-light transition-all outline-none placeholder:text-[11px] focus:border-primary focus:ring-2 focus:ring-primary cursor-text`}
						{...rest}
					/>
				</div>
				<div className="absolute top-[4.5rem] left-0">
					{error && (
						<ErrorMessage
							type={
								error.type == 'required' ? 'error' : 'pattern'
							}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default SelectFieldInput;
