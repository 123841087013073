import { ErrorMessage } from "../Errors";
import FormLabel from "../Form/FormLabel";

const DateInput = ({ id, label, register, error, ...rest }) => {
	return (
		<div className="w-full md:w-[18rem] xl:w-[20.5rem] flex flex-col justify-start">
			<FormLabel htmlFor={id}>{label}</FormLabel>
			<input
				type="date"
				id={id}
				name={id}
				className="border border-bgNeutral-elementborderlight dark:border-bgNeutral-dark bg-bgNeutral-elementlight dark:bg-bgNeutral-elementdark shadow-custom rounded-lg w-full px-4 py-3 text-xs text-textNeutral-dark dark:text-textNeutral-light transition-all outline-none focus:border-primary focus:ring-2 focus:ring-primary cursor-pointer"
				{...register}
				{...rest}
			/>
			{error && (
				<ErrorMessage
					type={error.type == "required" ? "error" : "pattern"}
					message={error.message}
				/>
			)}
		</div>
	);
};

export default DateInput;
