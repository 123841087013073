// External librairies
import classNames from "classnames";

// Config
import { formatRole } from "../../config/formats";

// Misc
import { Avatar } from "../Avatar";

const ProfileCard = ({ isOpen, user }) => {
	return (
		<div>
			<div className="flex">
				<div className="flex justify-center items-center">
					<Avatar
						userAvatar={user?.avatar}
						className={classNames(
							"object-cover h-full w-[2.8em] max-w-[2.8em] rounded-md transition-all duration-200",
							!isOpen && "h-auto w-[2.2em] !max-w-[2.2em]"
						)}
					/>
				</div>
				<div
					className={classNames(
						"pl-2 flex flex-col gap-y-1 justify-around origin-right transition-opacity duration-700",
						!isOpen && "opacity-0 invisible 2xl:block"
					)}
				>
					<p className="px-3 py-1 rounded-full text-[10px] border border-roles-admin text-roles-admin bg-roles-admin/[.12]">
						{formatRole(user?.role)}
					</p>
					<p className="pl-2 text-slate-800 text-[12px] font-medium dark:text-textNeutral-light">
						{user?.username}
					</p>
				</div>
			</div>
		</div>
	);
};

export default ProfileCard;
