import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthProvider";
import { useForm } from "react-hook-form";
import { TextInput, TextAreaInput, DateInput } from "../Input";
import { BASE_URL } from "../../config/config";
import { FormButton } from "../../components/Button";
import { ErrorMessage } from "../Errors";
import { toast } from "react-toastify";

const EventAdd = () => {
	const navigate = useNavigate();
	const { user } = useContext(AuthContext);

	const [state, setState] = useState({
		post: null,
		loading: null,
		error: null,
	});

	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm({
		mode: "onChange",
	});

	useEffect(() => {
		let defaultValues = {};
		reset({ ...defaultValues });
	}, []);

	const onSubmit = (data, e) => {
		e.preventDefault();

		const newUserData = {
			date: data.dateStart,
			title: data.title,
			description: data.description,
			end: data.dateEnd,
		};

		const config = {
			method: "POST",
			headers: { authorization: user.accessToken },
			data: newUserData,
		};

		axios(`${BASE_URL}/events`, config)
			.then((res) => {
				setState({ ...state, post: res.data });
				setState({ ...state, loading: false });
			})
			.catch(() => {
				setState({ ...state, loading: false });
			});

		if (!state.loading && !state.error) {
			navigate("/events/table");
			toast.success("L'évènement a été ajouté");
		}
	};
	return (
		<div className="w-3/5 flex flex-col gap-y-5">
			<div>
				<h2 className="dark:text-textNeutral-light">Ajouter un évènement</h2>
			</div>
			<form
				className="flex flex-col"
				onSubmit={(e) => {
					handleSubmit(onSubmit)(e);
				}}
			>
				<div className="flex flex-col gap-y-10">
					<div className="grid grid-cols-1 gap-y-8">
						<div className="flex flex-col md:flex-row gap-y-8 md:gap-y-0 gap-x-5">
							<div className="w-full md:w-1/2 flex flex-col">
								<DateInput
									id="dateStart"
									label="Date de début"
									register={{
										...register("dateStart", {
											required: true,
										}),
									}}
								/>
								{errors.dateStart ? (
									<>
										{errors.dateStart.type ===
											"required" && (
											<ErrorMessage message="Veuillez saisir une date de début" />
										)}
									</>
								) : null}
							</div>
							<div className="w-full md:w-1/2 flex flex-col">
								<DateInput
									id="dateEnd"
									label="Date de fin"
									register={{
										...register("dateEnd", {
											required: true,
										}),
									}}
								/>
								{errors.dateEnd ? (
									<>
										{errors.dateEnd.type === "required" && (
											<ErrorMessage message="Veuillez saisir une date de fin" />
										)}
									</>
								) : null}
							</div>
						</div>
						<div className="flex flex-col md:flex-row gap-y-8 md:gap-y-0 gap-x-5">
							<div className="w-full flex flex-col">
								<TextInput
									id="title"
									label="Titre"
									register={{
										...register("title", {
											required: true,
											pattern: /^[A-zÀ-ú0-9\s]*$/,
										}),
									}}
								/>
								{errors.title ? (
									<>
										{errors.title.type === "required" && (
											<ErrorMessage message="Veuillez saisir un titre" />
										)}
										{errors.title.type === "pattern" && (
											<ErrorMessage
												type="pattern"
												message="Ne peut pas contenir de charactères spéciaux"
											/>
										)}
									</>
								) : null}
							</div>
						</div>
						<div className="flex flex-col md:flex-row gap-y-8 md:gap-y-0 gap-x-5">
							<div className="w-full flex flex-col">
								<TextAreaInput
									id="description"
									label="Description"
									register={{
										...register("description", {
											required: true,
											pattern: /^[A-zÀ-ú0-9\s]*$/,
										}),
									}}
								/>
								{errors.description ? (
									<>
										{errors.description.type ===
											"required" && (
											<ErrorMessage message="Veuillez saisir une description" />
										)}
									</>
								) : null}
							</div>
						</div>
						{/* <div className="flex flex-col md:flex-row gap-y-8 md:gap-y-0 gap-x-5">
							<div className="w-full flex flex-col">
								<ImageInput
									register={{
										...register("image", {
											required: true,
										}),
									}}
								/>
								{errors.birthimageDate ? (
									<>
										{errors.image.type ===
											"required" && (
											<ErrorMessage message="Veuillez ajouter une image" />
										)}
									</>
								) : null}
							</div>
						</div> */}
					</div>
					<div className="w-full flex justify-end">
						<FormButton caption="Ajouter l'évènement" icon="event" />
					</div>
				</div>
			</form>
		</div>
	);
};

export default EventAdd;
