import React, { useEffect, useState, useContext } from "react";
import { Chart as ChartJS, Tooltip, Legend, ArcElement } from "chart.js";
import { request } from "../../utils/axios";
import { AuthContext } from "../../contexts/AuthProvider";
import { formatStatus } from "../../config/formats";

ChartJS.register(Legend, ArcElement, Tooltip);

export const options = {
	responsive: true,
};

const SummaryRecord = ({ color, title, record }) => {
	return (
		<div className="flex gap-x-4">
			<div>
				{/* <DoughnutChart data={issuesData} options={options} /> */}
			</div>
			<div className="flex flex-col">
				<p className="text-textNeutral-dark dark:text-textNeutral-light text-xs">
					{title}
				</p>
				<span className="font-semibold text-textNeutral-dark dark:text-textNeutral-light text-lg">
					{record}
				</span>
			</div>
		</div>
	);
};

const Summary = () => {
	const { user } = useContext(AuthContext);
	const [openedReports, setOpenedReports] = useState([]);

	useEffect(() => {
		request(
			"/reports?group=status&aggregate=count",
			"get",
			null,
			user.accessToken
		).then((res) => {
			let toDisplay = [];
			if (res.data !== undefined) {
				res.data.forEach((data) => {
					if (data.rep_status !== "archived") {
						toDisplay.push({
							title: formatStatus(data.rep_status),
							value: data.count,
						});
					}
				});
			}
			setOpenedReports(toDisplay);
		});
	}, [setOpenedReports]);

	return (
		<div className="ml-auto w-full h-full xl:w-4/5 flex flex-col rounded-lg border border-textNeutral-light dark:border-textNeutral-dark shadow-custom pt-5 px-8 pb-12 bg-bgNeutral-light dark:bg-bgNeutral-elementdark">
			<h3 className="font-semibold text-lg text-textNeutral-dark dark:text-textNeutral-light">
				Résumé des signalements
			</h3>
			<div>
				<ul className="mt-12 flex flex-row justify-between xl:flex-col xl:justify-start gap-y-12">
					{openedReports.map((report, index) => {
						return (
							<li key={index}>
								<SummaryRecord
									title={report.title}
									record={`${
										report.value
									}/${openedReports.reduce(
										(total, item) => total + item.value,
										0
									)}`}
								/>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};

export default Summary;
