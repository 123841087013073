// External librairies
import {useQuery} from "@tanstack/react-query";

// Config
import instance from "../../config/axiosconfig";
import {refreshToken} from "../../utils";
import {formatRole} from "../../config/formats";

export const useQueryUser = (id) => {
	return useQuery({
		queryKey: ["getUser"],
		queryFn: async () => {
			try{
				return await instance.get(`/users/${id}`)
			} catch (error) {
				if (error.response && error.response.status === 401) {
					await refreshToken();
					return instance.get(`/users/${id}`)
				}
				throw error
			}
		},
		select: (data) => {
			return data.data.data;
		},
	});
}

export const useQueryUsers = (params) => {
	return useQuery({
		queryKey: ["getUsers"],
		queryFn: async () => {
			try{
				return await instance.get(`/users?${params}`)
			} catch (error) {
				if (error.response && error.response.status === 401) {
					await refreshToken();
					return instance.get(`/users?${params}`)
				}
				throw error
			}
		},
		select: (data) => {
			return data.data.data;
		},
	})
}

export const useQueryAssignees = (category) => {
	return useQuery({
		queryKey: ["getAssignees", category],
		queryFn: async () => {
			try {
				return await instance.get(
					`/users?role=admin,associate,manager&plugin=reports&category=${category}`
				);
			} catch (error) {
				if (error.response && error.response.status === 401) {
					await refreshToken();
					return await instance.get(
						`/users?role=admin,associate,manager&plugin=reports&category=${category}`
					);
				}
				throw error
			}
		},
		select: (data) => {
			return data.data.data.map((e) => ({
				value: e.id,
				label: `${e.first_name} ${e.last_name} (${formatRole(e.role)})`,
			}));
		},
	});
};

export const useQueryUsersTable = (currentPage, roleFilter, sort) => {
	return useQuery({
		queryKey: ["getUsersTable", currentPage, roleFilter, sort],
		queryFn: async () =>{
			try{
				return await instance.get(
					`/users?page=${currentPage}&role=${roleFilter}&sort=${sort.accessor}&order=${sort.direction}`
				)
			} catch (error) {
				if (error.response && error.response.status === 401) {
					await refreshToken();
					return instance.get(
						`/users?page=${currentPage}&role=${roleFilter}&sort=${sort.accessor}&order=${sort.direction}`
					)
				}
				throw error
			}
		},
		select: (data) => {
			if (data.data.count === 0) {
				alert("rien");
			}
			const usersTableEntity = data.data;
			return usersTableEntity || [];
		},
	});
};