import DOMPurify from "dompurify";

export const randomPassword = (length) => {
	let pwd = "";
	const str =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$%/()=?+-_<>";
	for (let i = 1; i <= length; i++) {
		let char = Math.floor(Math.random() * str.length + 1);
		pwd += str.charAt(char);
	}
	return pwd;
};

export const classNames = (...classes) => {
	return classes.filter(Boolean).join(" ");
};

export const scheduleDays = (value) => {
	
}

export const sanitizeHTML = (data) => ({
	__html: DOMPurify.sanitize(data)
});