import React from "react";
import { motion, AnimatePresence } from "framer-motion";

const Transition = ({ children }) => {
	return (
		<>
			<AnimatePresence>
				<motion.div
					className="enter"
					initial={{ opacity: 0, y:8 }}
					animate={{ opacity: 1, y:0 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 1.1, ease: [0.22, 1, 0.36, 1] }}
				>
					{children}
				</motion.div>
			</AnimatePresence>
		</>
	);
};

export default Transition;
