// External librairies
import {useMutation} from "@tanstack/react-query";

// Instance
import instance from "../../config/axiosconfig";
import {refreshToken} from "../../utils";

export const useMutationReadNotification = () => {
    return useMutation({
        mutationKey: ["updateReadNotification"],
        mutationFn: async ({id, read}) => {
            try {
                return await instance.put(`/notifications/${id}`, {read});
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    await refreshToken();
                    return await instance.put(`/notifications/${id}`, {read});
                }
                throw error
            }
        },
    });
};

export const useMutationDeleteNotification = () => {
    return useMutation({
        mutationKey: ["deleteNotification"],
        mutationFn: async (id) => {
            try {
                return await instance.delete(`/notifications/${id}`);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    await refreshToken();
                    return await instance.delete(`/notifications/${id}`);
                }
                throw error
            }
        },
    });
}