// External librairies
import React from "react";
import { useParams, Outlet } from "react-router-dom";

// Query
import {useQueryAgendaSelect} from "../../queries";

// Components
import { NavigationHeader } from "../../components";

// Misc
import { Loader } from "../../utils";

const OpenAgendaPageSelect = () => {
	const { id } = useParams();
	const { isLoading, data } = useQueryAgendaSelect(id);

	const navData = [
		{
			id: 0,
			route: "brief",
			label: "Aperçu",
			isExtra: false
		},
		// {
		// 	id: 1,
		// 	route: "edit",
		// 	label: "Editer",
		// 	isExtra: false
		// },
	];

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<>
					<div>
						<NavigationHeader
							title="Agenda"
							subTitle={data?.title}
							returnUrl="/agenda"
							navData={navData}
						/>
					</div>
					<div className="mt-3 block max-w-full">
						<Outlet context={data} />
					</div>
				</>
			)}
		</>
	);
};

export default OpenAgendaPageSelect;
