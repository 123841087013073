import React from "react";
import { useParams, Outlet } from "react-router-dom";

import { useQueryUser } from "../../queries";
import { NavigationHeader } from "../../components/Header";
import { Loader } from "../../utils/react-router";

const UsersPageSelect = () => {
	const { id } = useParams();
	const { isLoading, data } = useQueryUser(id);

	const navData = [
		{
			id: 0,
			route: "brief",
			label: "Aperçu",
			isExtra: false
		},
		{
			id: 1,
			route: "edit",
			label: "Editer",
			isExtra: false
		},
	];

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<>
					<div>
						<NavigationHeader
							title="Utilisateurs"
							subTitle={data?.last_name + " " + data?.first_name}
							navData={navData}
							returnUrl="/users"
						/>
					</div>
					<div className="mt-3 block max-w-full">
						<Outlet context={data} />
					</div>
				</>
			)}
		</>
	);
};

export default UsersPageSelect;
