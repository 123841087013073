// External librairies
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

// Instance
import instance from "../../config/axiosconfig";

// Hooks
import { useLocalStorage } from "../../hooks/useLocalStorage";

export const useMutationLogin = () => {
	const [user, setUser] = useLocalStorage("user", null);
	const navigate = useNavigate();
	return useMutation({
		mutationKey: ["mutateLogin"],
		mutationFn: (credentials) => {
			return instance.post("/auth/login", credentials);
		},
		onSuccess: (response) => {
			setUser(response.data.data);
			navigate("/");
			window.location.reload();
		},
	});
};

