// External librairies
import React from 'react';
import { useOutletContext } from 'react-router-dom';

// Icons
import { TextFields, TextFormat, FilterList, Check } from '@mui/icons-material';

// Misc
import { Transition } from '../../utils/';

const SurveyPageBrief = () => {
	const surveyData = useOutletContext();

	return (
		<Transition>
			<div className="w-full lg:w-[70%] flex flex-col gap-y-8">
				<div>
					<h2>{surveyData.title}</h2>
				</div>
				<ul className=" rounded-[12px] flex flex-col gap-y-8">
					{surveyData.fieldArray.map((f) => {
						return (
							<li key={f.id} className="rounded-[12px] border-[1.5px] border-primary/[.55] p-10 bg-bgNeutral-light">
								<div className="w-full flex items-end gap-x-5">
									{f.type === 'text' && (
										<div className="w-full flex flex-col gap-y-5">
											<div className="border-b-[1px] border-bgNeutral-dark/[.15] flex items-center gap-x-3 pb-3">
												<i className="opacity-50">
													<TextFields className="block w-4 h-4 text-textNeutral-dark" />
												</i>
												<h3 className="opacity-[.55]">
													Réponse courte
												</h3>
											</div>
											<h4>{f.question}</h4>
										</div>
									)}
									{f.type === 'textarea' && (
										<div className="w-full flex flex-col gap-y-5">
											<div className="border-b-[1px] border-bgNeutral-dark/[.15] flex items-center gap-x-3 pb-3">
												<i className="opacity-50">
													<TextFormat className="block w-4 h-4 text-textNeutral-dark" />
												</i>
												<h3 className="opacity-[.55]">
													Paragraphe
												</h3>
											</div>
											<h4>{f.question}</h4>
										</div>
									)}
									{f.type === 'select' && (
										<div className="w-full flex flex-col gap-y-5">
											<div className="border-b-[1px] border-bgNeutral-dark/[.15] flex items-center gap-x-3 pb-3">
												<i className="opacity-50">
													<FilterList className="block w-4 h-4 text-textNeutral-dark" />
												</i>
												<h3 className="opacity-[.55]">
													Liste sélective
												</h3>
											</div>
											<div>
												<h4>{f.question}</h4>
												<ul className="mt-5 flex items-center gap-x-5">
													{JSON.parse(f.options).map(
														(option, i) => (
															<li
																key={i}
																className="rounded-[6px] px-8 py-2 text-[13px] text-textNeutral-dark/[.75] bg-bgNeutral-dark/[.08]"
															>
																{option}
															</li>
														)
													)}
												</ul>
											</div>
										</div>
									)}
									{f.type === 'checkbox' && (
										<div className="w-full flex flex-col gap-y-5">
											<div className="border-b-[1px] border-bgNeutral-dark/[.15] flex items-center gap-x-3 pb-3">
												<i className="opacity-50">
													<Check className="block w-4 h-4 text-textNeutral-dark" />
												</i>
												<h3 className="opacity-[.55]">
													Choix multiple
												</h3>
											</div>
											<div>
												<h4>{f.question}</h4>
												<ul className="mt-5 flex items-center gap-x-8">
													{JSON.parse(f.options).map(
														(option, i) => (
															<li
																key={i}
																className="pl-4 relative before:content-[''] before:rounded-full before:w-[6px] before:h-[6px] before:absolute before:top-1/2 before:left-0 before:-translate-y-1/2 before:bg-bgNeutral-dark/[.25]"
															>
																{option}
															</li>
														)
													)}
												</ul>
											</div>
										</div>
									)}
								</div>
							</li>
						);
					})}
				</ul>
			</div>
		</Transition>
	);
};

export default SurveyPageBrief;
