// External librairies
import { Controller, useFormContext } from 'react-hook-form';

// Components
import {
	SelectFieldInputTitle,
	SurveyMoveUpButton,
	SurveyMoveDownButton,
	SurveyRemoveButton,
	NestedArraySelectInput
} from '../../components';

// Animations
import { AnimationSide } from '../../animations';

const SurveySelectFieldsArray = ({
	fields,
	currentField,
	name,
	index,
	move,
	remove
}) => {
	const { control } = useFormContext();
	return (
		<AnimationSide>
			<div className="rounded-lg border-[.6px] border-bgNeutral-dark w-full flex flex-col gap-y-5 px-8 p-4 py-8">
				<div className="flex items-end gap-x-3 relative">
					<Controller
						control={control}
						name={name}
						rules={{ required: true }}
						render={({ field, fieldState: { error } }) => (
							<SelectFieldInputTitle
								field={field}
								label="Intitulé"
								error={error}
							/>
						)}
					/>
					<div className="w-full flex items-center justify-between absolute -top-[3.3rem] left-0">
						<div className="-mt-10">
							<span className="font-semibold tracking-wide text-textNeutral-dark/[.45] uppercase">
								Liste sélective
							</span>
						</div>

						<div className="flex items-center gap-x-3">
							{fields.length >= 2 && (
								<>
									{fields[0].id != currentField.id && (
										<SurveyMoveUpButton
											onClick={() => {
												move(index, index - 1);
											}}
										/>
									)}
									{fields[fields.length - 1].id !=
										currentField.id && (
										<SurveyMoveDownButton
											onClick={() => {
												move(index, index + 1);
											}}
										/>
									)}
								</>
							)}
							<SurveyRemoveButton
								onClick={() => {
									remove(index);
								}}
							/>
						</div>
					</div>
				</div>
				<NestedArraySelectInput
					nestIndex={index}
				/>
			</div>
		</AnimationSide>
	);
};

export default SurveySelectFieldsArray;
