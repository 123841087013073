import instance from "../../config/axiosconfig";
import {refreshToken} from "../../utils";
import {useQuery} from "@tanstack/react-query";

export const useQueryPlugins = () => {
    return useQuery({
        queryKey: ["getPlugins"],
        queryFn: async () => {
            try {
                return await instance.get('/plugins')
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    await refreshToken();
                    return await instance.get('/plugins')
                }
                throw error
            }
        },
        select: (res) => {
            const plugins = [];
            res.data.data.forEach(plugin => {
                switch (plugin.slug) {
                    case "reports":
                        process.env.REACT_APP_PLUGIN_REPORTS && plugins.push({label: plugin.fr, value: plugin.slug})
                        break;
                    case "agenda":
                        process.env.REACT_APP_PLUGIN_AGENDA && plugins.push({ label: plugin.fr, value: plugin.slug })
                        break;
                    case "job":
                        process.env.REACT_APP_PLUGIN_JOB && plugins.push({ label: plugin.fr, value: plugin.slug })
                        break;
                    case "news":
                        process.env.REACT_APP_PLUGIN_NEWS && plugins.push({ label: plugin.fr, value: plugin.slug })
                        break;
                    case "directory":
                        process.env.REACT_APP_PLUGIN_DIRECTORY && plugins.push({ label: plugin.fr, value: plugin.slug })
                        break;
                    case "survey":
                        process.env.REACT_APP_PLUGIN_SURVEY && plugins.push({ label: plugin.fr, value: plugin.slug })
                        break;
                    case "forum":
                        process.env.REACT_APP_PLUGIN_FORUM && plugins.push({ label: plugin.fr, value: plugin.slug })
                        break;
                    default:
                        plugins.push({ label: plugin.fr, value: plugin.slug })
                        break;
                }
            })
            return plugins
        }
    })
}