import { useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import classNames from "classnames";
import {
	ArrowDropDown,
	ArrowDropUp,
	ArrowLeft,
	ArrowRight,
} from "@mui/icons-material";

const UseTableWithColumns = ({
	columns,
	data,
	setSort,
	currentPage,
	setCurrentPage,
	count,
}) => {
	const allPage = Math.ceil(count / 10);
	const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
		useTable(
			{
				columns,
				data,
				manualSortBy: true,
				initialState: { pageIndex: 0 },
			},
			useSortBy,
			usePagination
		);
	
	if (typeof currentPage === 'undefined') {
		setCurrentPage(1)
	}

	const columnSetSort = (column) => {
		switch (column.sortDirection) {
			// case "none":
			// 	setSort({ direction: "asc", accessor: column.id });
			// 	break;
			case "asc":
				setSort({ direction: "desc", accessor: column.id });
				break;
			case "desc":
				setSort({ direction: "asc", accessor: column.id });
				break;
			default:
				setSort({ direction: "asc", accessor: column.id });
		}
	};

	const handlePreviousPage = () => {
		if (currentPage <= 1) {
			return currentPage;
		}

		setCurrentPage(currentPage - 1);
	};

	const handleNextPage = () => {
		if (currentPage >= allPage) {
			return currentPage;
		}
		setCurrentPage((prevPage) => {
			return prevPage + 1;
		});
	};

	return (
		<div className="rounded-lg">
			<table
				{...getTableProps()}
				className="w-full md:shadow-custom bg-white dark:bg-bgNeutral-elementdark rounded-lg"
			>
				<thead className="rounded-lg">
					{headerGroups.map((headerGroup) => (
						<tr
							{...headerGroup.getHeaderGroupProps()}
							className="h-16 rounded-t-lg font-semibold"
						>
							{headerGroup.headers.map((column) => (
								<th
									className="whitespace-nowrap first:pl-4 first:rounded-tl-lg last:rounded-tr-lg text-[10px] uppercase text-textNeutral-dark/[.65] border-b border-bgNeutral-dark/[.2]"
									{...column.getHeaderProps(
										column.getSortByToggleProps()
									)}
									onClick={() => columnSetSort(column)}
								>
									<div className="flex items-center">
										<span className="font-semibold text-textNeutral-dark/[.60]">
											{column.render("Header")}
										</span>
										<span>
											{column.sortDirection === "asc" ? (
												<ArrowDropUp className="opacity-[.6]" />
											) : column.sortDirection ===
											  "desc" ? (
												<ArrowDropDown className="opacity-[.6]" />
											) : null}
										</span>
									</div>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row, i) => {
						prepareRow(row);
						return (
							<tr
								key={i}
								{...row.getRowProps()}
								className="border-b border-bgNeutral-headerBorder relative text-textNeutral-dark"
							>
								{row.cells.map((cell) => {
									return (
										<td
											className="max-w-[15em] p-3 pl-0 text-[11px] text-textNeutral-dark overflow-hidden first:w-8 last:pr-0"
											{...cell.getCellProps()}
										>
											{cell.render("Cell")}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
			<div className="pagination mt-4 flex justify-end items-center gap-x-5">
				<span className="text-xs text-textNeutral-dark dark:text-textNeutral-light">
					<strong>
						Page {currentPage} sur {allPage} - {count} entrée(s)
					</strong>
				</span>
				<div className="flex gap-x-1">
					<button
						onClick={() => handlePreviousPage()}
						className={classNames(
							"rounded-md flex justify-center items-center p-1 px-4 text-textNeutral-light",
							currentPage <= 1
								? "bg-bgNeutral-elementdark/25 dark:bg-bgNeutral-elementdark"
								: "bg-primary"
						)}
					>
						<ArrowLeft sx={{ fontSize: 16 }} />
					</button>
					<button
						onClick={() => handleNextPage()}
						className={classNames(
							"rounded-md flex justify-center items-center p-1 px-4 text-textNeutral-light",
							currentPage == allPage
								? "bg-bgNeutral-elementdark/25 pointer-events-none"
								: "bg-primary text-textNeutral-light"
						)}
					>
						<ArrowRight sx={{ fontSize: 16 }} />
					</button>
				</div>
			</div>
		</div>
	);
};

export default UseTableWithColumns;
