import { ErrorMessage } from "../Errors";

const RadioInput = ({
	id,
	label,
	value,
	register,
	error,
	bg,
	bgFade,
	border,
	color,
	...rest
}) => {
	const getColorByRole = (value) => {
		switch (value) {
			case "citizen":
				return "before:bg-roles-citizen/75";
			case "associate":
				return "before:bg-roles-associate/75";
			case "manager":
				return "before:bg-roles-manager/75";
			case "admin":
				return "before:bg-roles-admin/75";
			case "private":
				return "before:bg-privacy-private/75";
			case "public":
				return "before:bg-privacy-public/75";
			default:
				return null;
		}
	};

	const getColorByPrivacy = (value) => {
		switch (value) {
			case "private":
				return "border-private bg-private/75";
			case "public":
				return "border-public bg-public/75";
			default:
				return null;
		}
	};

	const roleColor = getColorByRole(value);
	const privacyColor = getColorByPrivacy(value);

	return (
		<div className="w-full flex flex-col justify-start">
			<label
				className={`border ${border} rounded-lg relative flex flex-row-reverse justify-end items-center gap-x-4 pl-3 ${bgFade} hover:opacity-[.78] transition-all w-full h-full pr-6 py-3 text-sm text-left font-semibold tracking-tight opacity-75 cursor-pointer ${color} dark:text-textNeutral-light`}
			>
				{label}
				<input
					type="radio"
					id={id}
					name="role"
					value={value}
					className="radio-input opacity-0"
					{...register}
					{...rest}
				/>
				<span
					className={`checkmark ${roleColor} after:bg-white`}
				></span>
			</label>
			{error && (
				<ErrorMessage
					type={error.type == "required" ? "error" : "pattern"}
					message={error.message}
				/>
			)}
		</div>
	);
};

export default RadioInput;
