import React from 'react';

// Config
import { formatDateString } from '../../../config/formats';

// Icons
import { LibraryBooks, LibraryAddCheck } from "@mui/icons-material";

const CardSurvey = ({ title, createDate, updateDate }) => {
	return (
		<div className="border-2 rounded-[12px] w-full h-full flex flex-col items-between gap-y-12 px-10 py-8 bg-bgNeutral-light shadow-custom transition-all xl:duration-300 xl:ease-in-out xl:hover:shadow-2xl xl:hover:-translate-y-1 xl:hover:border-primary cursor-pointer">
			<div className="w-full flex justify-end items-center gap-x-5">
				<div className="flex flex-col gap-y-2">
					<div className="flex items-center gap-x-2 text-xs text-textNeutral-dark/[.45]">
						<p className="flex items-center gap-x-1 text-xs text-textNeutral-dark/[.45]">
							<i className="opacity-50">
								<LibraryBooks sx={{ fontSize: 16 }} />
							</i>
							Parution:
						</p>
						<span className="flex items-center text-xs text-textNeutral-dark/[.38]">
							{formatDateString(createDate)}
						</span>
					</div>
					<div className="flex items-center gap-x-2">
						<p className="flex items-center gap-x-1 text-xs text-primary/[.75] font-semibold">
							<i className="text-primary/[.75]">
								<LibraryAddCheck sx={{ fontSize: 16 }} />
							</i>
							Mise à jour:
						</p>
						<span className="flex items-center text-xs text-primary/[.75] font-semibold">
							{formatDateString(updateDate)}
						</span>
					</div>
				</div>
			</div>
			<div className="w-[75%]">
				<h2 className="text-[1.6rem] font-semibold">{title}</h2>
			</div>
		</div>
	);
};

export default CardSurvey;
