// External librairies
import {useMutation} from "@tanstack/react-query";

// Config
import instance from "../../config/axiosconfig";
import {refreshToken} from "../../utils";

export const useMutationIssue = (id) => {
    return useMutation({
        mutationKey: ["mutateIssue"],
        mutationFn: async (updateIssue) => {
            try {
                return await instance.put(`/reports/${id}`, updateIssue)
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    await refreshToken();
                    return await instance.put(`/reports/${id}`, updateIssue)
                }
                throw error
            }
        },
    });
};

export const useMutationIssueAdd = () => {
    return useMutation({
        mutationKey: ["mutateIssueAdd"],
        mutationFn: async (addIssue) => {
            try {
                return await instance.post('/reports', addIssue);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    await refreshToken();
                    return await instance.post('/reports', addIssue);
                }
                throw error
            }
        },
    });
};

export const useMutationCommentAdd = () => {
    return useMutation({
        mutationKey: ["mutateCommentAdd"],
        mutationFn: async (addComment) => {
            try {
                return await instance.post('/comments', addComment);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    await refreshToken();
                    return await instance.post('/comments', addComment);
                }
                throw error
            }
        },
    });
};