// External librairies
import React, { useState, useEffect, useCallback } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";

// Queries
import { useMutationAgenda } from "../../queries";

// Components
import {
	PreviewCardAgenda,
	TextInput,
	DateInput,
	EditorQuill,
	FormButton,
	CancelButton,
} from "../../components";

// Misc
import { Transition } from "../../utils/";

const AgendaPageEdit = () => {
	const eventData = useOutletContext();
	const { mutate: mutateEvent } = useMutationAgenda(eventData.id);

	const navigate = useNavigate();

	const [inputs, setInputs] = useState({
		dateStart: null,
		dateEnd: null,
		title: "",
		content: "",
	});

	const {
		control,
		register,
		formState: { errors, isValid },
		handleSubmit,
		reset,
		watch,
	} = useForm();

	const handleFormChange = useCallback(
		(value, name) => {
			setInputs({ ...inputs, [name]: value[name] });
		},
		[inputs]
	);

	// Set form default values
	useEffect(() => {
		let defaultValues = {};
		defaultValues.dateStart = eventData?.date;
		defaultValues.dateEnd = eventData?.end;
		defaultValues.title = eventData?.title;
		defaultValues.content = eventData?.description;
		inputs.title = eventData?.title;
		inputs.content = eventData?.description;
		inputs.dateStart = eventData?.date;
		inputs.dateEnd = eventData?.end;
		reset({ ...defaultValues });
	}, []);

	// Dynamic text render for preview
	useEffect(() => {
		const subscription = watch((value, { name }) => {
			handleFormChange(value, name);
		});
		return () => subscription.unsubscribe();
	}, [watch, handleFormChange]);

	const onSubmit = async (data, e) => {
		e.preventDefault();

		let newEventData = {
			title: data.title,
			date: data.dateStart,
			end: data.dateEnd,
			description: data.content,
		};

		mutateEvent(newEventData, {
			onSuccess: () => {
				toast.success("L'Evènement a été modifié");
				navigate("/agenda");
			},
			onError: () => toast.error("Une erreur est survenue"),
		});
	};

	return (
		<Transition>
			<div className="mt-10 mb-12 flex flex-col-reverse gap-y-32 xl:flex-row xl:gap-x-20 xl:gap-y-0">
				<div className="w-full xl:w-2/5 pt-[2.7em]">
					<PreviewCardAgenda
						dateStart={inputs.dateStart}
						dateEnd={inputs.dateEnd}
						title={inputs.title}
						content={inputs.content}
					/>
				</div>
				<div className="mt-10 w-full xl:w-3/5">
					<form
						className="flex flex-col gap-y-5"
						onSubmit={(e) => {
							handleSubmit(onSubmit)(e);
						}}
					>
						<div className="flex flex-col md:flex-row md:justify-between gap-y-8 md:gap-y-0 gap-x-5">
							<div className="flex flex-col">
								<DateInput
									id="dateStart"
									label="Date de début"
									register={{
										...register("dateStart", {
											required: true,
										}),
									}}
									error={errors?.dateStart}
								/>
							</div>
							<div className="flex flex-col">
								<DateInput
									id="dateEnd"
									label="Date de fin"
									register={{
										...register("dateEnd", {
											required: true,
										}),
									}}
									error={errors?.dateEnd}
								/>
							</div>
						</div>
						<div className="mt-5 flex flex-col gap-y-10">
							<TextInput
								id="title"
								label="Titre"
								register={{
									...register("title", {
										required: true,
									}),
								}}
								error={errors?.title}
							/>
							<Controller
								control={control}
								name="content"
								render={({ field: { onChange, value } }) => (
									<EditorQuill
										label="Description de la nouvelle"
										value={value}
										onChange={onChange}
									/>
								)}
							/>
						</div>
						<div className="mt-5 w-full flex flex-col gap-y-3 md:flex-row md:justify-end md:items-center md:gap-x-4 md:gap-y-0">
							<CancelButton caption="Annuler" backTo="news" />
							<FormButton
								caption="Enregistrer"
								isValid={isValid}
							/>
						</div>
					</form>
				</div>
			</div>
		</Transition>
	);
};

export default AgendaPageEdit;
