// External librairies
import React from "react";

// Config
import { formatDateString } from "../../config/formats";
import { sanitizeHTML } from "../../config/methods";
import { IMAGES_URL } from "../../config/config";

// Icons
import { CalendarMonth, CalendarToday, Timelapse } from "@mui/icons-material";

const PreviewCardAgenda = ({ dateStart, dateEnd, title, content }) => {
	return (
		<div className="w-full h-full flex flex-col gap-y-4">
			<div className="rounded-lg w-full min-h-full flex flex-col px-8 pt-4 pb-2 bg-bgNeutral-light shadow-custom opacity-[.85]">
				<div className="mt-2 2xl:mt-4 w-full flex flex-col gap-y-6 lg:gap-y-4 py-2">
					<div className="flex flex-col gap-y-3 2xl:flex-row 2xl:items-center 2xl:gap-x-5 2xl:gap-y-0">
						<div className="flex items-center gap-x-2">
							<p className="flex items-center gap-x-1 text-xs text-textNeutral-dark/[.67]">
								<i>
									<CalendarMonth sx={{ fontSize: 18 }} />
								</i>
								Date de début:
							</p>
							<span className="flex items-center text-xs text-textNeutral-dark/[.75] font-semibold">
								{dateStart && formatDateString(dateStart)}
							</span>
						</div>
						<div className="flex items-center gap-x-2">
							<p className="flex items-center gap-x-1 text-xs text-textNeutral-dark/[.67]">
								<i>
									<CalendarMonth sx={{ fontSize: 18 }} />
								</i>
								Date de fin:
							</p>
							<span className="flex items-center text-xs text-textNeutral-dark/[.75] font-semibold">
								{dateEnd && formatDateString(dateEnd)}
							</span>
						</div>
						{/* <div className="flex items-center gap-x-2">
							<p className="flex items-center gap-x-1 text-xs text-textNeutral-dark/[.75]">
								<i>
									<Timelapse sx={{ fontSize: 18 }} />
								</i>
								Durée de l'évènement:
							</p>
							<span className="flex items-center text-xs text-textNeutral-dark/[.75]">
								{dateEnd &&
									eachDayOfInterval(dateStart, dateEnd)}
								{dateStart &&
									dateEnd &&
									eachDayOfInterval({
										start: fnsFormatDate(dateStart),
										end: fnsFormatDate(dateEnd),
									})}
							</span>
						</div> */}
					</div>
					<div className="mt-3 flex flex-col gap-y-1">
						<h2 className="w-full text-[1.9em] text-textNeutral-dark font-semibold leading-[1.1em] overflow-hidden">
							{title}
						</h2>
						<div
							className="quill-editor-theme mt-3 pr-0 lg:pr-[2em] text-[13px] text-textNeutral-dark/[.72] leading-[1.9em]"
							dangerouslySetInnerHTML={{ __html: content }}
						>
							{/* {sanitizeHTML(content)} */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PreviewCardAgenda;
