// External librairies
import { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Turnstone from "turnstone";

// Query
import { request } from "../../utils/axios";

// Context
import { AuthContext } from "../../contexts/AuthProvider";

// Icons
import { Search } from "@mui/icons-material";

const SearchBar = () => {
	const { user } = useContext(AuthContext);
	const navigate = useNavigate();
	const turnstoneRef = useRef();

	const listbox = [
		{
			displayField: "reports",
			name: "Signalements",
			id: "reports",
			ratio: 5,
			searchType: "contains",
			data: async (query) => {
				return await fetchReports(query);
			},
		},
		{
			displayField: "users",
			name: "Utilisateurs",
			id: "users",
			ratio: 5,
			searchType: "contains",
			data: async (query) => {
				return await fetchUsers(query);
			},
		},
	];

	const fetchReports = async (query) => {
		return request(
			`/reports?search=${encodeURIComponent(query)}`,
			"get",
			{},
			user.accessToken
		)
			.then((res) => {
				return res.data.map((report) => ({
					id: report.id,
					value: report.title,
					route: "reports",
				}));
			})
			.catch((_) => {
				return [];
			});
	};

	const Item = ({ item }) => {
		return <div>{item.value}</div>;
	};

	const fetchUsers = async (query) => {
		return request(
			`/users?search=${encodeURIComponent(query)}`,
			"get",
			{},
			user.accessToken
		)
			.then((res) => {
				return res.data.map((user) => ({
					id: user.id,
					value: `${user.first_name} ${user.last_name}`,
					route: "users",
				}));
			})
			.catch((_) => {
				return [];
			});
	};

	const styles = {
		input: "text-[15px] ml-4 w-full bg-transparent focus:outline-none",
		listbox: "bg-white rounded-md mt-2 w-full",
		clearButton:
			"absolute inset-y-0 text-lg right-0 w-10 inline-flex items-center justify-center",
		groupHeading:
			"cursor-default mt-2 mb-0.5 px-1.5 uppercase text-sm text-primary",
		item: "block rounded-md cursor-pointer py-2 px-4",
		highlightedItem:
			"block rounded-md cursor-pointer py-2 px-4 bg-primary text-white",
	};

	const onSelect = (item) => {
		if (typeof item === "object") {
			turnstoneRef.current?.clear(); //to prevent auto fire onSelect
			navigate(`${item.route}/${item.id}/brief`);
		}
	};

	return (
		<div className="h-10 p-2.5 flex items-center rounded-md px-4 duration-300 cursor-pointer bg-gray-200 text-gray-400">
			<i>
				<Search sx={{ fontSize: 24 }} />
			</i>
			<Turnstone
				ref={turnstoneRef}
				id={"globalSearch"}
				name={"globalSearch"}
				placeholder={"Rechercher..."}
				styles={styles}
				listbox={listbox}
				maxItems={10}
				debounceWait={250}
				matchText={false}
				defaultListboxIsImmutable={false}
				listboxIsImmutable={false}
				typeahead={true}
				autoFocus={false}
				noItemsMessage="Aucun résultat"
				Item={Item}
				onEnter={(query, selectedItem) => onSelect(selectedItem?.value)}
				onSelect={(selectedItem) => onSelect(selectedItem)}
				autoComplete={"off"}
			/>
		</div>
	);
};

export default SearchBar;
