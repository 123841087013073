import { SurveyAddButton } from '../../../components';

const SurveyMenu = ({ title, append }) => {
	return (
		<div className="border-[1px] border-primary w-full 2xl:mt-5 rounded-[12px] xl:min-w-[14rem] xl:max-w-[14rem] md:max-h-[33rem] flex flex-col gap-y-5 p-8 bg-bgNeutral-light shadow-custom sticky top-10">
			{title && <h3>{title}</h3>}
			<div>
				<ul className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-1 xl:gap-y-3">
					<li className="w-full">
						<SurveyAddButton
							caption="Réponse courte"
							onClick={() => {
								append({ type: 'text' });
							}}
							icon="text"
						/>
					</li>
					<li className="w-full">
						<SurveyAddButton
							caption="Paragraphe"
							onClick={() => {
								append({ type: 'textarea' });
							}}
							icon="textarea"
						/>
					</li>
					<li className="w-full">
						<SurveyAddButton
							caption="Liste sélective"
							onClick={() => {
								append({ type: 'select' });
							}}
							icon="select"
						/>
					</li>
					<li className="w-full">
						<SurveyAddButton
							caption="Choix multiple"
							onClick={() => {
								append({ type: 'checkbox' });
							}}
							icon="checkbox"
						/>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default SurveyMenu;
