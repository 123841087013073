// External librairies
import {useMutation} from "@tanstack/react-query";

// Instance
import instance from "../../config/axiosconfig";
import {refreshToken} from "../../utils";

export const useMutationContact = (id) => {
    return useMutation({
        mutationKey: ["mutateContact"],
        mutationFn: async (updateContact) => {
            try {
                return await instance.put(`/contacts/${id}`, updateContact);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    await refreshToken();
                    return await instance.put(`/contacts/${id}`, updateContact);
                }
                throw error
            }
        },
    });
};

export const useMutationContactAdd = () => {
    return useMutation({
        mutationKey: ["mutateContactAdd"],
        mutationFn: async (addContact) => {
            try {
                return await instance.post("/contacts", addContact);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    await refreshToken();
                    return await instance.post("/contacts", addContact);
                }
                throw error
            }
        },
    });
};