// External librairies
import React, {useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {toast} from "react-toastify";

// Query
import {useMutationUserAdd, useQueryCategories, useQueryPlugins} from "../../queries";

// Components
import {
    NavigationHeader,
    TextInput,
    RadioInput,
    SelectMultiInput,
    FormButton,
} from "../../components";

// Config
import {ROLES} from "../../config/config";
import {formSchemaUserAdd} from "../../config/yupconfig";

// Misc
import {Transition} from "../../utils/react-router";
import {randomPassword} from "../../config/methods";
import {useNavigate} from "react-router-dom";

const UsersPageCreate = () => {
    const {mutate} = useMutationUserAdd();
    const {data: categories} = useQueryCategories();
    const {data: plugins} = useQueryPlugins();
    const navigate = useNavigate();

    const {
        register,
        formState: {errors, isValid},
        handleSubmit,
        reset,
        control,
        watch,
    } = useForm({
        resolver: yupResolver(formSchemaUserAdd),
    });

    const watchUserRole = watch("roleChoice");
    const watchUserPlugin = watch("selectPluginMulti")

    useEffect(() => {
        let defaultValues = {};
        defaultValues.roleChoice = "citizen";
        defaultValues.categories = [];
        reset({...defaultValues});
    }, [reset]);

    const onSubmit = (data, e) => {
        e.preventDefault();
        const newUserData = {
            birthday: new Date(),
            username: `newUser-${Date.now()}`,
            password: randomPassword(12),
            role: data.roleChoice,
            ...data,
        };

        const cat = data.selectCategoryMulti;
        const formatedCat = cat?.map((c) => c.value);
        const plugins = data.selectPluginMulti;
        const formatedPlugins = plugins?.map((p) => p.value)

        if (["associate", "manager"].includes(data.roleChoice)) {
            newUserData.categories = formatedCat;
            newUserData.plugins = formatedPlugins;
        }
        delete newUserData.roleChoice;
        delete newUserData.selectCategoryMulti;
        delete newUserData.selectPluginMulti;

        mutate(newUserData, {
            onSuccess: (res) => {
                toast.success("L'utilisateur a été créé !")
                navigate(`/users/${res.data.data.id}/brief`)
            },
            onError: () => toast.error("Une erreur est survenue"),
        });
    };
    return (
        <Transition>
            <div className="w-full lg:w-[46rem] flex flex-col gap-y-5">
                <NavigationHeader
                    title="Utilisateurs"
                    subTitle="Ajouter un utilisateur"
                    returnUrl="/users"
                />
                <form
                    className="flex flex-col"
                    onSubmit={(e) => {
                        handleSubmit(onSubmit)(e);
                    }}
                >
                    <div className="flex flex-col gap-y-6 md:gap-y-10">
                        <div className="flex flex-col gap-y-8 md:flex-row justify-between">
                            <TextInput
                                id="first_name"
                                label="Prénom"
                                register={{
                                    ...register("first_name", {
                                        required: true,
                                    }),
                                }}
                                error={errors?.first_name}
                            />
                            <TextInput
                                id="last_name"
                                label="Nom de famille"
                                register={{
                                    ...register("last_name", {
                                        required: true,
                                    }),
                                }}
                                error={errors?.last_name}
                            />
                        </div>
                        <div className="flex flex-col gap-y-8 md:flex-row justify-between">
                            <TextInput
                                id="email"
                                label="Email"
                                register={{
                                    ...register("email", {
                                        required: true,
                                    }),
                                }}
                                error={errors?.email}
                            />
                            <div>
                                {["associate", "manager"].includes(
                                    watchUserRole
                                ) && (
                                    <Transition>
                                        <Controller
                                            name="selectPluginMulti"
                                            control={control}
                                            render={({field}) => (
                                                <SelectMultiInput
                                                    placeholder="Modules"
                                                    options={plugins}
                                                    field={field}
                                                    label="Modules"
                                                    error={
                                                        errors?.selectPluginMulti
                                                    }
                                                />
                                            )}
                                        />
                                    </Transition>
                                )}
                            </div>
                        </div>
                        <div>
                            {
                                (watchUserPlugin && watchUserPlugin.length > 0) && (
                                    (watchUserPlugin.map(p => p.value).includes('reports') && ["associate", "manager"].includes(watchUserRole)) && (
                                        <Transition>
                                            <Controller
                                                name="selectCategoryMulti"
                                                control={control}
                                                render={({field}) => (
                                                    <SelectMultiInput
                                                        placeholder="Catégories"
                                                        options={categories}
                                                        field={field}
                                                        label="Catégories"
                                                        error={
                                                            errors?.selectCategoryMulti
                                                        }
                                                    />
                                                )}
                                            />
                                        </Transition>
                                    )
                                )
                            }

                        </div>
                        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-y-6 gap-x-3 md:gap-x-4">
                            {ROLES &&
                                ROLES.filter((v) => v.slug !== "all").map(
                                    (role) => {
                                        return (
                                            <RadioInput
                                                key={role.id}
                                                id={role.slug}
                                                label={role.name}
                                                register={{
                                                    ...register("roleChoice"),
                                                }}
                                                value={role.slug}
                                                bg={role.bg}
                                                bgFade={role.bgFade}
                                                border={role.border}
                                                color={role.color}
                                                ring={role.ring}
                                            />
                                        );
                                    }
                                )}
                        </div>
                        <div className="mt-5 w-full flex justify-end">
                            <FormButton
                                caption="Ajouter l'utilisateur"
                                icon="user"
                                isValid={isValid}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </Transition>
    );
};

export default UsersPageCreate;
