import React, {useContext, useEffect} from "react";
import {NavLink} from "react-router-dom";
import SearchBar from "../SearchBar/SearchBar";
import {Icon, Badge} from "@mui/material"

import {slugToIcon} from "../../config/formats";
import {useQueryCountUnreadNotifications} from "../../queries";
import {NotificationContext} from "../../contexts/NotificationsContext";

const Header = () => {
    const { unreadNotifications, setUnreadNotifications } = useContext(NotificationContext);
    const {isLoading, data: unreadCount} = useQueryCountUnreadNotifications();

    useEffect(() => {
        if (!isLoading) {
            setUnreadNotifications(unreadCount)
        }
    }, [isLoading, setUnreadNotifications, unreadCount])
    return (
        <div>
            <div className="flex justify-between items-center">
                <div className="flex items-center">
                    <img className="h-8 mr-4" src={`${process.env.REACT_APP_API_URL}/uploads/logo.png`} alt="city img"/>
                    <p className="dark:text-textNeutral-light">
                        Commune de {process.env.REACT_APP_CITY_NAME}
                    </p>
                </div>
                <div className="flex items-center gap-x-4">
                    <div>
                        <div className="hidden md:flex">
                            <SearchBar/>
                        </div>
                    </div>
                    <div
                        className={
                            "hidden md:h-10 md:w-10 md:rounded-md md:grid md:content-center md:justify-center md:text-white md:bg-primary"
                        }
                    >
                        <Icon className="text-textNeutral-light dark:text-textNeutral-light"
                              sx={{fontsize: 20}}>{slugToIcon["message"]}</Icon>

                    </div>
                    <div
                        className={
                            "hidden md:h-10 md:w-10 md:grid md:content-center md:justify-center"
                        }
                    >
                        <NavLink to={"Notifications"}>
                            <Badge
                                badgeContent={unreadNotifications}
                                color="error"
                            >
                                <Icon className="text-primary" sx={{fontsize: 20}}>{slugToIcon["notifications"]}</Icon>
                            </Badge>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;