// External librairies
import React, {useState, useEffect, useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {toast} from "react-toastify";

// Queries
import {useMutationNewsAdd, useMutationUploadImages} from "../../queries";

// Components
import {
    NavigationHeader,
    PreviewCardNews,
    TextInput,
    EditorQuill,
    ImageInput,
    FormButton,
    CancelButton, TextAreaInput, SearchBoxInput,
} from "../../components";

// Config
import {formSchemaNewsAdd} from "../../config/yupconfig";

// Misc
import {Transition} from "../../utils/";
import {useQueryNewsSections} from "../../queries/query/queryNews";

const NewsPageCreate = () => {
    const {mutate: uploadImages} = useMutationUploadImages();
    const {mutate: postNews} = useMutationNewsAdd();
    const {isLoading, data: sections, error} = useQueryNewsSections()
    const [images, setImages] = useState([]);

    const navigate = useNavigate();

    const [inputs, setInputs] = useState({
        image: null,
        title: "",
        content: "",
    });

    const {
        control,
        register,
        formState: {errors, isValid},
        handleSubmit,
        watch,
    } = useForm({
        resolver: yupResolver(formSchemaNewsAdd),
    });

    const handleFormChange = useCallback(
        (value, name) => {
            setInputs({...inputs, [name]: value[name]});
        },
        [inputs]
    );

    // Dynamic text render for preview
    useEffect(() => {
        const subscription = watch((value, {name}) => {
            handleFormChange(value, name);
        });
        return () => subscription.unsubscribe();
    }, [watch, handleFormChange]);

    const onSubmit = async (data, e) => {
        e.preventDefault();
        const news = {
            ...data,
            section: typeof data.section === "object" ? data.section.value : data.section,
        }
        if (images.length > 0) {
            await uploadImages(images, {
                onSuccess: (res) => {
                    news.images = res.data.data;
                    postNews(news, {
                        onSuccess: () => {
                            navigate("/news");
                            toast.success("L'article a été publié")
                        },
                        onError: () => toast.error("Une erreur est survenue lors de la publication de l'article")
                    });
                },
                onError: () => toast.error("Les images n'ont pas été téléchargées")
            })
        } else {
            postNews(news, {
                onSuccess: () => {
                    navigate("/news");
                    toast.success("La nouvelle a été publiée")
                },
                onError: () => toast.error("Une erreur est survenue lors de la publication de la nouvelle")
            })
        }
    };


    return (
        <Transition>
            <NavigationHeader title="Rédiger une Nouvelle" returnUrl="/news"/>
            <div className="mt-10 mb-12 flex flex-col-reverse gap-y-32 xl:flex-row xl:gap-x-14 xl:gap-y-0">
                <div className="w-full xl:w-2/5 pt-[2.7em]">
                    <PreviewCardNews
                        images={images}
                        title={inputs.title}
                        shortTitle={inputs.short_title}
                        content={inputs.content}
                        section={typeof inputs.section === "object" ? inputs.section.value : inputs.section}
                    />
                </div>
                <div className="w-full xl:w-3/5">
                    <form
                        className="flex flex-col gap-y-5"
                        onSubmit={(e) => {
                            handleSubmit(onSubmit)(e);
                        }}
                    >
                        <div className="mt-5 flex flex-col gap-y-10">
                            <TextInput
                                id="title"
                                label="Titre"
                                register={{
                                    ...register("title", {
                                        required: true,
                                    }),
                                }}
                                error={errors?.title}
                            />
                            <Controller
                                control={control}
                                name="section"
                                render={({field: {onChange, value}}) => (
                                    <SearchBoxInput
                                        id="news_sections"
                                        label="Rubrique"
                                        fetchResults={(search) => {
                                            return sections.filter(section => section.value.toLowerCase().includes(search.toLowerCase()))
                                        }}
                                        value={value}
                                        onChange={onChange}
                                        error={errors?.section}
                                    />
                                )}
                            />
                            <TextAreaInput
                                id="short_title"
                                label="Description courte"
                                register={{
                                    ...register("short_title", {
                                        required: false
                                    })
                                }}
                                error={errors?.short_title}
                            />
                            <Controller
                                control={control}
                                name="content"
                                render={({field: {onChange, value}}) => (
                                    <EditorQuill
                                        label="Contenu"
                                        value={value}
                                        onChange={onChange}
                                        error={errors?.content}
                                    />
                                )}
                            />
                            <div>
                                <ImageInput
                                    images={images}
                                    setImages={setImages}
                                />
                            </div>
                        </div>
                        <div
                            className="mt-5 w-full flex flex-col gap-y-3 md:flex-row md:justify-end md:items-center md:gap-x-4 md:gap-y-0">
                            <CancelButton caption="Annuler" backTo="news"/>
                            <FormButton
                                caption="Ajouter la Nouvelle"
                                isValid={isValid}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </Transition>
    );
};

export default NewsPageCreate;
