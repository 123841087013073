// External librairies
import React from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

// Icons
import {
	KeyboardBackspace,
} from "@mui/icons-material";
import { Icon } from '@mui/material';
import {slugToIcon} from "../../config/formats";

const ActionHeader = ({
	icon,
	title,
	subTitle,
	actionData,
	hasReturn = true,
	returnUrl,
}) => {
	return (
		<header className="mt-6 mb-[3em] w-full flex flex-col lg:flex-row lg:justify-between lg:items-center lg:gap-y-0 border-b border-bgNeutral-dark/[.09] gap-y-8 pb-6">
			<div className="flex items-center gap-x-5">
				{hasReturn && (
					<div>
						<NavLink to={`${returnUrl}`}>
							<button className="rounded-lg flex justify-center items-center py-2 px-3 text-textNeutral-light bg-primary transition-all hover:opacity-75">
								<KeyboardBackspace sx={{ fontSize: 19 }} />
							</button>
						</NavLink>
					</div>
				)}

				<div className="flex flex-col gap-y-1 md:flex-row md:items-center md:gap-x-3 md:gap-y-0">
					<div className="flex items-center gap-x-3">
						<Icon className="text-textNeutral-dark" sx={{ fontSize: 24 }}>{slugToIcon[icon]}</Icon>
						<h1>{title}</h1>
					</div>
					{subTitle && (
						<>
							<span className="hidden md:block">-</span>
							<span className="font-light text-sm text-textNeutral-dark">
								{subTitle}
							</span>
						</>
					)}
				</div>
			</div>
			<nav>
				{actionData && (
					<ul className="flex items-center gap-x-3">
						{actionData.map((n) => (
							<li
								key={n.id}
								className={classNames(
									"rounded-lg flex justify-center items-center gap-x-4 transition-all hover:opacity-[.85]",
									n.isSecondary
										? "border-2 border-primary bg-primary/[.02]"
										: "border-0 bg-primary"
								)}
							>
								<NavLink
									className="flex justify-between items-center gap-x-4 px-6 py-3"
									to={n.route}
								>
									{n.icon && (
										<Icon className={classNames(
											"pointer-events-none",
											n.isSecondary
												? "text-primary"
												: "text-textNeutral-light"
										)}>{slugToIcon[n.icon]}</Icon>
									)}
									<span
										className={classNames(
											"text-sm font-semibold whitespace-nowrap",
											n.isSecondary
												? "text-primary"
												: "text-textNeutral-light"
										)}
									>
										{n.label}
									</span>
								</NavLink>
							</li>
						))}
					</ul>
				)}
			</nav>
		</header>
	);
};

export default ActionHeader;
