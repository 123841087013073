// External librairies
import React from "react";
import {useOutletContext} from "react-router-dom";

// Config

// Icons
import {CalendarToday, Link, Place, Sell} from "@mui/icons-material";

// Misc
import {Transition} from "../../utils/";

const AgendaPageBrief = () => {
    const eventData = useOutletContext();

    return (
        <Transition>
            <div className="rounded-lg mt-10 mb-12 mx-auto w-full lg:w-[780px] p-12 bg-bgNeutral-light shadow-custom">
                <div className="w-full flex flex-col gap-y-6 py-2">
                    <h1>
                        {eventData.title}
                    </h1>
                    <div className="flex flex-col gap-y-2">
                        {eventData.dateRange && (
                            <div className="flex items-center gap-x-2 text-xs text-textNeutral-dark/[.38]">
                                <p className="flex items-center gap-x-1 text-xs text-textNeutral-dark/[.67]">
								<span>
									<CalendarToday sx={{fontSize: 16}}/>
								</span>
                                </p>
                                <span className="flex items-center text-xs text-textNeutral-dark/[.75] font-semibold">
								{eventData.dateRange}
							</span>
                            </div>
                        )}
                        {eventData.location && (
                            <div className="flex items-center gap-x-2 text-xs text-textNeutral-dark/[.38]">
                                <p className="flex items-center gap-x-1 text-xs text-textNeutral-dark/[.67]">
								<span>
									<Place sx={{fontSize: 16}}/>
								</span>
                                </p>
                                <span className="flex items-center text-xs text-textNeutral-dark/[.75] font-semibold">
								{eventData.location}
							</span>
                            </div>
                        )}
                        {eventData.link && (
                            <div className="flex items-center gap-x-2 text-xs text-textNeutral-dark/[.38]">
                                <p className="flex items-center gap-x-1 text-xs text-textNeutral-dark/[.67]">
								<span>
									<Link sx={{fontSize: 16}}/>
								</span>
                                </p>
                                <span className="flex items-center text-xs text-textNeutral-dark/[.75] font-semibold">
								<a className="text-secondary hover:text-primary"
                                   href={eventData.link}>{eventData.link}</a>
							</span>
                            </div>
                        )}
                        {eventData.price && (
                            <div className="flex items-center gap-x-2 text-xs text-textNeutral-dark/[.38]">
                                <p className="flex items-center gap-x-1 text-xs text-textNeutral-dark/[.67]">
								<span>
									<Sell sx={{fontSize: 16}}/>
								</span>
                                </p>
                                <span className="flex items-center text-xs text-textNeutral-dark/[.75] font-semibold">
								{eventData.price}
							</span>
                            </div>
                        )}

                    </div>
                    <div className="flex flex-col gap-y-1">
                        <div
                            className="quill-editor-theme pr-0 lg:pr-[2em] text-[16px] text-textNeutral-dark/[.72] leading-[1.9em]"
                            dangerouslySetInnerHTML={{
                                __html: eventData.description,
                            }}
                        ></div>
                        {eventData.image && (
                            <figure className="rounded-lg block w-full h-[12em] lg:h-full overflow-hidden">
                                <img
                                    className="w-full h-full object-cover object-center"
                                    src={eventData.image}
                                    alt=""
                                />
                            </figure>
                        )}
                        {eventData.longDescription && (
                            <div
                                className="quill-editor-theme pr-0 lg:pr-[2em] text-[16px] text-textNeutral-dark/[.72] leading-[1.9em]"
                                dangerouslySetInnerHTML={{
                                    __html: eventData.longDescription,
                                }}
                            ></div>
                        )}
                    </div>
                </div>
            </div>
        </Transition>
    );
};

export default AgendaPageBrief;
