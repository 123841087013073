// Icons
import { TextFormat, TextFields, FilterList, CheckCircleOutline } from "@mui/icons-material";

const SurveyAddButton = ({ caption, icon, onClick }) => {

	const getIcon = (icon) => {
		switch (icon) {
			case "text":
				return <TextFields sx={{ fontSize: 42 }} />;
			case "textarea":
				return <TextFormat sx={{ fontSize: 42 }} />;
			case "select":
				return <FilterList sx={{ fontSize: 42 }} />;
			case "checkbox":
				return <CheckCircleOutline sx={{ fontSize: 42 }} />;
			default:
				return null
		}
	};
	return (
		<div className="group rounded-[12px] border-2 border-bgNeutral-light flex justify-center bg-primary transition-all duration-300 hover:bg-bgNeutral-light hover:border-primary">
			<button
				type="button"
				onClick={onClick}
				className="w-full h-full flex flex-row lg:flex-col items-center gap-y-3 relative py-6 lg:py-4 pl-6 lg:px-2 text-[12px] text-bgNeutral-light font-semibold uppercase whitespace-nowrap overflow-hidden transition-all duration-300 group-hover:text-primary"
			>
				<i className="absolute top-1/2 -translate-y-1/2 right-4 lg:top-4 lg:right-0 lg:left-1/2 lg:-translate-x-1/2 opacity-[.35] text-bgNeutral-light font-semibold lg:relative left-auto lg:opacity-[.45] transition-all duration-300 group-hover:text-primary">
					{getIcon(icon)}
				</i>
				{caption}
			</button>
		</div>
	);
};

export default SurveyAddButton;
