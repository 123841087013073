// External librairies
import React from 'react';
import { NavLink } from 'react-router-dom';

// Query
import { useQueryForums } from '../../queries';

// Hooks
import { useLocalStorage } from '../../hooks/useLocalStorage';

// Components
import {ActionHeader, NoData, PrivatePublicTag} from '../../components';

// Icons
import {
	Forum,
} from '@mui/icons-material';

// Misc
import { Loader } from '../../utils';
import { Transition } from '../../utils/';

const ForumPage = () => {
	const [user] = useLocalStorage('user', null);
	const { isLoading, data } = useQueryForums(user.id);
	//TODO forums bloqués par défaut

	const actionData = [
		{
			id: 0,
			route: 'create',
			label: 'Ajouter un forum'
		}
	];

	// const onHandleSubscribe = (forumId) => {
	// 	mutateSubscribe(forumId, {
	// 		onSuccess: () => {
	// 			toast.success('Vous êtes maintenant inscris !');
	// 		},
	// 		onError: () => toast.error('Une erreur est survenue')
	// 	});
	// };

	return (
		<>
			<div>
				<ActionHeader
					icon="forum"
					title="Forum"
					hasReturn={false}
					actionData={actionData}
				/>
			</div>
			{!data ? (
				<NoData caption="Aucun forum disponible" />
			) : (
				<div>
					<ul>
						<Transition>
							{isLoading ? (
								<Loader />
							) : (
								<div className="w-full">
									<ul className="flex flex-col gap-y-8 py-5">
										{data.map((d) => (
											<NavLink
												key={d.id}
												to={`${d.id}/brief`}
											>
												<li className="border-[2px] border-primary/[0] rounded-[12px] flex items-center font-semibold text-textNeutral-dark py-6 px-10 relative bg-bgNeutral-light shadow-custom cursor-pointer transition-all duration-300 hover:border-primary/[1]">

													<div className="w-full flex items-center justify-between">
														<div className="flex gap-x-5 items-center">
															<i>
																<Forum
																	sx={{
																		fontSize: 24
																	}}
																/>
															</i>
															<h3>{d.title}</h3>
														</div>
														<div>
															<PrivatePublicTag isPublic={d.isPublic} />
														</div>
													</div>
												</li>
											</NavLink>
										))}
									</ul>
								</div>
							)}
						</Transition>
					</ul>
				</div>
			)}
		</>
	);
};

export default ForumPage;
