// External librairies
import {useQuery} from "@tanstack/react-query";

// Config
import instance from "../../config/axiosconfig";

export const useQuerySurvey = () => {
	return useQuery({
		queryKey: ["getSurvey"],
		queryFn: () => instance.get(`/surveys`),
		select: (data) => {
			return data.data.data;
		},
	});
};

export const useQuerySurveySelect = (id) => {
	return useQuery({
		queryKey: ["getSurveySelect"],
		queryFn: () => instance.get(`/surveys/${id}`),
		select: (data) => {
			return data.data.data;
		},
	});
};

export const useQuerySurveyResponsesSelect = (id) => {
	return useQuery({
		queryKey: ['getSurveyResponsesSelect'],
		queryFn: () => instance.get(`/surveys/${id}/responses?format=csv`),
		select: (data) => {
			return data.data;
		},
		// enabled: false
	});
};

