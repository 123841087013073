import { ErrorMessage } from '../Errors';
import FormLabel from '../Form/FormLabel';

const TextInput = ({
	type = 'text',
	label,
	placeholder,
	id,
	register,
	error,
	...rest
}) => {
	return (
		<div className="w-full md:w-[18rem] xl:w-[20.5rem] flex flex-col justify-start">
			<FormLabel htmlFor={id}>{label}</FormLabel>
			<input
				type={type}
				id={id}
				name={id}
				placeholder={label || placeholder}
				className={`border ${
					error ? 'border-red' : 'border-bgNeutral-elementborderlight'
				} dark:border-bgNeutral-dark bg-bgNeutral-elementlight dark:bg-bgNeutral-elementdark shadow-custom rounded-lg w-full px-4 py-3 text-xs text-textNeutral-dark dark:text-textNeutral-light transition-all outline-none placeholder:text-[11px] focus:border-primary focus:ring-2 focus:ring-primary cursor-text`}
				{...register}
				{...rest}
			/>
			{error && (
				<ErrorMessage
					type={error.type == 'required' ? 'error' : 'pattern'}
				/>
			)}
		</div>
	);
};

export default TextInput;
