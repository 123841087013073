// const Map = ({ location, zoomLevel, onChange, hideMarker }) => {
// 	const defaultLocation = location
// 		? location
// 		: {
// 				lat: parseFloat(process.env.REACT_APP_CITY_LAT),
// 				lng: parseFloat(process.env.REACT_APP_CITY_LNG),
// 		  };
// 	return (
// 		<div className="w-full h-96 relative">
// 			<GoogleMapReact
// 				defaultCenter={defaultLocation}
// 				defaultZoom={zoomLevel}
// 				bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
// 				onChange={onChange ? onChange : undefined}
// 			/>
// 			<div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
// 				<LocationPin />
// 			</div>
// 		</div>
// 	);
// };

// export default Map;

// External librairies
import React from "react";
import GoogleMapReact from "google-map-react";

// Icons
import { PersonPinCircle } from "@mui/icons-material";

const SimpleMap = ({ location, onChange }) => {
	const defaultProps = {
		center: location
			? location
			: {
					lat: parseFloat(process.env.REACT_APP_CITY_LAT),
					lng: parseFloat(process.env.REACT_APP_CITY_LNG),
			  },
		zoom: 11,
	};

	return (
		<div className="relative rounded-lg w-full h-[26rem] shadow-custom overflow-hidden">
			<div className="z-10 font-bold text-2xl text-primary absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 shadow-custom">
				<PersonPinCircle sx={{ fontSize: 50 }} />
			</div>
			<GoogleMapReact
				bootstrapURLKeys={{
					key: process.env.REACT_APP_GOOGLE_API_KEY,
				}}
				defaultCenter={defaultProps.center}
				defaultZoom={defaultProps.zoom}
				onChange={onChange}
			/>
		</div>
	);
};

export default SimpleMap;
