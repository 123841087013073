// External librairies
import React from "react";
import {useParams, Outlet, useNavigate} from "react-router-dom";

// Query
import {useMutationNewsDelete, useQueryNewsSelect} from "../../queries";

// Components
import { NavigationHeader } from "../../components";

// Misc
import { Loader } from "../../utils";
import {toast} from "react-toastify";

const NewsPageSelect = () => {
	const { id } = useParams();
	const { isLoading, data } = useQueryNewsSelect(id);
	const { mutate: deleteNews } = useMutationNewsDelete();
	const navigate = useNavigate();

	const navData = [
		{
			id: 0,
			route: "brief",
			label: "Aperçu",
			isExtra: false
		},
		{
			id: 1,
			route: "edit",
			label: "Editer",
			isExtra: false
		},
	];

	const handleDelete = () => {
		deleteNews(id, {
			onSuccess: () => {
				navigate("/news");
				toast.success("L'article a été supprimé")
			},
			onError: () => {
				toast.error("Impossible de supprimer l'article")
			}
		})
	}

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<>
					<div>
						<NavigationHeader
							title="Nouvelles"
							subTitle={data?.title}
							returnUrl="/news"
							navData={navData}
							canDelete={true}
							handleDelete={handleDelete}
						/>
					</div>
					<div className="mt-3 block max-w-full">
						<Outlet context={data} />
					</div>
				</>
			)}
		</>
	);
};

export default NewsPageSelect;
