import {IMAGES_URL} from "../../config/config";
import {Carousel} from "react-responsive-carousel";
import React from "react";

const ImageCarousel = ({images, type}) => {
    return (
        <Carousel
            swipeable={true}
            useKeyboardArrows={true}
            statusFormatter={(currentItem, total) => {
                return (
                    <p>
                        {currentItem}/{total}
                    </p>
                );
            }}
        >
            {
                images?.map((img) => {
                    return (
                        <div key={img.filename}>
                            <img
                                className="rounded-lg w-full h-full object-cover object-center"
                                src={type==="local" ? img : IMAGES_URL + img.filename}
                                alt=""
                            />
                        </div>
                    )
                })
            }
        </Carousel>
    )
}

export default ImageCarousel;