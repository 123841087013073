// External librairies
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

// Queries
import { useMutationAgendaAdd } from "../../queries";

// Components
import {
	NavigationHeader,
	PreviewCardAgenda,
	TextInput,
	SelectInput,
	EditorQuill,
	DateInput,
	FormButton,
	CancelButton,
} from "../../components";

// Config
import { RECURRENCE } from "../../config/config";
import { formSchemaEventAdd } from "../../config/yupconfig";

// Misc
import { Transition } from "../../utils/";

const AgendaPageCreate = () => {
	const { mutate: mutateAgendaAdd } = useMutationAgendaAdd();

	const navigate = useNavigate();

	const [inputs, setInputs] = useState({
		dateStart: null,
		dateEnd: null,
		title: "",
		content: "",
	});

	const {
		control,
		register,
		formState: { errors, isValid },
		handleSubmit,
		watch,
	} = useForm({
		resolver: yupResolver(formSchemaEventAdd),
	});

	// Dynamic preview function
	const handleFormChange = useCallback(
		(value, name) => {
			setInputs({ ...inputs, [name]: value[name] });
		},
		[inputs]
	);

	// Dynamic text render for preview
	useEffect(() => {
		const subscription = watch((value, { name }) => {
			handleFormChange(value, name);
		});
		return () => subscription.unsubscribe();
	}, [watch, handleFormChange]);

	const onSubmit = async (data, e) => {
		e.preventDefault();
		let newEventData = {
			title: inputs.title,
			date: inputs.dateStart,
			end: inputs.dateEnd,
			description: inputs.content,
		};

		mutateAgendaAdd(newEventData, {
			onSuccess: () => {
				toast.success("L'Evènement a été créé");
				navigate("/agenda");
			},
			onError: () => toast.error("Une erreur est survenue"),
		});
	};

	return (
		<Transition>
			<NavigationHeader
				title="Agenda"
				subTitle="Ajouter un évènement"
				returnUrl="/agenda"
			/>
			<div className=" mt-10 mb-12 flex flex-col gap-y-10 xl:flex-row xl:justify-between xl:gap-x-12 xl:gap-y-0">
				<div className="border-b border-bgNeutral-dark/[.09] mt-12 w-full xl:w-[45em] pb-16 xl:border-0 xl:pb-0">
					<form
						className="flex flex-col gap-y-10"
						onSubmit={(e) => {
							handleSubmit(onSubmit)(e);
						}}
					>
						<div className="flex flex-col md:flex-row md:justify-between gap-y-8 md:gap-y-0 gap-x-5">
							<div className="flex flex-col">
								<DateInput
									id="dateStart"
									label="Date de début"
									register={{
										...register("dateStart", {
											required: true,
										}),
									}}
									error={errors?.dateStart}
								/>
							</div>
							<div className="flex flex-col">
								<DateInput
									id="dateEnd"
									label="Date de fin"
									register={{
										...register("dateEnd", {
											required: true,
										}),
									}}
									error={errors?.dateEnd}
								/>
							</div>
						</div>
						<div className="mt-5 flex flex-col gap-y-10">
							<TextInput
								id="title"
								label="Titre"
								register={{
									...register("title", {
										required: true,
									}),
								}}
								error={errors?.title}
							/>
							<Controller
								control={control}
								name="content"
								render={({ field: { onChange, value } }) => (
									<EditorQuill
										label="Description de l'évènement"
										value={value}
										onChange={onChange}
									/>
								)}
							/>
						</div>
						<div className="mt-5 w-full flex flex-col gap-y-3 md:flex-row md:justify-end md:items-center md:gap-x-4 md:gap-y-0">
							{/* <CancelButton caption="Annuler" backTo="agenda" /> */}
							<FormButton
								caption="Ajouter l'évènement"
								isValid={isValid}
							/>
						</div>
					</form>
				</div>
				<div className="w-full xl:w-2/5 pt-[2.7em]">
					<PreviewCardAgenda
						dateStart={inputs.dateStart}
						dateEnd={inputs.dateEnd}
						title={inputs.title}
						content={inputs.content}
					/>
				</div>
			</div>
		</Transition>
	);
};

export default AgendaPageCreate;
