import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import moment from 'moment'
// External librairies
import React, {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

// Queries
import {
    useQueryForumMessages,
    useMutationForumMessageAdd
} from '../../queries';

// Misc
import {Loader} from '../../utils/';
import {toast} from "react-toastify";
import {AuthContext} from "../../contexts";

//Chat ui
import {
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    MessageSeparator,
    Avatar
} from "@chatscope/chat-ui-kit-react";
import {Avatar as CustomAvatar} from "../../components";

const ForumPageBrief = ({socket}) => {
    const {id} = useParams();
    const {data: messages, isLoading} = useQueryForumMessages(id);
    const {mutate} = useMutationForumMessageAdd(id);
    const {user} = useContext(AuthContext);

    const [messagesList, setMessagesList] = useState(messages || [])

    useEffect(() => {
        if (messages) {
            setMessagesList(messages)
        }
    }, [messages])

    useEffect(() => {
        socket.on("receive_message", (data) => {
            const isDuplicate = messagesList.some(message => message.id === data.id);
            if (!isDuplicate) {
                setMessagesList((prevMessages) => [...prevMessages, data]);
            }
        });

        return () => {
            socket.off("receive_message")
        }
    }, [socket])

    const onSubmit = async (data) => {
        let payload = {
            message: data
        };

        mutate(payload, {
            onSuccess: (posted) => {
                socket.emit('send_message', posted.data.data)
                setMessagesList((prevMessages) => [...prevMessages, posted.data.data])
            },
            onError: () => toast.error('Une erreur est survenue')
        });
    };

    const isAvatarSpacer = (message, index) => {
        let res
        if (index + 1 === messagesList.length) {
            res = false
        } else res = message.user.username === messagesList[index + 1].user.username;
        return res
    }

    const getPosition = (message, index) => {
        const messageCount = messagesList.length;

        if (messageCount <= 1) {
            // Si la liste ne contient qu'un seul message ou est vide
            return "single";
        }

        if (index === 0) {
            // Premier message dans la liste
            return message.user.username !== messagesList[index + 1].user.username ? "single" : "first";
        }

        if (index === messageCount - 1) {
            // Dernier message dans la liste
            return "last";
        }

        // Messages situés entre le premier et le dernier dans la liste
        const prevUser = messagesList[index - 1].user.username;
        const nextUser = messagesList[index + 1].user.username;
        const currentUser = message.user.username;

        if (prevUser !== currentUser && currentUser !== nextUser) {
            return "single";
        } else if (prevUser !== currentUser) {
            return "first";
        } else if (currentUser !== nextUser) {
            return "last";
        } else {
            return "normal";
        }
    };


    return (
        <div style={{position: "relative", height: "500px"}}>
            <ChatContainer>
                {isLoading ? (
                    <Loader/>
                ) : (
                    <MessageList>
                        {messagesList?.length > 0 &&
                            messagesList.map((message, index) => {
                                    return (<>
                                        {
                                            index === 0 ? (
                                                <MessageSeparator>{moment(message.date).locale('fr').format('DD MMMM YYYY')}</MessageSeparator>
                                            ) : (
                                                !moment(message.date).isSame(messagesList[index - 1].date, 'day') &&
                                                <MessageSeparator>{moment(message.date).locale('fr').format('DD MMMM YYYY')}</MessageSeparator>
                                            )
                                        }
                                        <Message
                                            model={{
                                                message: message.message,
                                                direction: message.user.id === user.id ? "outgoing" : "incoming",
                                                // position: index + 1 === messagesList.length || message.user.username !== messagesList[index + 1].user.username && "last"
                                                position: getPosition(message, index)
                                            }}
                                            key={message.id}
                                            avatarSpacer={isAvatarSpacer(message, index)}
                                        >
                                            {
                                                index === 0 ? (
                                                    <Message.Header>{message.user.username}</Message.Header>
                                                ) : (
                                                    message.user.username !== messagesList[index - 1].user.username &&
                                                    <Message.Header>{message.user.username}</Message.Header>
                                                )
                                            }
                                            {
                                                index + 1 === messagesList.length ? (
                                                    <Avatar>
                                                        <CustomAvatar userAvatar={message.user.id === user.id ? user.avatar : message.user.avatar}/>
                                                    </Avatar>
                                                ) : (
                                                    message.user.username !== messagesList[index + 1].user.username &&
                                                    <Avatar>
                                                        <CustomAvatar userAvatar={message.user.id === user.id ? user.avatar : message.user.avatar}/>
                                                    </Avatar>
                                                )
                                            }
                                            <Message.CustomContent
                                                className={`${message.user.id === user.id && "text-white"}`}>
                                                <div dangerouslySetInnerHTML={{ __html: message.message }} />
                                            </Message.CustomContent>
                                            {
                                                index + 1 === messagesList.length ? (
                                                    <Message.Footer>{moment(message.date).format('HH:mm')}</Message.Footer>
                                                ) : (
                                                    message.user.username !== messagesList[index + 1].user.username &&
                                                    <Message.Footer>{moment(message.date).format('HH:mm')}</Message.Footer>
                                                )
                                            }
                                        </Message>
                                    </>)
                                }
                            )}

                    </MessageList>
                )}
                <MessageInput placeholder="" attachButton={false} onSend={onSubmit}/>
            </ChatContainer>
        </div>
    );
};

export default ForumPageBrief;
