// External librairies
import React from "react";
import {HashRouter} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

// Context
import {AuthProvider} from "./contexts";

// Route system
import {RouteRender} from "./router";

// Scrolling behaviour
import {ScrollTop} from "./utils";

// Static
import "./index.css";
import {NotificationProvider} from "./contexts/NotificationsContext";

const App = () => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    });
    return (
        <HashRouter>
            <QueryClientProvider client={queryClient}>
                <AuthProvider>
                    <NotificationProvider>
                        <ScrollTop/>
                        <RouteRender/>
                    </NotificationProvider>
                </AuthProvider>
            </QueryClientProvider>
        </HashRouter>
    );
};

export default App;
