// External librairies
import {useMutation} from "@tanstack/react-query";

// Config
import instanceFileUpload from "../../config/axiosconfig";
import instance from "../../config/axiosconfig";
import {refreshToken} from "../../utils";

export const useMutationFile = () => {
    return useMutation({
        mutationKey: ["mutateFile"],
        mutationFn: async (uploadFile) => {
            try {
                return await instanceFileUpload.post("/upload", uploadFile)
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    await refreshToken();
                    return await instanceFileUpload.post("/upload", uploadFile)
                }
                throw error
            }
        },
        onSuccess: (data) => {
            return data.data.data;
        },
    });
};

export const useMutationDeleteImage = () => {
    return useMutation({
        mutationKey: ["deleteImage"],
        mutationFn: async (imagesToDelete) => {
            try {
                for (let filename of imagesToDelete) {
                    return instance.delete("/upload/" + filename )
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    await refreshToken();
                    for (let filename of imagesToDelete) {
                        return instance.delete("/upload" + filename)
                    }
                }
                throw error
            }
        }
    })
}

export const useMutationUploadImages = () => {
    return useMutation({
        mutationKey: ["uploadImage"],
        mutationFn: (images) => {
            const formData = new FormData();
            images.forEach((image) => {
                formData.append("img", image)
            })
            return instanceFileUpload.post('/upload', formData)
        }
    })
}