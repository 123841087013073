// External librairies
import React, {useEffect} from 'react';
import {useNavigate, useOutletContext} from 'react-router-dom';
import {Controller, useForm} from 'react-hook-form';

// Queries
import {
    useQueryUsers, useMutationForumUpdate
} from '../../queries';

// Misc
import {Transition} from '../../utils/';
import {
    FormButton,
    SelectMultiInput, TableForumSubscribers,
    TextInput,
} from '../../components';
import {yupResolver} from "@hookform/resolvers/yup";
import {formSchemaForumAdd} from "../../config/yupconfig";
import {toast} from "react-toastify";

const ForumPageSettings = () => {
    const forumData = useOutletContext();
    const {mutate: updateForum} = useMutationForumUpdate(forumData.id);
    const navigate = useNavigate();

    const {data: users} = useQueryUsers("role=admin,associate,manager");

    const {
        register,
        formState: {errors, isValid},
        handleSubmit,
        control,
        reset
    } = useForm({
        resolver: yupResolver(formSchemaForumAdd)
    });

    useEffect(_ => {
        let defaultValues = {};
        defaultValues.title = forumData.title;
        defaultValues.moderators = forumData.Moderators.map((mod) => ({username: mod.username, id: mod.id}));
        reset({...defaultValues})
    }, [])

    const onSubmit = async (data, e) => {
        e.preventDefault();
        let payload = {...data};
        payload.moderators = data.moderators.map((mod) => mod.id)
        updateForum(payload, {
            onSuccess: () => {
                toast.success("Le forum a été mis à jour");
                navigate('/forum/' + forumData.id + "/brief")
            },
            onError: (err) => {
                console.log(err);
                toast.error("Impossible de modifier le forum")
            }
        })
    };

    return (
        <Transition>
            <div className="w-full lg:w-[46rem] flex flex-col gap-y-5 mb-5">
                <form
                    className="flex flex-col gap-y-5"
                    onSubmit={(e) => {
                        handleSubmit(onSubmit)(e);
                    }}
                >
                    <div className="flex flex-col gap-y-8">
                        <div className="flex flex-col md:flex-row md:justify-between gap-y-8 md:gap-y-0 gap-x-5">
                            <div className="flex flex-col">
                                <TextInput
                                    id="title"
                                    label="Nom du canal"
                                    register={{
                                        ...register('title')
                                    }}
                                    error={errors?.title}
                                />
                            </div>
                            <div className="flex flex-col">
                                <Controller
                                    name="moderators"
                                    control={control}
                                    render={({field}) => (
                                        <SelectMultiInput
                                            placeholder="Ajoutez des modérateurs"
                                            label="Modérateurs"
                                            options={users}
                                            field={field}
                                            error={
                                                errors?.moderators
                                            }
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className="mt-5 w-full flex flex-col gap-y-3 md:flex-row md:justify-end md:items-center md:gap-x-4 md:gap-y-0">
                        <FormButton
                            caption="Modifier"
                            isValid={isValid}
                        />
                    </div>
                </form>
            </div>
            <TableForumSubscribers forumId={forumData.id}/>
        </Transition>
    );
};

export default ForumPageSettings;
