// Icons
import { CancelRounded } from "@mui/icons-material";

const RemoveButton = ({ onClick }) => {
	return (
		<div>
			<button
				type="button"
				className="rounded-md w-10 h-10 flex justify-center items-center bg-rose-400 cursor-pointer transition-all hover:opacity-75"
				onClick={onClick}
			>
				<i className="flex justify-center items-center text-textNeutral-light">
					<CancelRounded sx={{ fontSize: 16 }} />
				</i>
			</button>
		</div>
	);
};

export default RemoveButton;
