// External Librairies
import React from "react";
import { useOutletContext } from "react-router-dom";

// Components
import { Avatar, TableUserIssues } from "../../components";

// Config
import { ROLES } from "../../config/config";
import { formatDateString } from "../../config/formats";

// Misc
import { Loader, Transition } from "../../utils";

const ProfilePageBrief = () => {
	const userData = useOutletContext();

	const translatedRole = ROLES.find((r) => r.slug === userData?.role);

	return (
		<>
			<Transition>
				<div className="flex flex-col gap-y-5 xl:flex-row xl:gap-y-0 xl:gap-x-8">
					<div className="rounded-xl w-full lg:max-w-[24em] flex flex-col items-center gap-y-4 p-8 shadow-custom bg-bgNeutral-light">
						<div className="flex flex-col items-center gap-y-3">
							<figure className="w-56 h-56 bg-slate-300 rounded-lg overflow-hidden">
								<Avatar
									className="w-full h-auto object-cover"
									userAvatar={userData.avatar}
								/>
							</figure>
							<span
								className={`border ${translatedRole?.bgFade} ${translatedRole?.border} ${translatedRole?.color} font-semibold py-1 px-4 rounded-full text-xs dark:text-textNeutral-light`}
							>
								{translatedRole?.name}
							</span>
						</div>
						<div className="my-5 w-full flex justify-between items-center">
							<div className="flex flex-col gap-y-1">
								<p className="font-semibold">Informations</p>
								<p className="text-textNeutral-dark text-[11px] opacity-50">
									<span className="text-inherit">
										Créé le:{" "}
									</span>
									<span className="text-inherit">
										{formatDateString(
											userData?.use_created
										)}
									</span>
								</p>
							</div>
						</div>
						<div className="mb-2 border-b border-bgNeutral-dark/[.1] w-full flex justify-between pb-3">
							<div className="flex items-center gap-x-1">
								<div>
									<p className="text-xs text-textNeutral-dark">
										{userData?.first_name}
									</p>
								</div>
								<div>
									<p className="text-textNeutral-dark text-xs font-semibold">
										{userData?.last_name}
									</p>
								</div>
							</div>
							<div>
								<p className="text-xs text-textNeutral-dark/[.6] lowercase">
									@{userData?.username}
								</p>
							</div>
						</div>
						<div className="mb-2 border-b border-bgNeutral-dark/[.1] w-full flex justify-between pb-3">
							<div className="w-full flex items-center gap-x-1.5">
								<span className="text-textNeutral-dark text-xs">
									{userData?.email}
								</span>
							</div>
						</div>
						<div className="mb-2 border-b border-bgNeutral-dark/[.1] w-full flex justify-between pb-3">
							<div className="flex items-center gap-x-1">
								<div>
									<p className="flex items-center gap-x-1.5 text-xs text-textNeutral-dark">
										{formatDateString(userData?.birthday)}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="w-full flex flex-col gap-y-3">
						<h4>Mes incidents</h4>
						<TableUserIssues userId={userData?.id} />
					</div>
				</div>
			</Transition>
		</>
	);
};

export default ProfilePageBrief;
