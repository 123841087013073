import React from "react";
import { easeInOut, motion } from "framer-motion";

const animConfig = {
	hidden: { opacity: 0 },
	show: {
		opacity: 1,
		transition: {
			repeat: Infinity,
			ease: easeInOut,
			duration: .7,
			repeatType: "reverse",
			delay: .4
		},
	},
};

const Loader = () => {
	return (
		<div>
			<motion.figure
				className="mx-auto w-[49vh] h-[49vh] flex justify-center items-center"
				variants={animConfig}
				initial="hidden"
				animate="show"
			>
				<img
					className="w-[15rem] h-[15rem]"
					src={`${process.env.REACT_APP_API_URL}/uploads/logo.png`}
					alt="smapi_logo"
				/>
			</motion.figure>
		</div>
	);
};

export default Loader;
