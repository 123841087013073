// External librairies
import React from 'react';
import { NavLink } from 'react-router-dom';

// Query
import { useQuerySurvey } from '../../queries';

// Components
import { ActionHeader, CardSurvey } from '../../components';

// Misc
import { Loader } from '../../utils';
import { Transition } from '../../utils/';

const SurveyPage = () => {
	// Waiting for backend datas and librairy
	const { isLoading, data: dataSurvey } = useQuerySurvey();
	const actionData = [
		{
			id: 0,
			route: 'create',
			label: 'Rédiger un questionnaire'
		}
	];

	return (
		<>
			<div>
				<ActionHeader
					icon="survey"
					title="Questionnaires"
					hasReturn={false}
					actionData={actionData}
				/>
			</div>
			<div className="w-full h-full flex justify-center">
				{isLoading ? (
					<Loader />
				) : (
					<div className="w-full">
						<Transition>
							{dataSurvey ? (
								<div className="w-full grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-4">
									{dataSurvey.map((s) => (
										<div className="flex-1">
											<NavLink
												key={s.id}
												to={`${s.id}/brief`}
											>
												<CardSurvey
													title={s.title}
													createDate={s.sur_created}
													updateDate={s.sur_updated}
												/>
											</NavLink>
										</div>
									))}
								</div>
							) : (
								<div className="w-full h-full flex justify-center">
									<h3 className="mt-[20vh] text-xl lg:text-[2.6rem] opacity-[.18] text-center leading-[1.2em]">
										Il n'y actuellement aucun questionnaire
									</h3>
								</div>
							)}
						</Transition>
					</div>
				)}
			</div>
		</>
	);
};

export default SurveyPage;
