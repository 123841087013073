// External Librairies
import { Outlet } from "react-router-dom";

// Structure
import { Container } from "../../structure";

const MainInterface = () => {
	return (
		<div>
			<Container>
				<Outlet />
			</Container>
		</div>
	);
};

export default MainInterface;
