// External librairies
import React, {useEffect} from "react";
import {NavLink} from "react-router-dom";

// Query
import {useQueryNews} from "../../queries";

// Components
import {ActionHeader, MainCardNews, CardNews} from "../../components";

// Misc
import {Loader} from "../../utils";
import {Transition} from "../../utils/";

const NewsPage = () => {
    const {isLoading, data: dataNews, refetch: queryNews} = useQueryNews();

    const actionData = [
        {
            id: 0,
            route: "create",
            label: "Rédiger un article",
        },
    ];

    useEffect(() => {
        // Rechargez les données après la création d'une nouvelle
        queryNews();
    }, [queryNews]);

    return (
        <>
            <div>
                <ActionHeader
                    icon="news"
                    title="Nouvelles"
                    hasReturn={false}
                    actionData={actionData}
                />
            </div>
            {isLoading ? (
                <Loader/>
            ) : (
                <>
                    <Transition>
                        {dataNews && dataNews.length > 0 ? (
                            <div className="lg:mt-20 mb-12 mx-auto flex flex-col gap-y-24 block max-w-3/4">
                                {dataNews[0] && (
                                    <div className="w-full flex justify-center items-center">
                                        <NavLink
                                            to={`${dataNews[0].id}/brief`}
                                        >
                                            <MainCardNews
                                                dateCreated={dataNews[0].dateCreated}
                                                dateUpdated={dataNews[0].dateUpdated}
                                                title={dataNews[0].title}
                                                shortTitle={dataNews[0].shortTitle}
                                                content={dataNews[0].content}
                                                image={(dataNews[0].images && dataNews[0].images.length > 0) ? dataNews[0].images[0].filename : undefined}
                                                section={dataNews[0].section}
                                            />
                                        </NavLink>
                                    </div>
                                )}
                                <div className="w-full">
                                    <ul className="mx-0 lg:mx-auto w-full xl:w-[1000px] grid grid-flow-row grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-14 lg:gap-y-24">
                                        {dataNews.slice(1).map((n) => (
                                            <NavLink
                                                key={n.id}
                                                to={`${n.id}/brief`}
                                            >
                                                <li className="h-full">
                                                    <CardNews
                                                        dateCreated={
                                                            n.dateCreated
                                                        }
                                                        dateUpdated={
                                                            n.dateUpdated
                                                        }
                                                        title={n.title}
                                                        content={n.shortTitle || n.content}
                                                        image={(n.images && n.images.length > 0) ? n.images[0].filename : undefined}
                                                        section={n.section}

                                                    />
                                                </li>
                                            </NavLink>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ) : (
                            <div className="w-full h-full flex justify-center">
                                <h3 className="mt-[20vh] text-xl lg:text-[2.6rem] opacity-[.18] text-center leading-[1.2em]">
                                    Il n'y actuellement aucune nouvelle
                                </h3>
                            </div>
                        )}
                    </Transition>
                </>
            )}
        </>
    );
};

export default NewsPage;
