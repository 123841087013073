import {CalendarToday, AccessTime} from "@mui/icons-material";
import {useQueryAgenda} from "../../queries";
import {Loader} from "../../utils";
import {formatDateString} from "../../config/formats";

const NextEvent = () => {
    const {isLoading, data, error} = useQueryAgenda("?date=future");

    if (isLoading) {
        return <Loader/>
    }

    if (data && data.length > 0) {
        return (
            <div className="relative rounded-lg shadow-custom w-full h-[20em] xl:h-full flex overflow-hidden">
                <figure className="block w-full h-full">
                    {data[0].image && (
                        <img
                            className="rounded-lg w-full h-full object-cover object-center"
                            src={data[0].image}
                        />
                    )}
                </figure>
                <div
                    className="z-10 mt-auto h-2/5 w-full flex flex-col justify-around gap-y-3 absolute bottom-0 left-0 px-4 py-4 bg-black/50">
                    {data[0].location && (
                        <div className="rounded-md text-textNeutral-light text-xs font-semibold flex">
                            <div className="rounded-lg whitespace-nowrap bg-white/25 px-5 py-1 flex">
                                {data[0].location}
                            </div>
                        </div>
                    )
                    }
                    <div className="flex flex-col text-textNeutral-light">
                        <h3 className="text-inherit font-semibold text-lg">
                            {data[0].title}
                        </h3>
                        {
                            data[0].subtitle && (
                                <p className="text-inherit font-light text-xs">
                                    {data[0].subtitle}
                                </p>
                            )
                        }
                    </div>
                    <div className="flex gap-x-6">
                        <div className="text-textNeutral-light flex gap-x-2 items-center text-xs font-light">
                            <i>
                                <CalendarToday sx={{fontsize: 10}}/>
                            </i>
                            <span className="text-inherit">{formatDateString(data[0].start)}</span>
                        </div>
                        <div className="text-textNeutral-light flex gap-x-2 items-center text-xs font-light">
                            <i>
                                <AccessTime sx={{fontsize: 10}}/>
                            </i>
                            <span className="text-inherit">{`${data[0].start.getHours().toString().padStart(2, '0')}:${data[0].start.getMinutes().toString().padStart(2, '0')}`}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default NextEvent;
