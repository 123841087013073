// External Librairies
import React from "react";

// Components
import {Tag} from "../../components";

// Misc
import {Phone, Mail, PinDrop} from "@mui/icons-material";
import {getDays} from "../../pages/DirectoryPage/directoryHelpers";

const DirectoryCard = ({user}) => {
    const {
        group,
        role,
        first_name,
        last_name,
        phone,
        mail,
        city,
        street,
        zip,
        ContactSchedules
    } = user;


    return (
        <div
            className="rounded-lg px-6 py-8 bg-bgNeutral-light xl:-translate-y-0 cursor-pointer shadow-custom transition-all xl:duration-300 xl:ease-in-out xl:hover:shadow-2xl xl:hover:-translate-y-1">
            <div className="flex flex-col gap-y-8">
                <div className="border-b border-beNeutral-borderHeader flex flex-col gap-y-5 pb-4">
                    <div className="flex justify-between">
                        <div className="flex flex-col gap-y-0">
                            <h3>
                                {first_name} {last_name}
                            </h3>
                            <h4 className="opacity-75 font-light">{role}</h4>
                        </div>
                        <div>
                            <Tag caption={group}/>
                        </div>
                    </div>
                    <div className="flex flex-col gap-y-0">
                        {phone && (
                            <div className="flex items-center gap-x-2">
                                <i className="text-[14px] text-primary">
                                    <Phone sx={{fontSize: 12}}/>
                                </i>
                                <span className="text-[14px] text-textNeutral-dark font-light">
								{phone}
							</span>
                            </div>
                        )}
                        {mail && (
                            <div className="flex items-center gap-x-2">
                                <i className="text-[14px] text-primary">
                                    <Mail sx={{fontSize: 12}}/>
                                </i>
                                <span className="text-[14px] text-textNeutral-dark font-light">
								{mail}
							</span>
                            </div>
                        )}
                    </div>
                </div>
                {!ContactSchedules && (
                    <div className="flex flex-col gap-y-3">
                        <div>
							<span className="text-sm uppercase text-textNeutral-dark font-bold opacity-25">
								Aucun horaire disponible
							</span>
                        </div>
                    </div>
                )}
                {ContactSchedules && ContactSchedules.length > 0 && (
                    <ul>
                        {ContactSchedules.map((c) => (
                            <li key={c.id}>
                                <h4 className="text-[13px] text-primary/[.75]">
                                    {getDays(c.days)}
                                </h4>
                                <div className="flex items-center gap-x-8">
                                    {c.morning_start !== "00:00:00" && c.morning_end !== "00:00:00" && (
                                        <span className="text-textNeutral-dark/[.72] text-[13px] font-normal">
										{c.morning_start.substring(0, 5)} - {c.morning_end.substring(0, 5)}
									</span>
                                    )
                                    }
                                    {c.afternoon_start !== "00:00:00" && c.afternoon_end !== "00:00:00" && (
                                        <span className="text-textNeutral-dark/[.72] text-[13px] font-normal">
										{c.afternoon_start.substring(0, 5)} - {c.afternoon_end.substring(0, 5)}
									</span>
                                    )
                                    }
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
                {(street || zip || city) && (
                    <div className="flex items-center gap-x-2">
                        <i className="text-[14px] text-primary">
                            <PinDrop sx={{fontSize: 15}}/>
                        </i>
                        <div className="flex items-center gap-x-3 text-[14px] text-textNeutral-dark font-light">
                            {street && (
                                <span className="text-inherit opacity-75">
							{street}
						</span>
                            )}
                            <span className="text-inherit opacity-75">-</span>
                            {(zip || city) && (
                                <div className="flex items-center gap-x-1">
                                    {zip && (
                                        <span className="text-inherit font-medium">
								{zip}
							</span>
                                    )}
                                    {city && (
                                        <span className="text-inherit font-medium">
								{city}
							</span>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DirectoryCard;
