// External librairies
import React from "react";
import {useOutletContext} from "react-router-dom";

// Config
import {formatDateString} from "../../config/formats";

// Icons
import {LibraryAddCheck, LibraryBooks} from "@mui/icons-material";

// Misc
import {Transition} from "../../utils/";
import {ImageCarousel} from "../../components";

const NewsPageBrief = () => {
    const newsData = useOutletContext();

    return (
        <Transition>
            <div className="rounded-lg mt-10 mb-12 mx-auto w-full lg:w-[780px] p-12 bg-bgNeutral-light shadow-custom">
                <figure className="rounded-lg block w-full h-[12em] lg:h-full overflow-hidden">
                    {newsData.NewsImages.length > 0
                        ? (
                            <ImageCarousel images={newsData.NewsImages}/>
                        )
                        : (
                            <img
                                className="w-full h-full object-cover object-center"
                                src="https://images.unsplash.com/photo-1618005198919-d3d4b5a92ead?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80"
                                alt=""
                            />
                        )
                    }
                </figure>
                <div className="mt-12 w-full flex flex-col gap-y-6 lg:gap-y-4 py-2">
                    <div className="flex flex-col gap-y-2 lg:flex-row lg:items-center gap-x-8 lg:gap-x-5 lg:gap-y-0">
                        <div className="flex items-center gap-x-2 text-xs text-textNeutral-dark/[.38]">
                            <p className="flex items-center gap-x-1 text-xs text-textNeutral-dark/[.38]">
                                <i>
                                    <LibraryBooks sx={{fontSize: 16}}/>
                                </i>
                                Parution:
                            </p>
                            <span className="flex items-center text-xs text-textNeutral-dark/[.38]">
								{formatDateString(newsData?.new_created)}
							</span>
                        </div>
                        <div className="flex items-center gap-x-2 text-xs text-textNeutral-dark/[.38]">
                            <p className="flex items-center gap-x-1 text-xs text-textNeutral-dark/[.38]">
                                <i>
                                    <LibraryAddCheck sx={{fontSize: 16}}/>
                                </i>
                                Dernière mise à jour:
                            </p>
                            <span className="flex items-center text-xs text-textNeutral-dark/[.38]">
								{formatDateString(newsData?.new_updated)}
							</span>
                        </div>
                    </div>
                    <h2 className="text-[1.9em] lg:text-[2.5em] text-textNeutral-dark font-semibold leading-[1.1em]">
                        {newsData.title}
                    </h2>
                    <h3>
                        {newsData.short_title}
                    </h3>
                    <div
                        className="quill-editor-theme pr-0 lg:pr-[2em] text-[16px] text-textNeutral-dark/[.72] leading-[1.9em]"
                        dangerouslySetInnerHTML={{__html: newsData.content}}
                    ></div>
                </div>
            </div>
        </Transition>
    );
};

export default NewsPageBrief;
