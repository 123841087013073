// External librairies
import { useMutation } from "@tanstack/react-query";

// Config
import instance from "../../config/axiosconfig";

export const useMutationSurvey = (id) => {
	return useMutation({
		mutationKey: ["mutateSurvey"],
		mutationFn: (updateSurvey) => instance.put(`/surveys/${id}`, updateSurvey),
	});
};

export const useMutationSurveyAdd = () => {
	return useMutation({
		mutationKey: ["mutateSurveyAdd"],
		mutationFn: (addSurvey) => {
			return instance.post('/surveys', addSurvey);
		},
	});
};