export const BASE_URL = process.env.REACT_APP_API_URL;
export const IMAGES_URL = BASE_URL + "/uploads/";

export const VALID_ROLES = ["ROLE_ADMIN", "ROLE_MANAGER", "ROLE_ASSOCIATE"];

export const PRIVACY = [
	{
		id: 0,
		label: "Privé",
		value: "private",
		bg: "bg-privacy-private",
		border: "border-privacy-private",
		bgFade: "bg-privacy-private/[.12]",
		color: "text-privacy-private",
	},
	{
		id: 1,
		label: "Publique",
		value: "public",
		bg: "bg-privacy-public",
		border: "border-privacy-public",
		bgFade: "bg-privacy-public/[.12]",
		color: "text-privacy-public",
	},
];

export const STATUS = [
	{ id: 0, name: "Tous", slug: "all" },
	{
		id: 1,
		name: "Nouveau",
		slug: "new",
		bg: "bg-state-new",
		border: "border-state-new",
		bgFade: "bg-state-new/[.12]",
		color: "text-state-new",
	},
	{
		id: 2,
		name: "En cours",
		slug: "ongoing",
		bg: "bg-state-progress",
		border: "border-state-progress",
		bgFade: "bg-state-progress/[.12]",
		color: "text-state-progress",
	},
	{
		id: 3,
		name: "Terminé",
		slug: "over",
		bg: "bg-state-done",
		border: "border-state-done",
		bgFade: "bg-state-done/[.12]",
		color: "text-state-done",
	},
	{
		id: 4,
		name: "Archivé",
		slug: "archived",
		bg: "bg-state-archived",
		border: "border-state-archived",
		bgFade: "bg-state-archived/[.12]",
		color: "text-state-archived",
	},
];

export const ROLES = [
	{ id: 0, name: "Tous", slug: "all" },
	{
		id: 1,
		name: "Citoyen",
		accessor: "citizen",
		slug: "citizen",
		bg: "bg-roles-citizen",
		border: "border-roles-citizen",
		color: "text-roles-citizen",
		bgFade: "bg-roles-citizen/[.12]",
		ring: "ring-roles-citizen",
	},
	{
		id: 2,
		name: "Collaborateur",
		accessor: "associate",
		slug: "associate",
		bg: "bg-roles-associate",
		border: "border-roles-associate",
		color: "text-roles-associate",
		bgFade: "bg-roles-associate/[.12]",
		ring: "ring-roles-associate",
	},
	{
		id: 3,
		name: "Responsable",
		accessor: "manager",
		slug: "manager",
		bg: "bg-roles-manager",
		border: "border-roles-manager",
		color: "text-roles-manager",
		bgFade: "bg-roles-manager/[.12]",
		ring: "ring-roles-manager",
	},
	{
		id: 4,
		name: "Administrateur",
		accessor: "admin",
		slug: "admin",
		bg: "bg-roles-admin",
		border: "border-roles-admin",
		color: "text-roles-admin",
		bgFade: "bg-roles-admin/[.12]",
		ring: "ring-roles-citadminizen",
	},
];

export const RECURRENCE = [
	{
		label: "Jamais",
		value: "never"
	},
	{
		label: "Tous les jours",
		value: "daily"
	},
	{
		label: "Toutes les semaines",
		value: "weekly"
	},
	{
		label: "Tous les mois",
		value: "monthly"
	},
	{
		label: "Tous les ans",
		value: "annualy"
	},
]

export const WEEKDAYS = [
	{
		label: "Lundi",
		value: 2
	},
	{
		label: "Mardi",
		value: 4
	},
	{
		label: "Mercredi",
		value: 8
	},
	{
		label: "Jeudi",
		value: 16
	},
	{
		label: "Vendredi",
		value: 32
	}
]

export const CONTRACT_TYPE = [
	{
		label: "CDI",
		value: 0
	},
	{
		label: "CDD",
		value: 1
	},
	{
		label: "Stage",
		value: 2
	},
]

export const WORK_TIME = [
	{
		label: "100%",
		value: 0
	},
	{
		label: "80%",
		value: 1
	},
	{
		label: "60%",
		value: 2
	},
	{
		label: "40%",
		value: 3
	},
	{
		label: "A convenir",
		value: 4
	},
]


