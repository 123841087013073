// External librairies
import React from "react";

// Config
import { formatDateString } from "../../config/formats";

// Icons
import { LibraryAddCheck, LibraryBooks } from "@mui/icons-material";
import {ImageCarousel} from "../Image";
import {Tag} from "../Tag";

const PreviewCardNews = ({ images, title, shortTitle, content, section }) => {
	return (
		<div className="w-full h-full flex flex-col gap-y-4">
			<div className="rounded-lg w-full min-h-full flex flex-col px-8 py-8 bg-bgNeutral-light shadow-custom opacity-[.85]">
				<figure className="rounded-lg block w-full h-[12em] lg:h-full overflow-hidden">
					<ImageCarousel
						type="local"
						images={images.map(image => URL.createObjectURL(image))}
					/>
				</figure>
				<div className="mt-6 2xl:mt-12 w-full flex flex-col gap-y-6 lg:gap-y-4 py-2">
					<div className="flex flex-col gap-y-2 2xl:flex-row 2xl:items-center gap-x-8 2xl:gap-x-5 2xl:gap-y-0">
						<div className="flex items-center gap-x-2 text-xs text-textNeutral-dark/[.38]">
							<p className="flex items-center gap-x-1 text-xs text-textNeutral-dark/[.38]">
								<i>
									<LibraryBooks sx={{ fontSize: 16 }} />
								</i>
								Parution:
							</p>
							<span className="flex items-center text-xs text-textNeutral-dark/[.38]">
								{formatDateString(
									new Date()
										.toJSON()
										.slice(0, 10)
										.replace(/-/g, "/")
								)}
							</span>
						</div>
						<div className="flex items-center gap-x-2 text-xs text-textNeutral-dark/[.38]">
							<p className="flex items-center gap-x-1 text-xs text-textNeutral-dark/[.38]">
								<i>
									<LibraryAddCheck sx={{ fontSize: 16 }} />
								</i>
								Dernière mise à jour:
							</p>
							<span className="flex items-center text-xs text-textNeutral-dark/[.38]">
								{formatDateString(
									new Date()
										.toJSON()
										.slice(0, 10)
										.replace(/-/g, "/")
								)}
							</span>
						</div>
						<Tag caption={section} />
					</div>
					<div className="mt-3 flex flex-col gap-y-1">
						<h2 className="w-full text-[1.9em] text-textNeutral-dark font-semibold leading-[1.1em] overflow-hidden">
							{title}
						</h2>
						<h3>
							{shortTitle}
						</h3>
						<div
							className="quill-editor-theme mt-3 pr-0 lg:pr-[2em] text-[13px] text-textNeutral-dark/[.72] leading-[1.9em]"
							dangerouslySetInnerHTML={{ __html: content }}
						></div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PreviewCardNews;
